<template>
    <div>
    <b-card
      class="mb-1"
      :title="$t('fx_spot_forward_swap.titles.fx_spot_forward_swap')"
    >
      
        <hr />
        <b-overlay :show="orderColumnsOverlay">
        <b-row class="mb-2">
          <b-col cols="12" xl="8" md="8" sm="12">
            <div
              class="d-flex mt-1"
              style="width: 100%;height: 56.39px !important; max-height: 56.39px !important;"
            >
              <b-col cols="12" md="6" xl="6" sm="12">
                <b-input-group>
                  <flat-pickr
                    :placeholder="$t('fx_spot_forward_swap.trade_date')"
                    onClose="testClose"
                    v-model="tradeRangeDate"
                    class="form-control"
                    :config="dateConfig"
                    @on-change="getFilterTradeDate()"
                    :disabled="
                      typeof lastSearchData != 'Object'
                        ? !lastSearchData.find(
                            (data) => data.user == this.user.name
                          )
                        : true
                    "
                  />
                  <b-input-group-append>
                    <b-button
                      @click="clearTradeDate()"
                      variant="success"
                      :disabled="
                        typeof lastSearchData != 'Object'
                          ? !lastSearchData.find(
                              (data) => data.user == this.user.name
                            )
                          : true
                      "
                      >{{ $t("general_buttons.clear") }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="12" md="6" xl="6" sm="12">
                <b-input-group>
                  <flat-pickr
                    :placeholder="$t('fx_spot_forward_swap.settlement_date')"
                    onClose="testClose"
                    v-model="settleRangeDate"
                    class="form-control"
                    :config="dateConfig"
                    @on-change="getFilterSettlementDate"
                    :disabled="
                      typeof lastSearchData != 'Object'
                        ? !lastSearchData.find(
                            (data) => data.user == this.user.name
                          )
                        : true
                    "
                  />
                  <b-input-group-append>
                    <b-button
                      @click="clearSettlementDate()"
                      variant="success"
                      :disabled="
                        typeof lastSearchData != 'Object'
                          ? !lastSearchData.find(
                              (data) => data.user == this.user.name
                            )
                          : true
                      "
                      >{{ $t("general_buttons.clear") }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </div>
            <hr class="mt-0" />
            <div
              class="d-flex mt-1"
              style="width: 100%;height: 56.39px !important; max-height: 56.39px !important;"
            >
              <!-- Per Page -->
              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-md-0"
                v-if="visibleFxSpotInput"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50 w-100"
                />
                <label>entries</label>
              </b-col>
              <!-- filter status -->
              <b-col
                cols="12"
                md="3"
                xl="3"
                sm="4"
                class="d-flex align-items-center justify-content-start mb-md-0"
              >
                <treeselect
                  value-consists-of="LEAF_PRIORITY"
                  :multiple="true"
                  :options="statusOptions"
                  v-model="treeStatus"
                  valueFormat="object"
                  :limit="4"
                  clearable
                  :placeholder="
                    $t('fx_spot_forward_swap.fx_execute_selections')
                  "
                  v-if="visibleFxSpotInput"
                  class="treeSelect"
                />
              </b-col>
              <!-- filter table columns -->
              <b-col cols="12" md="6" xl="6" sm="4" class="d-flex align-items-center justify-content-start mb-md-0">
                <!-- <treeselect
                  value-consists-of="LEAF_PRIORITY"
                  :multiple="true"
                  :options="tableColumnsOptions"
                  :clearable="false"
                  :searchable="true"
                  v-model="treeTablecolumns"
                  valueFormat="object"
                  :limit="4"
                  :placeholder="$t('fx_spot_forward_swap.get_selected_columns')"
                  v-if="visibleFxSpotInput"
                  class="treeSelect"
                /> -->
              </b-col>
              <!-- search in table -->
              <!-- <b-col cols="6" sm="6" xl="6" md="6">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  :placeholder="$t('general_text.search_table')"
                  v-if="allFxSFSData.length > 0"
                />
              </b-col> -->
            </div>
          </b-col>
          <b-col cols="12" xl="4" md="4" sm="12">
            <div class="d-flex" style="width: 100% !important;">
              <b-col v-if="$Can('search_fx')" cols="6" md="6" sm="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-info"
                  size="sm"
                  class="top-btns"
                  @click="searchForTradesModal"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="trades-font">{{
                    $t("fx_spot_forward_swap.buttons.search_for_trades")
                  }}</span>
                </b-button>
              </b-col>
              <b-col v-if="$Can('search_fx')" cols="6" md="6" sm="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-dark"
                  size="sm"
                  class="top-btns"
                  @click="runLastSearch()"
                  :disabled="
                    typeof lastSearchData != 'Object'
                      ? !lastSearchData.find(
                          (data) => data.user == this.user.name
                        )
                      : true
                  "
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="trades-font">{{
                    $t("fx_spot_forward_swap.buttons.run_last_search")
                  }}</span>
                </b-button>
              </b-col>
            </div>
            <hr />
            <div class="d-flex mt-1" style="width: 100% !important;">
              <b-col v-if="$Can('add_fx')" cols="6" xl="6" md="6" sm="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  size="sm"
                  variant="gradient-success"
                  class="top-btns"
                  @click="createNewTrade"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="text-nowrap">{{
                    $t("fx_spot_forward_swap.buttons.enter_new_trade")
                  }}</span>
                </b-button>
              </b-col>
              <b-col cols="3" xl="3" md="3" sm="12">
                <b-button
                  id="info-legend-button"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  size="sm"
                  class="top-btns"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />

                  <span class="text-nowrap">{{
                    $t("fx_spot_forward_swap.buttons.legend_table")
                  }}</span>
                </b-button>
                <b-popover
                  target="info-legend-button"
                  placement="right"
                  triggers="click"
                >
                  <template #title>
                    {{ $t("fx_spot_forward_swap.legend_table.legend") }}
                  </template>
                  <b-table-simple hover small caption-top responsive bordered>
                    <b-tbody>
                      <b-tr>
                        <td style="width: 60%;">
                          {{
                            $t("fx_spot_forward_swap.legend_table.execution")
                          }}
                        </td>
                        <td style="background-color: paleturquoise;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{ $t("fx_spot_forward_swap.legend_table.verified") }}
                        </td>
                        <td style="background-color: springgreen;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{
                            $t(
                              "fx_spot_forward_swap.legend_table.journal_created"
                            )
                          }}
                        </td>
                        <td style="background-color: mediumpurple;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{
                            $t(
                              "fx_spot_forward_swap.legend_table.expired_order"
                            )
                          }}
                        </td>
                        <td style="background-color: silver;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{
                            $t(
                              "fx_spot_forward_swap.legend_table.revers_initiated"
                            )
                          }}
                        </td>
                        <td style="background-color: #FFFF99;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{ $t("fx_spot_forward_swap.legend_table.reversed") }}
                        </td>
                        <td style="background-color: coral;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{
                            $t("fx_spot_forward_swap.legend_table.cancelled")
                          }}
                        </td>
                        <td style="background-color: orangered;"></td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-popover>
              </b-col>

              <b-col cols="3" xl="3" md="3" sm="12">
                <b-button
                  id="info-export-button"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  size="sm"
                  class="top-btns"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />

                  <span class="text-nowrap">{{
                    $t("equity_bond.buttons.export")
                  }}</span>
                </b-button>
                <b-popover
                  target="info-export-button"
                  placement="right"
                  triggers="click"
                >
                  <template #title>
                    {{ $t("equity_bond.buttons.export") }}
                  </template>

                  <b-button size="sm" class="top-btns" @click="exportXlsx()">
                    <span class="text-nowrap">Save as .xlsx</span>
                  </b-button>

                  <b-button
                    size="sm"
                    class="top-btns mt-1"
                    @click="exportCsv()"
                  >
                    <span class="text-nowrap">Save as .csv</span>
                  </b-button>
                </b-popover>
              </b-col>
            </div>
          </b-col>
        </b-row>
        <!-- order columns overlay -->
          <template #overlay>
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <p><strong id="form-confirm-label">{{ $t("general_messages.save_order_columns_message") }}</strong></p>

            <p class="mb-0" style="font-size:0.8rem;">{{ $t("general_messages.filter_columns_message") }}</p>
            <b-col
                cols="12"
                class="d-flex align-items-center justify-content-start mb-md-0"
              >
                <treeselect
                  value-consists-of="LEAF_PRIORITY"
                  :multiple="true"
                  :options="tableColumnsOptions"
                  :clearable="false"
                  :searchable="true"
                  v-model="treeTablecolumns"
                  @select="selectTreeColumn"
                  @deselect="deSelectTreeColumn"
                  valueFormat="object"
                  :limit="9"
                  :placeholder="$t('fx_spot_forward_swap.get_selected_columns')"
                  v-if="visibleFxSpotInput"
                  class="treeSelect"
                />
              </b-col>
            <div class="d-flex justify-content-center mt-1">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="gradient-danger"
                class="mr-3"
                size="sm"
                @click="onCancelOrderColumn"
              >
              {{ $t("general_buttons.cancel") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="gradient-warning"
                size="sm"
                @click="onSaveOrderColumns"
              >
              {{ $t("general_buttons.save_columns_order") }}
              </b-button>
              
            </div>
          </div>
        </template>
      </b-overlay>
        </b-card>
        <b-overlay :show="loading" rounded="sm" no-fade>
          <b-card>
          <div @contextmenu.prevent="$refs.contextMenu.open" 
            v-if="allFxSFSData.length > 0">
            <div style="background-color:white;" :style="'height:' + (windowHeight - 550) + 'px'" id="table-container">

          <b-table
            :sticky-header="true"
            :no-border-collapse="true"
            hover
            :bordered="true"
            ref="refFXSFSTable"
            class="mt-1"
            thead-class="animate__animated animate__fadeIn"
            :items="filteredTrades"
            :filter="filter"
            filter-debounce="250"
            @filtered="onFiltered"
            :filter-included-fields="filterOn"
            selectable
            select-mode="single"
            responsive
            :small="true"
            head-variant="dark"
            :fields="tableColumns"
            show-empty
            empty-text="No matching records found"
            :current-page="currentPage"
            :per-page="perPage"
            :tbody-tr-class="legendClass"

            caption-top
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortByDesc"
          >
          <!-- @row-clicked="onRowSelected" -->

          <!-- sort columns draggable -->
         <template slot="thead-top" slot-scope="data">
              <draggable v-model="tableColumns" tag="tr" id="draggableColumns" style="display: none;" class="animate__animated animate__fadeIn">
                <th v-for="header in tableColumns" :key="header.key" scope="col" style="white-space: nowrap;padding: 1rem;cursor:grab">{{ header.label }}</th>
              </draggable>
            </template>

              <!-- filter columns -->
            <template

              slot="top-row"
              slot-scope="{ fields }"
              v-if="showColumnsFilter"
            >
              <td
              :class="field.key == 'ID' ? 'sticky-column' : ''"
              v-for="field in tableColumns" :key="field.key">
                <v-select
                  v-if="field.key == 'EntryType'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                  :options="optEntryTypes"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                <v-select
                  v-else-if="field.key == 'ProductType'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                  :options="optProductTypes"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                <v-select
                  v-else-if="field.key == 'SettlementType'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                  :options="optSettlementTypes"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                <v-select
                  v-else-if="field.key == 'CutOffTime'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                  :options="optSettlementTypes"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                <v-select
                  v-else-if="field.key == 'GivenThrg'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                  :options="optOrderGivenThroughs"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                <v-select
                  v-else-if="field.key == 'TakenVia'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                  :options="optOrderTakenVias"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                <v-select
                  v-else-if="field.key == 'ActingCap'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                  :options="optActingCapacities"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                <input
                  v-else-if="field.key == 'ID'"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="'ID_column_search_input'"

                  style="max-width: 75px;"
                />
                <input
                  v-else
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                />
                <!-- <b-form-input v-model="filters[field.key]" :placeholder="field.label" :class="field.key" /> -->
              </td>
            </template>

            <!-- Column: check box -->
            <!-- <template #cell(check)="data" v-if="showMultiplecheckboks">
              <b-form-checkbox
                v-model="data.item.selectedTrade"
                @change="multiSelectRow(data.item.selectedTrade, data.item)"
              >
              </b-form-checkbox>
            </template> -->

            <!-- Column: ID -->
            <template #cell(ID)="data">
              <div class="d-flex justify-content-center align-items-center">

              <b
                ><span class="font-weight-bold text-center">
                  <strong style="text-decoration:underline;">{{ data.item.ID ? data.item.ID : "-" }}</strong>
                </span></b
              >
              <div v-if="showMultiplecheckboks" style="margin-left:0.3em">
              <b-form-checkbox
                v-model="data.item.selectedTrade"
                @change="multiSelectRow(data.item.selectedTrade, data.item)"
              >
              </b-form-checkbox>

            </div>



            </div>

            <!-- trade controls -->
            <div>
              <b-button
                  :id="'trade-controls-' + data.item.ID.toString()"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  size="sm"
                  class="top-btns"
                  @click="showTradeControlsPopover('trade-controls-' + data.item.ID.toString(),data.item)"
                >
                <i class="fa-solid fa-bars"></i>

                  
                </b-button>
                <b-popover
                  :target="'trade-controls-' + data.item.ID.toString()"
                  triggers="focus"
                  variant="primary"
                >
                  <template #title>
                    {{ $t('equity_bond.titles.trade_controls') }}
                  </template>

                  <b-button
                    v-if="$Can('verify_fx') && seeVerifyButton"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
                    :title="$t('equity_bond.buttons.verify_trade')"
                    size="sm"
                    class="top-btns mt-1"
                    @click="verifySelectedTrade(data.item)"
                  >
                    

                    <span class="trades-font">
                      <feather-icon
                      icon="CheckSquareIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.verify_trade")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('amend_fx') && seeAmendButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-warning"
                    size="sm"
                    class="top-btns mt-1"
                    @click="amendUpdate(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="EditIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.amend_trade")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('journal_fx') && seeJournalButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    size="sm"
                    class="top-btns mt-1"
                    @click="createJournalEntries(data.item)"
                  >
                    
                    <span class="trades-font"><feather-icon
                      icon="ClipboardIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.create_Journal_entries")
                    }}</span>
                  </b-button>


                  <b-button
                    v-if="$Can('view_fx')"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="top-btns mt-1"
                    @click="viewSelectedTrade(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="EyeIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.view_trade")
                    }}</span>
                  </b-button>


                  <b-button
                    v-if="$Can('copy_fx') && seeCopyButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="top-btns mt-1"
                    @click="copySelectedTrade(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="CopyIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.copy_trade")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('history_fx') && seeAmendDetailsButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="top-btns mt-1"
                    @click="getAmendDetails(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="ListIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.get_amend_details")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('view_journals_fx') && seeJorurnalEntriesButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="top-btns mt-1"
                    @click="viewJournalEntries(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="EyeIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.view_journal_entries")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('initiate_fx') && seeReversalInitiatedButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-dark"
                    size="sm"
                    class="top-btns mt-1"
                    @click="initiateReversal(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="PlayIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.initiate_revesal")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('reverse_fx') && seeReversedButton "
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-dark"
                    size="sm"
                    class="top-btns mt-1"
                    @click="reverseEntry(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="CornerUpLeftIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.reverse_entry")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('undo_fx') && seeUndoInitiatedButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-dark"
                    size="sm"
                    class="top-btns mt-1"
                    @click="undoInitiateReversal(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="ChevronsLeftIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.undo_initiate_reversal")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('cancel_fx') && seeCancelledButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-danger"
                    size="sm"
                    class="top-btns mt-1"
                    @click="cancelSelectedTrade(data.item)"
                    :disabled="data.item.Cancelled == 1"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="XSquareIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.cancel_trade")
                    }}</span>
                  </b-button>
                </b-popover>
            </div>
            </template>

            <!-- Column: EntryType -->
            <template #cell(EntryType)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.EntryType ? data.item.EntryType : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SCF UTI -->
            <template #cell(TcUti)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{ data.item.TcUti ? data.item.TcUti : "-" }}</strong>
                </span></b
              >
            </template>

            <!-- Column: Counterparty -->
            <template #cell(Counterparty)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.Counterparty ? data.item.Counterparty : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: OrderDate -->
            <template #cell(OrderDate)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.OrderDate != null
                      ? dateFormat(data.item.OrderDate)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: TradeDate -->
            <template #cell(TradeDate)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.TradeDate != null
                      ? dateFormat(data.item.TradeDate)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SettlDate -->
            <template #cell(SettlDate)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.SettlDate != null
                      ? dateFormat(data.item.SettlDate)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: ProductType -->
            <template #cell(ProductType)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.ProductType ? data.item.ProductType : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: CcyPair -->
            <template #cell(CcyPair)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{ data.item.Ccy1 + "" + data.item.Ccy2 }}</strong>
                </span></b
              >
            </template>

            <!-- Column: Client -->
            <template #cell(Client)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.Client ? data.item.Client : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: Client buy Amount -->
            <template #cell(ClientAmount1)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.ClientAmount1
                      ? formatPrice(data.item.ClientAmount1, 2)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: Client Buy Ccy -->
            <template #cell(Ccy1)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{ data.item.Ccy1 ? data.item.Ccy1 : "-" }}</strong>
                </span></b
              >
            </template>

            <!-- Column: Client sell Amount -->
            <template #cell(ClientAmount2)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.ClientAmount2
                      ? formatPrice(data.item.ClientAmount2, 2)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: Client Sell Ccy -->
            <template #cell(Ccy2)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{ data.item.Ccy2 ? data.item.Ccy2 : "-" }}</strong>
                </span></b
              >
            </template>

            <!-- Column: ClientRate -->
            <template #cell(ClientRate)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.ClientRate
                      ? Number(data.item.ClientRate).toFixed(4)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: sfc buy amount -->
            <template #cell(CptyAmount1)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.CptyAmount1
                      ? formatPrice(data.item.CptyAmount1, 2)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SFC buy ccy -->
            <template #cell(sfcBuyCcy)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{ data.item.Ccy1 ? data.item.Ccy1 : "-" }}</strong>
                </span></b
              >
            </template>

            <!-- Column: sfc sell amount -->
            <template #cell(CptyAmount2)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.CptyAmount2
                      ? formatPrice(data.item.CptyAmount2, 2)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SFC sell ccy -->
            <template #cell(sfcSellCcy)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{ data.item.Ccy2 ? data.item.Ccy2 : "-" }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SFC Rate -->
            <template #cell(CptyRate)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.CptyRate
                      ? Number(data.item.CptyRate).toFixed(4)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: Rebate -->
            <template #cell(Rebate)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.Rebate ? data.item.Rebate + "%" : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SpreadAmount -->
            <template #cell(SpreadAmount)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.SpreadAmount
                      ? formatPrice(data.item.SpreadAmount, 2)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SpreadCcy -->
            <template #cell(SpreadCcy)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.SpreadCcy ? data.item.SpreadCcy : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: Spread -->
            <template #cell(Spread)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.Spread ? formatPrice(data.item.Spread, 2) : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: CommAmount -->
            <template #cell(CommAmount)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.CommAmount
                      ? formatPrice(data.item.CommAmount, 2)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: CommCcy -->
            <template #cell(CommCcy)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.CommCcy ? data.item.CommCcy : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: CptyAcc -->
            <template #cell(CptyAcc)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.CptyAcc
                      ? data.item.CptyAcc
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: ClientAcc -->
            <template #cell(ClientAcc)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.ClientAcc ? data.item.ClientAcc : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: CptyTrader -->
            <template #cell(CptyTrader)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.CptyTrader ? data.item.CptyTrader : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: TcUser -->
            <template #cell(TcUser)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.TcUser ? data.item.TcUser : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SpotRef -->
            <template #cell(SpotRef)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.SpotRef
                      ? Number(data.item.SpotRef).toFixed(4)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SettlementType -->
            <template #cell(SettlementType)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.SettlementType ? data.item.SettlementType : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: CutOffTime -->
            <template #cell(CutOffTime)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.CutOffTime ? data.item.CutOffTime : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: DeliverableCcy -->
            <template #cell(DeliverableCcy)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.DeliverableCcy ? data.item.DeliverableCcy : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: ConfirmationTrader -->
            <template #cell(ConfirmationTrader)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.ConfirmationTrader
                      ? data.item.ConfirmationTrader
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: CptyUti -->
            <template #cell(CptyUti)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.CptyUti ? data.item.CptyUti : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: UniqueLinkId -->
            <template #cell(UniqueLinkId)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.UniqueLinkId ? data.item.UniqueLinkId : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: ClearingMemberId -->
            <template #cell(ClearingMemberId)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.ClearingMemberId
                      ? data.item.ClearingMemberId
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: CpRef -->
            <template #cell(CpRef)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{ data.item.CpRef ? data.item.CpRef : "-" }}</strong>
                </span></b
              >
            </template>

            <!-- Column: OrderTime -->
            <template #cell(OrderTime)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{ data.item.OrderTime }}</strong>
                </span></b
              >
            </template>

            <!-- Column: ExecutionTime -->
            <template #cell(ExecutionTime)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{ data.item.ExecutionTime }}</strong>
                </span></b
              >
            </template>

            <!-- Column: OrderTakenVia -->
            <template #cell(TakenVia)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.TakenVia ? data.item.TakenVia : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: OrderGivenThrough -->
            <template #cell(GivenThrg)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.GivenThrg ? data.item.GivenThrg : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: ActingCapacity -->
            <template #cell(ActingCap)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.ActingCap ? data.item.ActingCap : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: TraderNote -->
            <template #cell(TraderNote)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.TraderNote ? data.item.TraderNote : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SavedBy -->
            <template #cell(SavedBy)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.SavedBy ? data.item.SavedBy : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SaveDate -->
            <template #cell(SaveDate)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.SaveDate != null
                      ? dateFormat(data.item.SaveDate)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SaveTime -->
            <template #cell(SaveTime)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{ data.item.SaveTime }}</strong>
                </span></b
              >
            </template>

            <!-- Column: AmendedBy -->
            <template #cell(AmendedBy)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.AmendedBy ? data.item.AmendedBy : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: AmendmentDate -->
            <template #cell(AmendmentDate)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.AmendmentDate != null
                      ? dateFormat(data.item.AmendmentDate)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: AmendmentTime -->
            <template #cell(AmendmentTime)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{ data.item.AmendmentTime }}</strong>
                </span></b
              >
            </template>

            <!-- Column: AmendmentReason -->
            <template #cell(AmendmentReason)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.AmendmentReason ? data.item.AmendmentReason : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: EntryType2 & Main Entry -->
            <template #cell(EntryType2)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{ "Main Entry" }}</strong>
                </span></b
              >
            </template>

            <!-- Column: Cancelled -->
            <template #cell(Cancelled)="data">
              <b
                ><span class="font-weight-bold text-white">
                  <strong>{{
                    data.item.Cancelled == 1 ? "Cancelled" : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: CancelledBy -->
            <template #cell(CancelledBy)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.CancelledBy ? data.item.CancelledBy : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: CancelDate -->
            <template #cell(CancelDate)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.CancelDate != null
                      ? dateFormat(data.item.CancelDate)
                      : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: CancelTime -->
            <template #cell(CancelTime)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{ data.item.CancelTime }}</strong>
                </span></b
              >
            </template>

            <!-- Column: CancelReason -->
            <template #cell(CancelReason)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.CancelReason ? data.item.CancelReason : "-"
                  }}</strong>
                </span></b
              >
            </template>

            

            <!-- trade controls section ------------------------------------------------------>
            <!-- <template #row-details="row">
              <b-card :title="$t('fx_spot_forward_swap.titles.trade_controls')">
                <b-col cols="12">
                  <hr />
                </b-col>

                <div
                  class="d-flex mb-1"
                  style="max-width:1300px!important;white-space:nowrap;overflow-x:scroll;"
                >
                  <b-col v-if="$Can('verify_fx')" cols="2" md="2" sm="12">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="flat-success"
                      :title="$t('fx_spot_forward_swap.buttons.verify_trade')"
                      size="sm"
                      class="top-btns"
                      @click="verifySelectedTrade(row.item)"
                    >
                      <feather-icon
                        icon="CheckSquareIcon"
                        size="24"
                        style="margin-bottom: 0.3rem;"
                      />

                      <span class="trades-font">{{
                        $t("fx_spot_forward_swap.buttons.verify_trade")
                      }}</span>
                    </b-button>
                  </b-col>
                  <b-col v-if="$Can('amend_fx')" cols="2" md="2" sm="12">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="flat-warning"
                      size="sm"
                      class="top-btns"
                      @click="amendUpdate(row.item)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="24"
                        style="margin-bottom: 0.3rem;"
                      />
                      <span class="trades-font">{{
                        $t("fx_spot_forward_swap.buttons.amend_trade")
                      }}</span>
                    </b-button>
                  </b-col>
                  <b-col v-if="$Can('journal_fx')" cols="2" md="2" sm="12">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="flat-primary"
                      size="sm"
                      class="top-btns"
                      @click="createJournalEntries(row.item)"
                    >
                      <feather-icon
                        icon="ClipboardIcon"
                        size="24"
                        style="margin-bottom: 0.3rem;"
                      />
                      <span class="trades-font">{{
                        $t(
                          "fx_spot_forward_swap.buttons.create_Journal_entries"
                        )
                      }}</span>
                    </b-button>
                  </b-col>
                  <b-col v-if="$Can('view_fx')" cols="2" md="2" sm="12">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="flat-secondary"
                      size="sm"
                      class="top-btns"
                      @click="viewSelectedTrade(row.item)"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        size="24"
                        style="margin-bottom: 0.3rem;"
                      />
                      <span class="trades-font">{{
                        $t("fx_spot_forward_swap.buttons.view_trade")
                      }}</span>
                    </b-button>
                  </b-col>
                  <b-col v-if="$Can('copy_fx')" cols="2" md="2" sm="12">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="flat-secondary"
                      size="sm"
                      class="top-btns"
                      @click="copySelectedTrade(row.item)"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        size="24"
                        style="margin-bottom: 0.3rem;"
                      />
                      <span class="trades-font">{{
                        $t("fx_spot_forward_swap.buttons.copy_trade")
                      }}</span>
                    </b-button>
                  </b-col>
                  <b-col v-if="$Can('history_fx')" cols="2" md="2" sm="12">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="flat-secondary"
                      size="sm"
                      class="top-btns"
                      @click="getAmendDetails(row.item)"
                    >
                      <feather-icon
                        icon="ListIcon"
                        size="24"
                        style="margin-bottom: 0.3rem;"
                      />
                      <span class="trades-font">{{
                        $t("fx_spot_forward_swap.buttons.get_amend_details")
                      }}</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$Can('view_journals_fx')"
                    cols="2"
                    md="2"
                    sm="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="flat-secondary"
                      size="sm"
                      class="top-btns"
                      @click="viewJournalEntries(row.item)"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        size="24"
                        style="margin-bottom: 0.3rem;"
                      />
                      <span class="trades-font">{{
                        $t("fx_spot_forward_swap.buttons.view_journal_entries")
                      }}</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$Can('initiate_fx')"
                    cols="2"
                    md="2"
                    xl="2"
                    lg="2"
                    sm="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="flat-dark"
                      size="sm"
                      class="top-btns"
                      @click="initiateReversal(row.item)"
                    >
                      <feather-icon
                        icon="PlayIcon"
                        size="24"
                        style="margin-bottom: 0.3rem;"
                      />
                      <span class="trades-font">{{
                        $t("fx_spot_forward_swap.buttons.initiate_revesal")
                      }}</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$Can('reverse_fx')"
                    cols="2"
                    md="2"
                    xl="2"
                    lg="2"
                    sm="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="flat-dark"
                      size="sm"
                      class="top-btns"
                      @click="reverseEntry(row.item)"
                    >
                      <feather-icon
                        icon="CornerUpLeftIcon"
                        size="24"
                        style="margin-bottom: 0.3rem;"
                      />
                      <span class="trades-font">{{
                        $t("fx_spot_forward_swap.buttons.reverse_entry")
                      }}</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$Can('undo_fx')"
                    cols="2"
                    md="2"
                    xl="2"
                    lg="2"
                    sm="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="flat-dark"
                      size="sm"
                      class="top-btns"
                      @click="undoInitiateReversal(row.item)"
                    >
                      <feather-icon
                        icon="ChevronsLeftIcon"
                        size="24"
                        style="margin-bottom: 0.3rem;"
                      />
                      <span class="trades-font">{{
                        $t(
                          "fx_spot_forward_swap.buttons.undo_initiate_reversal"
                        )
                      }}</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$Can('cancel_fx')"
                    cols="2"
                    md="2"
                    xl="2"
                    lg="2"
                    sm="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="flat-danger"
                      size="sm"
                      class="top-btns"
                      @click="cancelSelectedTrade(row.item)"
                      :disabled="row.item.Cancelled == 1"
                    >
                      <feather-icon
                        icon="XSquareIcon"
                        size="24"
                        style="margin-bottom: 0.3rem;"
                      />
                      <span class="trades-font">{{
                        $t("fx_spot_forward_swap.buttons.cancel_trade")
                      }}</span>
                    </b-button>
                  </b-col>
                </div>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-card>
            </template> -->
          </b-table>
          </div>
        </div>

        <!-- mouse right click -->
        <vue-context ref="contextMenu">
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showColumnsFilter')"
              :disabled="showOrderColumns"
            >
              <feather-icon
                :icon="!showColumnsFilter ? 'EyeIcon' : 'EyeOffIcon'"
                size="16"
              />
              <span class="ml-75">{{
                !showColumnsFilter
                  ? "Show column filters"
                  : "Hide column filters"
              }}</span>
            </b-link>
          </li>
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showMultiplecheckboks')"
              :disabled="showOrderColumns"
            >
              <feather-icon
                :icon="
                  !showMultiplecheckboks ? 'CheckSquareIcon' : 'EyeOffIcon'
                "
                size="16"
              />
              <span class="ml-75">{{
                !showMultiplecheckboks
                  ? "Multiple select"
                  : "Hide multiple select"
              }}</span>
            </b-link>
          </li>
          <li v-if="!showOrderColumns">
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('orderTableColumns')"
            >
            <i class="fa-solid fa-arrow-up-wide-short"></i>
              <span class="ml-75">{{ 'Sort columns'
              }}</span>
            </b-link>
          </li>
          <li
          v-if="selectedMultipleTrades.length > 0"
          >
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc('copyTrades')"
            >
              <feather-icon
                icon="CopyIcon"
                size="16"
              />
              <span class="ml-75">{{ $t("fx_spot_forward_swap.buttons.copy_trade")
              }}</span>
            </b-link>
          </li>
          <li
            v-if="multiSelectListStatusData.length > 0"
            v-for="(optStatus, index) in multiSelectListStatusData"
            :key="index"
          >
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc(optStatus.key)"
            >
              <feather-icon
                :icon="optStatus.icon"
                size="16"
                :stroke="optStatus.color"
              />
              <span class="ml-75">{{ optStatus.title }}</span>
            </b-link>
          </li>
        </vue-context>

        <div class="mx-2" v-if="allFxSFSData.length > 0">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <div v-if="allFxSFSData.length == 0">
          <b-alert variant="info" show>
            <div class="alert-body text-center">
              <span
                ><strong>{{ $t("general_title.not_found_data") }}</strong>
                <br />
                {{
                  $t(
                    "fx_spot_forward_swap.messages.not_found_fx_spot_forward_swap_data"
                  )
                }}</span
              >
            </div>
          </b-alert>
        </div>
        
   
    {{ /*search for trades modal*/ }}
      <SearchTrades
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="searchTrades"
        @searchedData="getSearchedData"
      />

      {{ /*View Selected Trade for trades modal*/ }}
      <ViewSelectedTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="ViewSelectedTrade"
      />

      {{ /*Enter New Trade for trades modal*/ }}
      <EnterNewTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refEnterNewTrade"
        @createdFxSFS="createdFxSFS"
      />
      {{ /*Verify selected Trade for trades modal*/ }}
      <VerifyTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refVerifyTrade"
        @verified="verifiedTrade"
      />
      {{ /*Amend selected Trade for trades modal*/ }}
      <AmendSelectedTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refAmendTrade"
        @updatedData="getUpdatedAmendData"
      />
      {{ /*Copy selected Trade for trades modal*/ }}
      <CopySelectedTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refCopyTrade"
        @copiedFxSFS="copiedFxSFS"
      />
      {{ /*Create Journal selected Trade for trades modal*/ }}
      <CreateJournal
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refCreateJournalTrade"
        @createdJournalEntryArray="createdJournalEntryArray"
      />
      {{ /*View Journal selected Trade for trades modal*/ }}
      <ViewJournalEntries
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refViewJournalEntries"
        @createdJournalEntry="createdJournalEntry"
      />
      {{ /*Inititate reversal Trade for trades modal*/ }}
      <InitiateReversal
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refInitiateReversal"
        @reversaledTrade="reversaledTrade"
      />
      <ReverseEntry
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refReverseEntry"
        @reversedEntry="reversedEntry"
      />
      {{ /*Undo selected Trade for trades modal*/ }}
      <UndoInitiateReversal
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refUndoInitiateReversal"
        @undoInitiate="undoInitiate"
      />
    </b-card>
    </b-overlay>
    {{/** view modal failed multi select process*/}}
      <b-modal
      id="multiSelectProcessModal"
      v-model="showMultiSelectProcessModal"
      :title="$t('multiCheckBoxTitles.failes_process_title')"
      :ok-title="$t('general_buttons.close')"
      @ok="okMultiSelectProcessModal"
      @hidden="okMultiSelectProcessModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      scrollable
      ok-only
      ok-variant="primary"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refMultiSelectProcessModal"
                class="position-relative"
                :items="multiSelectProcessModalTableData"
                selectable
                select-mode="single"
                responsive
                head-variant="light"
                :fields="multiSelectProcessModalTableColumns"
                show-empty
                :empty-text="
                  $t('general_messages.no_matching_recors_found')
                "
              >
                <template #cell(ID)="data">
                  <strong>{{ data.item.ID }}</strong>
                </template>

                <template #cell(Tcuti)="data">
                  <strong>{{ data.item.Tcuti }}</strong>
                </template>

                <template #cell(reason)="data">
                  <strong>{{ data.item.reason }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view modal failed multi select process*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import fXSpotForwardSwapStoreModule from "./FXSpotForwardSwapStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapState, mapGetters, mapActions } from "vuex";
import SearchTrades from "./SearchTrades.vue";
import ViewSelectedTrade from "./ViewSelectedTrade.vue";
import EnterNewTrade from "./EnterNewTrade.vue";
import VerifyTrade from "./VerifyTrade.vue";
import AmendSelectedTrade from "./AmendSelectedTrade.vue";
import CopySelectedTrade from "./CopySelectedTrade.vue";
import CreateJournal from "./CreateJournal.vue";
import ViewJournalEntries from "./ViewJournalEntries.vue";
import InitiateReversal from "./InitiateReversal.vue";
import ReverseEntry from "./ReverseEntry.vue";
import UndoInitiateReversal from "./UndoInitiateReversal.vue";
import VueHorizontal from "vue-horizontal";
import VueContext from "vue-context";
import { debounce } from "lodash";
import draggable from 'vuedraggable'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    SearchTrades,
    ViewSelectedTrade,
    EnterNewTrade,
    VerifyTrade,
    AmendSelectedTrade,
    CopySelectedTrade,
    CreateJournal,
    ViewJournalEntries,
    InitiateReversal,
    ReverseEntry,
    UndoInitiateReversal,
    Treeselect,
    VueHorizontal,
    VueContext,
    draggable
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME = "fxSFS";
    // Register module
    if (!store.hasModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME))
      store.registerModule(
        FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME,
        fXSpotForwardSwapStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME))
        store.unregisterModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {
    this.getOrderFxColumns()
    //option status
    let treeOptionsStatus = [
      {
        id: 1000000,
        label: this.$t("fx_spot_forward_swap.status"),
        children: [],
      },
    ];
    this.fxSFSStatusOptions.forEach((element) => {
      let setStatus = {
        id: element.text,
        label: element.text,
        value: element.text,
        is: "status",
      };
      treeOptionsStatus[0].children.push(setStatus);
    });

    this.statusOptions = treeOptionsStatus;
    //************************** */

    // this.treeStatus.push({id:"Execution",is:"status",label:"Execution",value:"Execution"})

    //get last 7 days trades
    this.getLast7DaysTrades();
    this.getArrangeFxExecutedOrderTakenGivenThrough();
    this.getAllAccounts();

    this.debouncedGetWindowHeight = debounce(this.getWindowHeight, 300);
    window.addEventListener("resize", this.debouncedGetWindowHeight);
    

  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
window.removeEventListener("resize", this.debouncedGetWindowHeight);  
},
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    const fxSFSStatusOptions = [
      { text: "Execution" },
      { text: "Verified" },
      { text: "Journal Created" },
      { text: "Expired Order" },
      { text: "Revers Inititated" },
      { text: "Reversed" },
      { text: "Cancelled" },
    ];

    const fxSFStableColumns = [
      // { key: "show_details", label: "" },
      // {
      //   key: "check",
      //   label: "#",
      //   tdClass: "text-center",
      //   class: `text-center d-none`,
      // },
      {
        stickyColumn: true,
        key: "ID",
        label: "ID",
        // thClass: "text-left",
        thClass: "sticky-column",
        tdClass: "text-center ID_background",
        thStyle: 'width: 50px',
        sortable: true,
        class: "text-start table-padding sticky-column",
        variant: "primary",
      },
      {
        key: "EntryType",
        label: this.$t("fx_spot_forward_swap.table.entry_type"),
        class: "text-start table-padding",
      },
      {
        key: "TcUti",
        label: this.$t("fx_spot_forward_swap.table.sfc_uti"),
        class: "text-start table-padding",
      },
      {
        key: "Counterparty",
        label: this.$t("fx_spot_forward_swap.table.counterparty"),
        class: "text-start table-padding",
      },
      {
        key: "OrderDate",
        label: this.$t("fx_spot_forward_swap.table.order_date"),
        class: "text-start table-padding text-nowrap",
      },
      {
        key: "TradeDate",
        label: this.$t("fx_spot_forward_swap.table.trade_date"),
        class: "text-start table-padding text-nowrap",
        sortable: true,
      },

      {
        key: "SettlDate",
        label: this.$t("fx_spot_forward_swap.table.value_date"),
        class: "text-start table-padding text-nowrap",
        sortable: true,
      },
      {
        key: "ProductType",
        label: this.$t("fx_spot_forward_swap.table.product_type"),
        class: "text-start table-padding",
      },
      {
        key: "CcyPair",
        label: this.$t("fx_spot_forward_swap.table.ccy_pair"),
        class: "text-start table-padding",
      },

      {
        key: "Client",
        label: this.$t("fx_spot_forward_swap.table.client"),
        class: "text-start table-padding",
      },

      {
        key: "ClientAmount1",
        label: this.$t("fx_spot_forward_swap.table.client_buy_amount"),
        class: "text-start table-padding",
        sortable: true,
      },
      {
        key: "Ccy1",
        label: this.$t("fx_spot_forward_swap.table.client_buy_ccy"),
        class: "text-start table-padding",
      },
      {
        key: "ClientAmount2",
        label: this.$t("fx_spot_forward_swap.table.client_sell_amount"),
        class: "text-start table-padding",
        sortable: true,
      },
      {
        key: "Ccy2",
        label: this.$t("fx_spot_forward_swap.table.client_sell_ccy"),
        class: "text-start table-padding",
      },

      {
        key: "ClientRate",
        label: this.$t("fx_spot_forward_swap.table.client_rate"),
        class: "text-start table-padding",
      },
      {
        key: "CptyAmount1",
        label: this.$t("fx_spot_forward_swap.table.sfc_buy_amount"),
        class: "text-start table-padding",
      },
      {
        key: "sfcBuyCcy",
        label: this.$t("fx_spot_forward_swap.table.sfc_buy_ccy"),
        class: "text-start table-padding",
      },
      {
        key: "CptyAmount2",
        label: this.$t("fx_spot_forward_swap.table.sfc_sell_amount"),
        class: "text-start table-padding",
      },
      {
        key: "sfcSellCcy",
        label: this.$t("fx_spot_forward_swap.table.sfc_sell_ccy"),
        class: "text-start table-padding",
      },
      {
        key: "CptyRate",
        label: this.$t("fx_spot_forward_swap.table.sfc_rate"),
        class: "text-start table-padding",
      },
      {
        key: "Rebate",
        label: this.$t("fx_spot_forward_swap.table.rebate"),
        class: "text-start table-padding",
      },
      {
        key: "SpreadAmount",
        label: this.$t("fx_spot_forward_swap.table.spread_amount"),
        class: "text-start table-padding",
      },

      {
        key: "SpreadCcy",
        label: this.$t("fx_spot_forward_swap.table.spread_ccy"),
        class: "text-start table-padding",
      },

      {
        key: "Spread",
        label: this.$t("fx_spot_forward_swap.table.spread"),
        class: "text-start table-padding",
      },

      {
        key: "CommAmount",
        label: this.$t("fx_spot_forward_swap.table.comm_amount"),
        class: "text-start table-padding",
      },

      {
        key: "CommCcy",
        label: this.$t("fx_spot_forward_swap.table.comm_ccy"),
        class: "text-start table-padding",
      },

      {
        key: "CptyAcc",
        label: this.$t("fx_spot_forward_swap.table.counterparty_account"),
        class: "text-start table-padding",
      },

      {
        key: "ClientAcc",
        label: this.$t("fx_spot_forward_swap.table.client_account"),
        class: "text-start table-padding",
      },

      {
        key: "CptyTrader",
        label: this.$t("fx_spot_forward_swap.table.counterparty_trader"),
        class: "text-start table-padding",
      },
      {
        key: "TcUser",
        label: this.$t("fx_spot_forward_swap.table.sfc_user"),
        class: "text-start table-padding",
        sortable: true,
      },
      {
        key: "SpotRef",
        label: this.$t("fx_spot_forward_swap.table.spot_ref"),
        class: "text-start table-padding",
      },
      {
        key: "SettlementType",
        label: this.$t("fx_spot_forward_swap.table.settlement_type"),
        class: "text-start table-padding",
      },
      {
        key: "CutOffTime",
        label: this.$t("fx_spot_forward_swap.table.cutt_off_time"),
        class: "text-start table-padding",
      },
      {
        key: "DeliverableCcy",
        label: this.$t("fx_spot_forward_swap.table.deliverable_ccy"),
        class: "text-start table-padding",
      },
      {
        key: "ConfirmationTrader",
        label: this.$t("fx_spot_forward_swap.table.confirmation_trader"),
        class: "text-start table-padding",
      },
      {
        key: "CptyUti",
        label: this.$t("fx_spot_forward_swap.table.counterparty_uti"),
        class: "text-start table-padding",
      },
      {
        key: "UniqueLinkId",
        label: this.$t("fx_spot_forward_swap.table.unique_link_id"),
        class: "text-start table-padding",
      },
      {
        key: "ClearingMemberId",
        label: this.$t("fx_spot_forward_swap.table.cliearing_member_id"),
        class: "text-start table-padding",
      },
      {
        key: "CpRef",
        label: this.$t("fx_spot_forward_swap.table.counterparty_ref"),
        class: "text-start table-padding",
      },
      {
        key: "OrderTime",
        label: this.$t("fx_spot_forward_swap.table.order_time"),
        class: "text-start table-padding",
      },
      {
        key: "ExecutionTime",
        label: this.$t("fx_spot_forward_swap.table.execution_time"),
        class: "text-start table-padding",
      },

      {
        key: "TakenVia",
        label: this.$t("fx_spot_forward_swap.table.order_taken_via"),
        class: "text-start table-padding",
      },
      {
        key: "GivenThrg",
        label: this.$t("fx_spot_forward_swap.table.order_given_through"),
        class: "text-start table-padding",
      },

      {
        key: "ActingCap",
        label: this.$t("fx_spot_forward_swap.table.acting_capacity"),
        class: "text-start table-padding",
      },

      {
        key: "TraderNote",
        label: this.$t("fx_spot_forward_swap.table.trader_note"),
        class: "text-start table-padding",
      },
      {
        key: "SavedBy",
        label: this.$t("fx_spot_forward_swap.table.saved_by"),
        class: "text-start table-padding",
      },
      {
        key: "SaveDate",
        label: this.$t("fx_spot_forward_swap.table.save_date"),
        class: "text-start table-padding text-nowrap",
        sortable: true,
      },
      {
        key: "SaveTime",
        label: this.$t("fx_spot_forward_swap.table.save_time"),
        class: "text-start table-padding",
      },
      {
        key: "AmendedBy",
        label: this.$t("fx_spot_forward_swap.table.amended_by"),
        class: "text-start table-padding",
      },
      {
        key: "AmendmentDate",
        label: this.$t("fx_spot_forward_swap.table.amendment_date"),
        class: "text-start table-padding text-nowrap",
        sortable: true,
      },
      {
        key: "AmendmentTime",
        label: this.$t("fx_spot_forward_swap.table.amendment_time"),
        class: "text-start table-padding",
      },
      {
        key: "AmendmentReason",
        label: this.$t("fx_spot_forward_swap.table.amendment_reason"),
        class: "text-start table-padding",
      },
      {
        key: "EntryType2",
        label: this.$t("fx_spot_forward_swap.table.entry_type"),
        class: "text-start table-padding",
      },
      {
        key: "Cancelled",
        label: this.$t("fx_spot_forward_swap.table.cancelled"),
        class: "text-start table-padding",
      },
      {
        key: "CancelledBy",
        label: this.$t("fx_spot_forward_swap.table.cancelled_by"),
        class: "text-start table-padding",
      },
      {
        key: "CancelDate",
        label: this.$t("fx_spot_forward_swap.table.canceled_date"),
        class: "text-start table-padding text-nowrap",
        sortable: true,
      },
      {
        key: "CancelTime",
        label: this.$t("fx_spot_forward_swap.table.cancelled_time"),
        class: "text-start table-padding",
      },
      {
        key: "CancelReason",
        label: this.$t("fx_spot_forward_swap.table.cancelled_reason"),
        class: "text-start table-padding",
      },
      // {
      //   stickyColumn: true,
      //   key: "TradeActions",
      //   label: "Actions",
      //   class: "text-center",
      //   isRowHeader: true,
      //   thStyle:"background-color:#ebe9f1;color:black;border: 1px solid #ebe9f1;"
      // },
    ];

    return {
      multiSelectProcessModalTableColumns: [
        {
          key: "ID",
          label: this.$t("multiCheckBoxTitles.failed_proceses_table_columns.id"),
          class: "text-center",
          thStyle: "width: 20%",
        },
        {
          key: "Tcuti",
          label: this.$t("multiCheckBoxTitles.failed_proceses_table_columns.tcUti"),
          sortable: false,
          class: "text-start",
          thStyle: "width: 30%",
        },
        {
          key: "reason",
          label: this.$t("multiCheckBoxTitles.failed_proceses_table_columns.reason"),
          sortable: false,
          class: "text-start",
          thStyle: "width: 50%",
        },
      ],
      userData: JSON.parse(localStorage.getItem("userData")),
      windowHeight: window.innerHeight,
      allFxSFSData: [],
      allFxSFSNotFilterData: [],
      visibleFxSpotInput: false,
      optEntryTypes: [
        { title: "All", value: "" },
        { title: "Order", value: "Order" },
        { title: "Execution", value: "Execution" },
      ],
      optProductTypes: [
        { title: "All", value: "" },
        { title: "SPOT", value: "SPOT" },
        { title: "FORWARD", value: "FORWARD" },
        { title: "SWAP", value: "SWAP" },
      ],
      optbuySells: [
        { title: "All", value: "" },
        { title: "Buy", value: "Buy" },
        { title: "Sell", value: "Sell" },
      ],
      optOrderTypes: [
        { title: "All", value: "" },
        { title: "Limit", value: "Limit" },
        { title: "Market", value: "Market" },
        { title: "Stop Limit", value: "Stop Limit" },
        { title: "Stop", value: "Stop" },
        { title: "Trailing Stop", value: "Trailing Stop" },
      ],
      optTimeInForces: [
        { title: "All", value: "" },
        { title: "DAY", value: "DAY" },
        { title: "GTC", value: "GTC" },
        { title: "GTD", value: "GTD" },
        { title: "FOK", value: "FOK" },
        { title: "FAK", value: "FAK" },
      ],
      optSettlementTypes: [
        { title: "All", value: "" },
        { title: "PHYSICAL", value: "PHYSICAL" },
        { title: "CASH", value: "CASH" },
      ],
      optActingCapacities: [
        { title: "All", value: "" },
        { title: "CASH", value: "CASH" },
        { title: "PRINCIPAL", value: "PRINCIPAL" },
      ],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      sortByDesc: true,
      sortBy: "ID",
      filterOn: [
        "TcUser",
        "Counterparty",
        "Client",
        "OrderGivenThrough",
        "OrderTakenVia",
      ],
      tableColumns: null,
      tableOrjinalColumns: null,
      fxSFStableColumns:fxSFStableColumns,
      filters: {
        EntryType: "",
        TcUti: "",
        Counterparty: "",
      },
      perPage: localStorage.getItem("fxTradesShow") ?? 25,
      perPage: 25,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100, 500],
      filter: null,
      loading: false,
      filterSettlementDate: null,
      filterTradeDate: null,
      canceledReasonText: null,
      statusOptions: [],
      tableColumnsOptions: [],
      fxSFSStatusOptions: fxSFSStatusOptions,
      treeStatus: [],
      treeTablecolumns: [],
      tradeRangeDate: null,
      filterTradeDateStart: null,
      filterTradeDateEnd: null,
      settleRangeDate: null,
      filterSettleDateStart: null,
      filterSettleDateEnd: null,
      optClients: [],
      optCounterParties: [],
      searchedClients: [],
      searchedCounterparties: [],
      optCostCenters: [],
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        mode: "range",
      },
      dateColumnConfig: {
        allowInput:true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      showColumnsFilter: false,
      showMultiplecheckboks: false,
      selectedMultipleTrades: [],
      multiSelectListStatusData: [],
      showMultiSelectProcessModal: false,
      showOrderColumns:false,
      orderColumnsOverlay:false,
      multiSelectProcessModalTableData: [],
      seeAmendDetailsButton : false,
      seeAmendButton : false,
      seeCopyButton : false,
      seeVerifyButton : false,
      seeJournalButton : false,
      seeJorurnalEntriesButton : false,
      seeReversalInitiatedButton : false,
      seeUndoInitiatedButton : false,
      seeReversedButton : false,
      seeCancelledButton:false,
    };
  },
  computed: {
    ...mapState({
      lastSearchData: (state) => state.runLastFxSpotForwardSwapSearchData,
      userColumnsData: (state) => state.usersOrderColumnsFxData,
    }),
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["getLastSearchFxSpotForwardSwap","getUserOrderColumnsFx"]),
    filteredTrades() {
      const filtered = this.allFxSFSData.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key]).includes(this.filters[key])
        );
      });
      return filtered.length > 0
        ? filtered
        : [
            {
              EntryType: "",
              TcUti: "",
              Counterparty: "",
            },
          ];
    },
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    ...mapActions(["saveOrderColumnsFx","getOrderColumnsFx"]),
    getWindowHeight() {

this.windowHeight = window.innerHeight;

this.updateStickyHeaderHeight()

},

updateStickyHeaderHeight() {
  this.$nextTick(() => {
        const headerElements = document.getElementsByClassName("b-table-sticky-header");
        if (headerElements.length > 0) {
          const newHeight = window.innerHeight - 550;
          this.stickyHeaderStyle = `height: ${newHeight}px`;
        }
      });
    },

showTradeControlsPopover(targetTrade,trade){
      if(trade){

        // set default
        this.seeAmendDetailsButton = false
        this.seeVerifyButton = false
        this.seeAmendButton = false
        this.seeCopyButton = false
        this.seeJournalButton = false
        this.seeJorurnalEntriesButton = false
        this.seeReversalInitiatedButton = false
        this.seeUndoInitiatedButton = false
        this.seeReversedButton = false
        this.seeCancelledButton = false


        // set suitable flow buttons

        // see amend details
        if(trade.AmendedBy !=null && trade.AmendmentDate != null && trade.AmendmentTime != null &&  trade.Cancelled == 0){
            this.seeAmendDetailsButton = true
          }

          // see amend trade button
          if(trade.JournalCreated == 0 && trade.Cancelled == 0){
            this.seeAmendButton = true
          }

          // see copy trade button
          if(trade.Cancelled == 0){
            this.seeCopyButton = true
          }

          // see verify
          if(trade.EntryType == 'Execution' && trade.Verified == 0 && trade.Cancelled == 0){
            this.seeVerifyButton = true
          }
          // see journal
          if(trade.EntryType == 'Execution' && trade.Verified == 1 && trade.JournalCreated == 0 && trade.Cancelled == 0){
                this.seeJournalButton = true
          }

          // see trade journalEntries
          if(trade.EntryType == 'Execution' && trade.Verified == 1 && trade.JournalCreated == 1 && trade.Cancelled == 0){
                this.seeJorurnalEntriesButton = true
          }

          // see revers initiated
          if(trade.EntryType == 'Execution' && trade.Verified == 1 && trade.JournalCreated == 1 && trade.ReversalInitiated == 0 && trade.Cancelled == 0 ){
                this.seeReversalInitiatedButton = true
          }

          // see undo initiated
          if(trade.EntryType == 'Execution' && trade.Verified == 1 && trade.JournalCreated == 1 && trade.ReversalInitiated == 1 && trade.Reversed == 0 && trade.Cancelled == 0 ){
                this.seeUndoInitiatedButton = true
          }

          // see reversed
          if(trade.EntryType == 'Execution' && trade.Verified == 1 && trade.JournalCreated == 1 && trade.ReversalInitiated == 1 && trade.Reversed == 0 && trade.Cancelled == 0 ){
                this.seeReversedButton = true
          }

          // if cancelled trade it can not do
          if(trade.CancelDate == null && trade.Cancelled == 0 && trade.CancelTime == null){
                this.seeCancelledButton = true
          }
      }

      this.$root.$emit('bv::show::popover', targetTrade)
    },

    optionRightClicked(click) {
      if (click == "showColumnsFilter") {
        this.showColumnsFilter = !this.showColumnsFilter;
      }
      if (click == "showMultiplecheckboks") {
        this.showMultiplecheckboks = !this.showMultiplecheckboks;
        this.tableColumns[1].class = this.showMultiplecheckboks
          ? "text-center"
          : "text-center d-none";
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades = [];
        _.mapValues(this.allFxSFSData, (v) => (v.selectedTrade = false));
      }
      if(click == "orderTableColumns"){
        this.showOrderColumns = !this.showOrderColumns
        this.orderColumnsOverlay = true
        // hide main columns
        this.$refs.refFXSFSTable.$el.classList.add("hide-rows")

        // visible draggable order columns
        var draggableColumns = document.getElementById("draggableColumns")
        draggableColumns.style.display = ""
      }
    },
    getOrderFxColumns(){

    this.getOrderColumnsFx().then((res) => {
      // get order user columns
      if (this.userColumnsData.find((data) => data.user == this.user.name)) {
        let orderedColumnsData = this.getUserOrderColumnsFx
        this.tableColumns = orderedColumnsData
        this.tableOrjinalColumns = this.fxSFStableColumns
        
      } else {
      
        this.tableColumns = this.fxSFStableColumns
        this.tableOrjinalColumns = this.fxSFStableColumns
      }
      
      //******************************* */

        // tree columns options
        let treeColumnsOption = [
      {
        id: 1000000,
        label: this.$t("fx_spot_forward_swap.columns"),
        children: [],
      },
    ];

    this.tableOrjinalColumns.forEach((element, index) => {
      if (element.key != "ID") {
        var setColumn = {
          id: index,
          label: element.key,
          value: element.key,
          is: "column",
        };
        treeColumnsOption[0].children.push(setColumn);

        if(this.tableColumns.find((column) => column.key === element.key)){
          this.treeTablecolumns.push(setColumn);
        }
      }
      
    });
    this.tableColumnsOptions = treeColumnsOption;

    //this.treeTablecolumns = treeColumnsOption[0].children;
    //******************* */

    }).catch((error) => {
      this.wrongToastMessage(this.$t("general_messages.columns_data_notfound"));
      console.log(error);
    });
    },
    onSaveOrderColumns(){
    if(this.tableColumns && this.tableColumns.length > 0){
        this.saveOrderColumnsFx(this.tableColumns).then((res) => {
          // visible main columns
        this.$refs.refFXSFSTable.$el.classList.remove("hide-rows");
        // hide draggable order columns
        var draggableColumns = document.getElementById("draggableColumns");
        draggableColumns.style.display = "none";
        this.showOrderColumns = false;
        this.orderColumnsOverlay = false
        this.successOrderColumnsMessage()
        }).catch((error) => {
          console.log(error);
        });
    } else {
      this.wrongToastMessage(this.$t("general_messages.columns_data_notfound"));
    }
    },
    onCancelOrderColumn(){
    // visible main columns
    this.$refs.refFXSFSTable.$el.classList.remove("hide-rows");
    // hide draggable order columns
    var draggableColumns = document.getElementById("draggableColumns");
    draggableColumns.style.display = "none";
    this.showOrderColumns = false;
    this.orderColumnsOverlay = false;
    },
    selectTreeColumn(node){

    if(this.tableOrjinalColumns.find((c) => c.key === node.value)){
      let columnIndex = this.tableOrjinalColumns.findIndex((i) => i.key === node.value)
      let columnData = this.tableOrjinalColumns.find((c) => c.key === node.value)
      this.tableColumns.splice(columnIndex,0,columnData)
    }        

    },
    deSelectTreeColumn(node){
    if(this.tableColumns.find((c) => c.key === node.value)){
      let columnIndex = this.tableColumns.findIndex((i) => i.key === node.value)
      this.tableColumns.splice(columnIndex,1)
    }  
    },
    multiSelectRow(check, trade) {
      if (check) {
        //add right click check true trades
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades.push(trade);
      } else {
        // remove false check trades
        let clonedTrades = JSON.parse(
          JSON.stringify(this.selectedMultipleTrades)
        );
        _.remove(clonedTrades, {
          ID: trade.ID,
        });

        this.selectedMultipleTrades = clonedTrades;
      }
    },
    multipleChangeOprFunc(status) {
      let tradesCount = null;
      let trades = [];

      if (status == "Verify") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 0,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 0,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Create Journal") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Revers Inititated") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Reversed") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Undo Initiate Reversal") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Cancel") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        });
      }

      if (status == "copyTrades") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        });
      }

      if (trades.length > 0 && status) {

        if(status == "copyTrades"){
        this.tradeMultipleSelectCopyOpr(tradesCount, trades);
        } else {
        this.tradeMultipleChangeStatusOpr(tradesCount, trades, status);
        }

      }
    },
    getLast7DaysTrades() {
      this.$checkAccessRight("FxExecuted", "Search").then((res) => {
        if (res.data.valid) {
          this.loading = true;
          store
            .dispatch("fxSFS/getLast7DaysFxTrades")
            .then((res) => {
              this.allFxSFSData = [];
              if (res.data.length > 0) {
                this.visibleFxSpotInput = true;
              } else {
                this.visibleFxSpotInput = false;
              }
              this.totalRows = res.data.length;
              this.allFxSFSData = res.data;
              this.allFxSFSNotFilterData = res.data;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getArrangeFxExecutedOrderTakenGivenThrough() {
      store
        .dispatch(
          "fxSFS/fetchArrangeFxExecutedOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs.push({ title: "All", value: "" });

            if (res.data.orderGivenThroughs.length > 0) {
              res.data.orderGivenThroughs.forEach((data) => {
                this.optOrderGivenThroughs.push({ title: data, value: data });
              });
            }
            this.optOrderTakenVias.push({ title: "All", value: "" });

            if (res.data.orderTakenVias.length > 0) {
              res.data.orderTakenVias.forEach((data) => {
                this.optOrderTakenVias.push({ title: data, value: data });
              });
            }
          } 
          // else {
          //   this.errorMessage(
          //     this.$t("fx_spot_forward_swap.messages.creating_message")
          //   );
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllAccounts() {
      store
        .dispatch("fxSFS/getAccountCostCenter",{type:'FX'})
        .then((res) => {
          this.optClients = [];
          this.optCounterParties = [];
          this.searchedClients = res.data.clients;
          this.searchedCounterparties = res.data.clients;
          this.optCostCenters = [];
          this.optCostCenters = res.data.cost_centers;
          this.$refs.refEnterNewTrade.tradeForm.costCenter = res.data.default_cost_center;
          this.$refs.refAmendTrade.tradeForm.costCenter = res.data.default_cost_center;

          if (res.data.clients.length > 0) {
            res.data.clients.forEach((data) => {
              this.optClients.push(data.AccountName);
            });

            res.data.clients.forEach((data) => {
              this.optCounterParties.push(data.AccountName);
            });
          } 
        })
        .catch((error) => {
          console.log(error);
        });
    },

    initiateReversal(item) {
      this.$checkAccessRight("FxExecuted", "InitiateReversal").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            if (item.ID != 0) {
              this.checkJournalCreated("InitiateReversal", item.TcUti).then(
                (res) => {
                  if (res.data.info == true) {
                    this.checkEntryReversed("Initiate", item.TcUti).then(
                      (res) => {
                        if (res.data.info == true) {
                          this.checkReversalInitiated(
                            "InitiateReversal",
                            item.TcUti
                          ).then((res) => {
                            if (res.data.info == true) {
                              store
                                .dispatch("fxSFS/getAmendFxInitialise", {
                                  auth: this.user,
                                  tradeId: item.ID,
                                  tcUti: item.TcUti,
                                })
                                .then((res) => {
                                  if (res.data.info == "Pass") {
                                    if (this.getChoice()) {
                                      this.$refs.refInitiateReversal.fetchedTradeData =
                                        res.data.trade;

                                      //set timeline datas
                                      let actionsArray = [];
                                      if (
                                        res.data.trade.SavedBy != null &&
                                        res.data.trade.SavedBy != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Saved",
                                          user: res.data.trade.SavedBy ?? "",
                                          date:
                                            res.data.trade.SaveDate.split(
                                              " "
                                            )[0] +
                                            " " +
                                            res.data.trade.SaveTime.split(
                                              " "
                                            )[0],
                                          reason: "-",
                                        });
                                      }

                                      if (
                                        res.data.trade.AmendedBy != null &&
                                        res.data.trade.AmendedBy != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Amended",
                                          user: res.data.trade.AmendedBy ?? "",
                                          date:
                                            res.data.trade.AmendmentDate.split(
                                              " "
                                            )[0] +
                                            " " +
                                            res.data.trade.AmendmentTime.split(
                                              " "
                                            )[0],
                                          reason:
                                            res.data.trade.AmendmentReason,
                                        });
                                      }
                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0]
                                            .VerificationUser != null &&
                                          res.data.actions[0]
                                            .VerificationUser != ""
                                        ) {
                                          this.$refs.refInitiateReversal.checkVerify = true;
                                          actionsArray.push({
                                            type: "Verified",
                                            user:
                                              res.data.actions[0]
                                                .VerificationUser ?? "",
                                            date:
                                              res.data.actions[0]
                                                .VerificationDateTime,
                                            reason: "-",
                                          });
                                        }
                                      }

                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0].JournalUser !=
                                            null &&
                                          res.data.actions[0].JournalUser != ""
                                        ) {
                                          actionsArray.push({
                                            type: "Journal Created",
                                            user:
                                              res.data.actions[0].JournalUser ??
                                              "",
                                            date:
                                              res.data.actions[0]
                                                .JournalDateTime,
                                            reason: "-",
                                          });
                                        }
                                      }

                                      if (
                                        res.data.trade.CancelledBy != null &&
                                        res.data.trade.CancelledBy != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Canceled",
                                          user:
                                            res.data.trade.CancelledBy ?? "",
                                          date:
                                            res.data.trade.CancelDate.split(
                                              " "
                                            )[0] +
                                            " " +
                                            res.data.trade.CancelTime.split(
                                              " "
                                            )[0],
                                          reason: res.data.trade.CancelReason,
                                        });
                                      }

                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0]
                                            .ReversalInitiatedBy != null &&
                                          res.data.actions[0]
                                            .ReversalInitiatedBy != ""
                                        ) {
                                          actionsArray.push({
                                            type: "Reversal Initiated",
                                            user:
                                              res.data.actions[0]
                                                .ReversalInitiatedBy ?? "",
                                            date:
                                              res.data.actions[0]
                                                .ReversalInitiatedDateTime,
                                            reason:
                                              res.data.actions[0]
                                                .ReversalInitiatedReason,
                                          });
                                        }
                                      }

                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0].ReversedBy !=
                                            null &&
                                          res.data.actions[0].ReversedBy != ""
                                        ) {
                                          actionsArray.push({
                                            type: "Reversed",
                                            user:
                                              res.data.actions[0].ReversedBy ??
                                              "",
                                            date:
                                              res.data.actions[0]
                                                .ReversedDateTime,
                                            reason: "-",
                                          });
                                        }
                                      }

                                      this.$refs.refInitiateReversal.actionsData = actionsArray;
                                      this.$refs.refInitiateReversal.initiateReversalTradeModal = true;
                                    } else {
                                      let verifyList = [];
                                      verifyList.push(item);
                                      this.tradeMultipleChangeStatusOpr(
                                        1,
                                        verifyList,
                                        "Revers Inititated"
                                      );
                                    }
                                  } else {
                                    this.notFoundMessage();
                                  }
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            } else {
                              this.checkReversalInitiatedMessage(
                                res.data.message
                              );
                            }
                          });
                        } else {
                          this.checkEntryReversedMessage(res.data.message);
                        }
                      }
                    );
                  } else {
                    this.checkJournalCreatedMessage(res.data.message);
                  }
                }
              );
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    reverseEntry(item) {
      this.$checkAccessRight("FxExecuted", "Reverse").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            if (item.ID != 0) {
              this.checkJournalCreated("Reverse", item.TcUti).then((res) => {
                if (res.data.info == true) {
                  this.checkEntryReversed("Reverse", item.TcUti).then((res) => {
                    if (res.data.info == true) {
                      this.checkReversalInitiated("Reverse", item.TcUti).then(
                        (res) => {
                          if (res.data.info == true) {
                            store
                              .dispatch("fxSFS/getFxReverseEntry", {
                                auth: this.user,
                                tradeId: item.ID,
                                tcUti: item.TcUti,
                              })
                              .then((res) => {
                                if (res.data.info == "Pass") {
                                  if (this.getChoice()) {
                                    this.$refs.refReverseEntry.fetchedTradeData =
                                      res.data.trade;

                                    //set timeline datas
                                    let actionsArray = [];
                                    if (
                                      res.data.trade.SavedBy != null &&
                                      res.data.trade.SavedBy != ""
                                    ) {
                                      actionsArray.push({
                                        type: "Saved",
                                        user: res.data.trade.SavedBy ?? "",
                                        date:
                                          res.data.trade.SaveDate.split(
                                            " "
                                          )[0] +
                                          " " +
                                          res.data.trade.SaveTime.split(" ")[0],
                                        reason: "-",
                                      });
                                    }

                                    if (
                                      res.data.trade.AmendedBy != null &&
                                      res.data.trade.AmendedBy != ""
                                    ) {
                                      actionsArray.push({
                                        type: "Amended",
                                        user: res.data.trade.AmendedBy ?? "",
                                        date:
                                          res.data.trade.AmendmentDate.split(
                                            " "
                                          )[0] +
                                          " " +
                                          res.data.trade.AmendmentTime.split(
                                            " "
                                          )[0],
                                        reason: res.data.trade.AmendmentReason,
                                      });
                                    }
                                    if (res.data.actions.length > 0) {
                                      if (
                                        res.data.actions[0].VerificationUser !=
                                          null &&
                                        res.data.actions[0].VerificationUser !=
                                          ""
                                      ) {
                                        this.$refs.refReverseEntry.checkVerify = true;
                                        actionsArray.push({
                                          type: "Verified",
                                          user:
                                            res.data.actions[0]
                                              .VerificationUser ?? "",
                                          date:
                                            res.data.actions[0]
                                              .VerificationDateTime,
                                          reason: "-",
                                        });
                                      }
                                    }

                                    if (res.data.actions.length > 0) {
                                      if (
                                        res.data.actions[0].JournalUser !=
                                          null &&
                                        res.data.actions[0].JournalUser != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Journal Created",
                                          user:
                                            res.data.actions[0].JournalUser ??
                                            "",
                                          date:
                                            res.data.actions[0].JournalDateTime,
                                          reason: "-",
                                        });
                                      }
                                    }

                                    if (
                                      res.data.trade.CancelledBy != null &&
                                      res.data.trade.CancelledBy != ""
                                    ) {
                                      actionsArray.push({
                                        type: "Canceled",
                                        user: res.data.trade.CancelledBy ?? "",
                                        date:
                                          res.data.trade.CancelDate.split(
                                            " "
                                          )[0] +
                                          " " +
                                          res.data.trade.CancelTime.split(
                                            " "
                                          )[0],
                                        reason: res.data.trade.CancelReason,
                                      });
                                    }

                                    if (res.data.actions.length > 0) {
                                      if (
                                        res.data.actions[0]
                                          .ReversalInitiatedBy != null &&
                                        res.data.actions[0]
                                          .ReversalInitiatedBy != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Reversal Initiated",
                                          user:
                                            res.data.actions[0]
                                              .ReversalInitiatedBy ?? "",
                                          date:
                                            res.data.actions[0]
                                              .ReversalInitiatedDateTime,
                                          reason:
                                            res.data.actions[0]
                                              .ReversalInitiatedReason,
                                        });
                                      }
                                    }

                                    if (res.data.actions.length > 0) {
                                      if (
                                        res.data.actions[0].ReversedBy !=
                                          null &&
                                        res.data.actions[0].ReversedBy != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Reversed",
                                          user:
                                            res.data.actions[0].ReversedBy ??
                                            "",
                                          date:
                                            res.data.actions[0]
                                              .ReversedDateTime,
                                          reason: "-",
                                        });
                                      }
                                    }

                                    this.$refs.refReverseEntry.actionsData = actionsArray;
                                    this.$refs.refReverseEntry.reverseEntryTradeModal = true;
                                  } else {
                                    let verifyList = [];
                                    verifyList.push(item);
                                    this.tradeMultipleChangeStatusOpr(
                                      1,
                                      verifyList,
                                      "Reversed"
                                    );
                                  }
                                } else {
                                  this.notFoundMessage();
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          } else {
                            this.checkReversalInitiatedMessage(
                              res.data.message
                            );
                          }
                        }
                      );
                    } else {
                      this.checkEntryReversedMessage(res.data.message);
                    }
                  });
                } else {
                  this.checkJournalCreatedMessage(res.data.message);
                }
              });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    undoInitiateReversal(item) {
      this.$checkAccessRight("FxExecuted", "UndoInitiateReversal").then(
        (res) => {
          if (res.data.valid) {
            if (item.Cancelled == 0) {
              if (item.ID != 0) {
                this.checkEntryReversed(
                  "UndoInitiateReversal",
                  item.TcUti
                ).then((res) => {
                  if (res.data.info == true) {
                    this.checkReversalInitiated(
                      "UndoInitiateReversal",
                      item.TcUti
                    ).then((res) => {
                      if (res.data.info == true) {
                        store
                          .dispatch("fxSFS/getFxUndoInitiateReversal", {
                            auth: this.user,
                            tradeId: item.ID,
                            tcUti: item.TcUti,
                          })
                          .then((res) => {
                            if (res.data.info == "Pass") {
                              if (this.getChoice()) {
                                this.$refs.refUndoInitiateReversal.fetchedTradeData =
                                  res.data.trade;

                                //set timeline datas
                                let actionsArray = [];
                                if (
                                  res.data.trade.SavedBy != null &&
                                  res.data.trade.SavedBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Saved",
                                    user: res.data.trade.SavedBy ?? "",
                                    date:
                                      res.data.trade.SaveDate.split(" ")[0] +
                                      " " +
                                      res.data.trade.SaveTime.split(" ")[0],
                                    reason: "-",
                                  });
                                }

                                if (
                                  res.data.trade.AmendedBy != null &&
                                  res.data.trade.AmendedBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Amended",
                                    user: res.data.trade.AmendedBy ?? "",
                                    date:
                                      res.data.trade.AmendmentDate.split(
                                        " "
                                      )[0] +
                                      " " +
                                      res.data.trade.AmendmentTime.split(
                                        " "
                                      )[0],
                                    reason: res.data.trade.AmendmentReason,
                                  });
                                }
                                if (res.data.actions.length > 0) {
                                  if (
                                    res.data.actions[0].VerificationUser !=
                                      null &&
                                    res.data.actions[0].VerificationUser != ""
                                  ) {
                                    this.$refs.refUndoInitiateReversal.checkVerify = true;
                                    actionsArray.push({
                                      type: "Verified",
                                      user:
                                        res.data.actions[0].VerificationUser ??
                                        "",
                                      date:
                                        res.data.actions[0]
                                          .VerificationDateTime,
                                      reason: "-",
                                    });
                                  }
                                }

                                if (res.data.actions.length > 0) {
                                  if (
                                    res.data.actions[0].JournalUser != null &&
                                    res.data.actions[0].JournalUser != ""
                                  ) {
                                    actionsArray.push({
                                      type: "Journal Created",
                                      user:
                                        res.data.actions[0].JournalUser ?? "",
                                      date: res.data.actions[0].JournalDateTime,
                                      reason: "-",
                                    });
                                  }
                                }

                                if (
                                  res.data.trade.CancelledBy != null &&
                                  res.data.trade.CancelledBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Canceled",
                                    user: res.data.trade.CancelledBy ?? "",
                                    date:
                                      res.data.trade.CancelDate.split(" ")[0] +
                                      " " +
                                      res.data.trade.CancelTime.split(" ")[0],
                                    reason: res.data.trade.CancelReason,
                                  });
                                }

                                if (res.data.actions.length > 0) {
                                  if (
                                    res.data.actions[0].ReversalInitiatedBy !=
                                      null &&
                                    res.data.actions[0].ReversalInitiatedBy !=
                                      ""
                                  ) {
                                    actionsArray.push({
                                      type: "Reversal Initiated",
                                      user:
                                        res.data.actions[0]
                                          .ReversalInitiatedBy ?? "",
                                      date:
                                        res.data.actions[0]
                                          .ReversalInitiatedDateTime,
                                      reason:
                                        res.data.actions[0]
                                          .ReversalInitiatedReason,
                                    });
                                  }
                                }

                                if (res.data.actions.length > 0) {
                                  if (
                                    res.data.actions[0].ReversedBy != null &&
                                    res.data.actions[0].ReversedBy != ""
                                  ) {
                                    actionsArray.push({
                                      type: "Reversed",
                                      user:
                                        res.data.actions[0].ReversedBy ?? "",
                                      date:
                                        res.data.actions[0].ReversedDateTime,
                                      reason: "-",
                                    });
                                  }
                                }

                                this.$refs.refUndoInitiateReversal.actionsData = actionsArray;
                                this.$refs.refUndoInitiateReversal.undoInitiateTradeModal = true;
                              } else {
                                let verifyList = [];
                                verifyList.push(item);
                                this.tradeMultipleChangeStatusOpr(
                                  1,
                                  verifyList,
                                  "Undo Initiate Reversal"
                                );
                              }
                            } else {
                              this.notFoundMessage();
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      } else {
                        this.checkReversalInitiatedMessage(res.data.message);
                      }
                    });
                  } else {
                    this.checkEntryReversedMessage(res.data.message);
                  }
                });
              } else {
                this.tradeIDDoesntZeroMessage();
              }
            } else {
              this.canceledToastMessage();
            }
          } else {
            this.notAuthToastMessage();
          }
        }
      );
    },
    cancelSelectedTrade(item) {
      this.$checkAccessRight("FxExecuted", "Cancel").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            this.checkJournalCreated("Cancel", item.TcUti).then((res) => {
              if (res.data.info == true) {
                if (item.ID != 0) {
                  this.tradeCancelReasonText().then((res) => {
                    if (res) {
                      store
                        .dispatch("fxSFS/getCanceledFxExecuteById", {
                          auth: this.user,
                          tradeId: item.ID,
                          reason: this.canceledReasonText,
                          trade: item,
                        })
                        .then((res) => {
                          if (res.data.info == "Pass") {
                            this.tradeCancelledMessage();
                            this.runLastSearch();
                            this.canceledReasonText = null;
                          } else {
                            this.tradeCancelledErrorMessage(res.data.message);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    } else {
                      this.cancelOpearionNotDoneMessage();
                    }
                  });
                } else {
                  this.tradeIDDoesntZeroMessage();
                }
              } else {
                this.checkJournalCreatedMessage(res.data.message);
              }
            });
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    async tradeCancelReasonText() {
      const self = this;
      const { value: reason } = await this.$swal({
        title: self.$t("fx_spot_forward_swap.reason.cancel_reason"),
        text: self.$t("fx_spot_forward_swap.reason.please_enter_cancel_reason"),
        input: "textarea",
        inputPlaceholder: self.$t(
          "fx_spot_forward_swap.reason.please_enter_cancel_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("fx_spot_forward_swap.reason.submit"),
        cancelButtonText: self.$t("fx_spot_forward_swap.reason.Cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              self.canceledReasonText = reason;
              resolve();
            } else {
              resolve(
                self.$t("fx_spot_forward_swap.reason.you_need_write_reason")
              );
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      });

      if (reason) {
        return new Promise(function(resolve, reject) {
          resolve(true);
        });
      }
    },

    tradeMultipleChangeStatusOpr(tradesCount, trades, status) {
      const self = this;
      this.$swal({
        title: self.$t("multiCheckBoxTitles.change_status_title"),
        text:
          self.$t("multiCheckBoxTitles.trades_count_message_1") +
          tradesCount +
          self.$t("multiCheckBoxTitles.trades_count_message_2"),
        input:
          status == "Revers Inititated" || status == "Cancel"
            ? "textarea"
            : "",
        inputPlaceholder: self.$t(
          "fx_spot_forward_swap.reason.please_enter_reversal_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("multiCheckBoxTitles.change_status"),
        cancelButtonText: self.$t("general_buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              resolve();
            } else {
              resolve(
                self.$t("fx_spot_forward_swap.reason.you_need_write_reason")
              );
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;

          // if status journal set journal data
          if (status == "Create Journal") {
            if (trades.length > 0) {
              var setTradesData = [];
              trades.forEach((trade) => {
                setTradesData.push(self.setTradeData(trade));
              });
            }
            trades = setTradesData;
          }

          store
            .dispatch("fxSFS/tradeMultipleChangeFxStatusOpr", {
              auth: self.user,
              trades: trades,
              status: status,
              reason: result.value ? result.value : null,
            })
            .then((res) => {
              self.loading = false;
              
              if(res.data.failedProcesses.length > 0){
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesWarningMessage()
                self.showMultiSelectProcessModal = true
                self.multiSelectProcessModalTableData = res.data.failedProcesses;
              } else {
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesSuccessMessage();
                if(trades.length==1 && status == 'Revers Inititated'){
                  let val = {info: true, tradeId:trades[0].ID, tcUti:trades[0].tcUti, reason: result.value ? result.value : null}
                  this.reversaledTrade(val)
                }
                _.mapValues(this.allFxSFSData, (v) => (v.selectedTrade = false));
              
                self.okMultiSelectProcessModal()
              }
              // if (
              //   self.lastSearchData.find((data) => data.user == this.user.name)
              // ) {
              //   self.runLastSearch();
              // } else {
              //   self.getLast7DaysTrades();
              // }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    tradeMultipleSelectCopyOpr(tradesCount, trades) {
      const self = this;
      this.$swal({
        title: self.$t("multiCheckBoxTitles.change_copy_title"),
        text:
          tradesCount +
          self.$t("multiCheckBoxTitles.trades_copy_message"),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("multiCheckBoxTitles.copy_trades"),
        cancelButtonText: self.$t("general_buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;

          // set trade data
            if (trades.length > 0) {
              var setTradesData = [];
              trades.forEach((trade) => {
                setTradesData.push(self.setCopyTradeData(trade));
              });
            } 
            trades = setTradesData;
            
          store
            .dispatch("fxSFS/tradeMultipleSelectFxCopyOpr", {
              auth: self.user,
              trades: trades,
            })
            .then((res) => {
              self.loading = false;
              self.selectedMultipleTrades = [];

              if(res.data.failedCopyOpr.length > 0){
                self.multiSelectCopyWarningMessage()
                self.okMultiSelectProcessModal()
                self.showMultiSelectProcessModal = true
                self.multiSelectProcessModalTableData = res.data.failedCopyOpr;
              }else if(res.data.info == 'not_found'){
                self.wrongToastMessage(res.data.message)
              } else {
                self.multiSelectAllTradesCopySuccessMessage();
                self.okMultiSelectProcessModal()
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    okMultiSelectProcessModal(){
      this.showMultiSelectProcessModal = false
      this.multiSelectProcessModalTableData = []
      _.mapValues(this.allFxSFSData, (v) => (v.selectedTrade = false));

      if (
        this.lastSearchData.find((data) => data.user == this.user.name)
      ) {
        this.runLastSearch();
      } else {
        this.getLast7DaysTrades();
      }
    },
    searchForTradesModal() {
      this.$checkAccessRight("FxExecuted", "Search").then((res) => {
        if (res.data.valid) {
          this.$refs.searchTrades.searchForTradesModal = true;
          // this.$refs.searchTrades.getAllAccounts();
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getSearchedData(data) {
      this.totalRows = data.length;
      if (data.length > 0) {
        this.visibleFxSpotInput = true;
      }
      this.allFxSFSData = data;
      this.allFxSFSNotFilterData = data;
      this.$refs.searchTrades.searchForTradesModal = false;
      this.filterTradeDate = null
      this.tradeRangeDate = null
      this.filterTradeDateStart = null
      this.filterTradeDateEnd = null
      this.filterSettlementDate = null;
      this.settleRangeDate = null
      this.filterSettleDateStart = null
      this.filterSettleDateEnd = null
    },
    getUpdatedAmendData(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    createNewTrade() {
      this.$checkAccessRight("FxExecuted", "Save").then((res) => {
        if (res.data.valid) {
          this.$refs.refEnterNewTrade.getSavedDefaultEntries();
          this.$refs.refEnterNewTrade.enterNewTradeModal = true;
          // this.$refs.refEnterNewTrade.getAllAccounts();
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    createdFxSFS(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    copiedFxSFS(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    createdJournalEntryArray(journalData) {
      if (journalData.success) {
        this.$refs.refViewJournalEntries.fetchedTradeData = journalData.trade;
        this.$refs.refViewJournalEntries.journalEntries = journalData.entries;
        this.$refs.refViewJournalEntries.viewJournalTradeModal = true;
        this.$refs.refViewJournalEntries.createJournalOperation = true;
      }
    },
    createdJournalEntry(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    reversaledTrade(val) {
      const self = this;
      if (val.info) {
        this.runLastSearch();
        this.$swal({
          title: self.$t("fx_spot_forward_swap.mail.title"),
          text: self.$t("fx_spot_forward_swap.mail.message"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: self.$t("fx_spot_forward_swap.mail.send"),
          cancelButtonText: self.$t("fx_spot_forward_swap.mail.not_send"),
          customClass: {
            confirmButton: "btn btn-success mr-1",
            cancelButton: "btn btn-danger",
            denyButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            store
              .dispatch("fxSFS/sendInitiateReversalMail", {
                auth: self.user,
                tcUti: val.tcUti,
                tradeId: val.tradeId,
                reason: val.reason,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  self.sendedMailSuccess();
                } else {
                  self.errorMessage(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }
    },
    reversedEntry(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    undoInitiate(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    verifiedTrade(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    legendClass(item, type) {
      const executedRowColor = "table-executed";
      const verifiedRowColor = "table-verified";
      const journalCreateRowColor = "table-journalCreate";
      const expiredOrderRowColor = "table-expiredOrder";
      const reversedInitiateRowColor = "table-reverseInitiated";
      const reversedRowColor = "table-reversed";
      const canceledRowColor = "table-canceled";

      if (!item || type !== "row") {
        return;
      }

      if (item.Cancelled == 1) {
        return canceledRowColor;
      }
      if (item.Reversed == 1) {
        return reversedRowColor;
      }
      if (item.ReversalInitiated == 1) {
        return reversedInitiateRowColor;
      }
      if (item.ExpiredOrder == 1) {
        return expiredOrderRowColor;
      }
      if (item.JournalCreated == 1) {
        return journalCreateRowColor;
      }
      if (item.Verified == 1) {
        return verifiedRowColor;
      }
      if (item.EntryType == "Execution") {
        return executedRowColor;
      }
    },
    onRowSelected(row) {
      row._showDetails = !row._showDetails;
    },
    runLastSearch() {
      this.filterTradeDate = null
      this.tradeRangeDate = null
      this.filterTradeDateStart = null
      this.filterTradeDateEnd = null
      this.filterSettlementDate = null;
      this.settleRangeDate = null
      this.filterSettleDateStart = null
      this.filterSettleDateEnd = null

      if (
        typeof this.lastSearchData != "Object" &&
        this.lastSearchData.find((data) => data.user == this.user.name)
      ) {
        this.$checkAccessRight("FxExecuted", "Search").then((res) => {
          if (res.data.valid) {
            this.loading = true;
            if (this.getLastSearchFxSpotForwardSwap) {
              store
                .dispatch("fxSFS/searchFxExecuted", {
                  auth: this.user,
                  searchData: this.getLastSearchFxSpotForwardSwap,
                })
                .then((res) => {
                  this.allFxSFSData = [];
                  if (res.data.length > 0) {
                    this.visibleFxSpotInput = true;
                  } else {
                    this.visibleFxSpotInput = true;
                  }
                  this.totalRows = res.data.length;
                  this.allFxSFSData = res.data;
                  this.allFxSFSNotFilterData = res.data;
                  this.loading = false;
                })
                .catch((error) => {
                  this.loading = false;
                  console.log(error);
                });
            } else {
              this.notFoundSearchData();
            }
          } else {
            this.notAuthToastMessage();
          }
        });
      } else {
        this.getLast7DaysTrades()
      }
    },
    getFilterTradeDate(e) {
      if (this.tradeRangeDate != null) {
        this.$checkAccessRight("FxExecuted", "Search").then((res) => {
          if (res.data.valid) {
            let filterTradeDateStart = null;
            let filterTradeDateEnd = null;

            if (
              this.filterTradeDateStart != null &&
              this.filterTradeDateEnd != null
            ) {
              filterTradeDateStart = this.YMDdateFormat(
                this.filterTradeDateStart
              );
              filterTradeDateEnd = this.YMDdateFormat(this.filterTradeDateEnd);
            }
            if (filterTradeDateStart != null && filterTradeDateEnd != null) {
              this.loading = true;
              store
                .dispatch("fxSFS/fetchFilterTradeDate", {
                  auth: this.user,
                  tradeStartDate: filterTradeDateStart,
                  tradeEndDate: filterTradeDateEnd,
                })
                .then((res) => {
                  this.allFxSFSData = [];
                  if (res.data.filterDatas.length > 0) {
                    this.visibleFxSpotInput = true;
                    this.totalRows = res.data.filterDatas.length;
                    this.allFxSFSData = res.data.filterDatas;
                  } else {
                    this.visibleFxSpotInput = false;
                  }

                  this.loading = false;
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            this.notAuthToastMessage();
          }
        });
      }
    },
    getFilterSettlementDate() {
      if (this.settleRangeDate != null) {
        this.$checkAccessRight("FxExecuted", "Search").then((res) => {
          if (res.data.valid) {
            let filterSettleDateStart = null;
            let filterSettleDateEnd = null;

            if (
              this.filterSettleDateStart != null &&
              this.filterSettleDateEnd != null
            ) {
              filterSettleDateStart = this.YMDdateFormat(
                this.filterSettleDateStart
              );
              filterSettleDateEnd = this.YMDdateFormat(
                this.filterSettleDateEnd
              );
            }

            if (filterSettleDateStart != null && filterSettleDateEnd != null) {
              this.loading = true;

              store
                .dispatch("fxSFS/fetchFilterSettlementDate", {
                  auth: this.user,
                  startSettlementDate: filterSettleDateStart,
                  endSettlementDate: filterSettleDateEnd,
                })
                .then((res) => {
                  this.allFxSFSData = [];
                  if (res.data.filterDatas.length > 0) {
                    this.visibleFxSpotInput = true;
                    this.totalRows = res.data.filterDatas.length;
                    this.allFxSFSData = res.data.filterDatas;
                  } else {
                    this.visibleFxSpotInput = false;
                  }
                  this.loading = false;
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            this.notAuthToastMessage();
          }
        });
      }
    },
    filterColumnDate(dateType, date) {
      //this.allFxSFSData
      if (dateType == "OrderDate" && date) {
      }
    },
    verifySelectedTrade(item) {
      this.$checkAccessRight("FxExecuted", "Verify").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            if (item.Cancelled == 0) {
              this.checkTradeOperations(item).then((res) => {
                if (res == true) {
                  store
                    .dispatch("fxSFS/getVerifySelectedFxTrade", {
                      auth: this.user,
                      tradeId: item.ID,
                      tcUti: item.TcUti,
                    })
                    .then((res) => {
                      if (res.data.info == "Pass") {
                        if (this.getChoice()) {
                          this.$refs.refVerifyTrade.verifySelectedTradeModal = true;
                          this.$refs.refVerifyTrade.fetchedTradeData =
                            res.data.trade;

                          // set changes inputs
                          if (res.data.changedInputs) {
                            let setChangedInputs = _.map(
                              res.data.changedInputs,
                              (value, key) => ({ key, value })
                            );

                            if (setChangedInputs.length > 0) {
                              this.$refs.refVerifyTrade.changedInputs = setChangedInputs;
                            }
                          }

                          //set timeline datas
                          let actionsArray = [];
                          if (
                            res.data.trade.SavedBy != null &&
                            res.data.trade.SavedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Saved",
                              user: res.data.trade.SavedBy ?? "",
                              date:
                                res.data.trade.SaveDate.split(" ")[0] +
                                " " +
                                res.data.trade.SaveTime.split(" ")[0],
                              reason: "-",
                            });
                          }

                          if (
                            res.data.trade.AmendedBy != null &&
                            res.data.trade.AmendedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Amended",
                              user: res.data.trade.AmendedBy ?? "",
                              date:
                                res.data.trade.AmendmentDate.split(" ")[0] +
                                " " +
                                res.data.trade.AmendmentTime.split(" ")[0],
                              reason: res.data.trade.AmendmentReason,
                            });
                          }
                          if (res.data.actions.length > 0) {
                            if (
                              res.data.actions[0].VerificationUser != null &&
                              res.data.actions[0].VerificationUser != ""
                            ) {
                              actionsArray.push({
                                type: "Verified",
                                user:
                                  res.data.actions[0].VerificationUser ?? "",
                                date: res.data.actions[0].VerificationDateTime,
                                reason: "-",
                              });
                            }
                          }

                          if (res.data.actions.length > 0) {
                            if (
                              res.data.actions[0].JournalUser != null &&
                              res.data.actions[0].JournalUser != ""
                            ) {
                              actionsArray.push({
                                type: "Journal Created",
                                user: res.data.actions[0].JournalUser ?? "",
                                date: res.data.actions[0].JournalDateTime,
                                reason: "-",
                              });
                            }
                          }

                          if (
                            res.data.trade.CancelledBy != null &&
                            res.data.trade.CancelledBy != ""
                          ) {
                            actionsArray.push({
                              type: "Canceled",
                              user: res.data.trade.CancelledBy ?? "",
                              date:
                                res.data.trade.CancelDate.split(" ")[0] +
                                " " +
                                res.data.trade.CancelTime.split(" ")[0],
                              reason: res.data.trade.CancelReason,
                            });
                          }

                          if (res.data.actions.length > 0) {
                            if (
                              res.data.actions[0].ReversalInitiatedBy != null &&
                              res.data.actions[0].ReversalInitiatedBy != ""
                            ) {
                              actionsArray.push({
                                type: "Reversal Initiated",
                                user:
                                  res.data.actions[0].ReversalInitiatedBy ?? "",
                                date:
                                  res.data.actions[0].ReversalInitiatedDateTime,
                                reason:
                                  res.data.actions[0].ReversalInitiatedReason,
                              });
                            }
                          }

                          if (res.data.actions.length > 0) {
                            if (
                              res.data.actions[0].ReversedBy != null &&
                              res.data.actions[0].ReversedBy != ""
                            ) {
                              actionsArray.push({
                                type: "Reversed",
                                user: res.data.actions[0].ReversedBy ?? "",
                                date: res.data.actions[0].ReversedDateTime,
                                reason: "-",
                              });
                            }
                          }

                          this.$refs.refVerifyTrade.actionsData = actionsArray;
                        } else {
                          let verifyList = [];
                          verifyList.push(item);
                          this.tradeMultipleChangeStatusOpr(
                            1,
                            verifyList,
                            "Verify"
                          );
                        }
                      } else {
                        this.notFoundMessage();
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              });
            } else {
              this.canceledToastMessage();
            }
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    amendUpdate(item) {
      this.$checkAccessRight("FxExecuted", "Amend").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            if (item.Cancelled == 0) {
              this.checkJournalCreated("Amend", item.TcUti).then((res) => {
                if (res.data.info == true) {
                  store
                    .dispatch("fxSFS/getAmendSelectedFXTrade", {
                      auth: this.user,
                      tradeId: item.ID,
                      tcUti: item.TcUti,
                    })
                    .then((res) => {
                      if (res.data.info == "Pass") {
                        this.$refs.refAmendTrade.fetchedTradeData =
                          res.data.trade;
                        this.$refs.refAmendTrade.amendTradeModal = true;

                        // set changes inputs
                        if (res.data.changedInputs) {
                          let setChangedInputs = _.map(
                            res.data.changedInputs,
                            (value, key) => ({ key, value })
                          );

                          if (setChangedInputs.length > 0) {
                            this.$refs.refAmendTrade.changedInputs = setChangedInputs;
                          }
                        }

                        //set timeline datas
                        let actionsArray = [];
                        if (
                          res.data.trade.SavedBy != null &&
                          res.data.trade.SavedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Saved",
                            user: res.data.trade.SavedBy ?? "",
                            date:
                              res.data.trade.SaveDate.split(" ")[0] +
                              " " +
                              res.data.trade.SaveTime.split(" ")[0],
                            reason: "-",
                          });
                        }

                        if (
                          res.data.trade.AmendedBy != null &&
                          res.data.trade.AmendedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Amended",
                            user: res.data.trade.AmendedBy ?? "",
                            date:
                              res.data.trade.AmendmentDate.split(" ")[0] +
                              " " +
                              res.data.trade.AmendmentTime.split(" ")[0],
                            reason: res.data.trade.AmendmentReason,
                          });
                        }
                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].VerificationUser != null &&
                            res.data.actions[0].VerificationUser != ""
                          ) {
                            this.$refs.refAmendTrade.checkVerify = true;
                            actionsArray.push({
                              type: "Verified",
                              user: res.data.actions[0].VerificationUser ?? "",
                              date: res.data.actions[0].VerificationDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].JournalUser != null &&
                            res.data.actions[0].JournalUser != ""
                          ) {
                            actionsArray.push({
                              type: "Journal Created",
                              user: res.data.actions[0].JournalUser ?? "",
                              date: res.data.actions[0].JournalDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (
                          res.data.trade.CancelledBy != null &&
                          res.data.trade.CancelledBy != ""
                        ) {
                          actionsArray.push({
                            type: "Canceled",
                            user: res.data.trade.CancelledBy ?? "",
                            date:
                              res.data.trade.CancelDate.split(" ")[0] +
                              " " +
                              res.data.trade.CancelTime.split(" ")[0],
                            reason: res.data.trade.CancelReason,
                          });
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversalInitiatedBy != null &&
                            res.data.actions[0].ReversalInitiatedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversal Initiated",
                              user:
                                res.data.actions[0].ReversalInitiatedBy ?? "",
                              date:
                                res.data.actions[0].ReversalInitiatedDateTime,
                              reason:
                                res.data.actions[0].ReversalInitiatedReason,
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversedBy != null &&
                            res.data.actions[0].ReversedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversed",
                              user: res.data.actions[0].ReversedBy ?? "",
                              date: res.data.actions[0].ReversedDateTime,
                              reason: "-",
                            });
                          }
                        }

                        this.$refs.refAmendTrade.actionsData = actionsArray;
                      } else {
                        this.notFoundMessage();
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  this.checkJournalCreatedMessage(res.data.message);
                }
              });
            } else {
              this.canceledToastMessage();
            }
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    createJournalEntries(item) {
      this.$checkAccessRight("FxExecuted", "CreateJournal").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("fxSFS/checkJournalTradeVerifyOperation", {
              auth: this.user,
              trade: item,
            })
            .then((res) => {
              if (res.data.info == "verified") {
                this.checkJournalCreated("Journal", item.TcUti).then((res) => {
                  if (res.data.info == true) {
                    store
                      .dispatch("fxSFS/getCreateJournalFxTrade", {
                        auth: this.user,
                        tradeId: item.ID,
                        tcUti: item.TcUti,
                      })
                      .then((res) => {
                        if (res.data.info == "Pass") {
                          if (res.data.trade.Cancelled == "0") {
                            if (this.getChoice()) {
                              this.$refs.refCreateJournalTrade.fetchedTradeData =
                                res.data.trade;

                              // set changes inputs
                              if (res.data.changedInputs) {
                                let setChangedInputs = _.map(
                                  res.data.changedInputs,
                                  (value, key) => ({ key, value })
                                );

                                if (setChangedInputs.length > 0) {
                                  this.$refs.refCreateJournalTrade.changedInputs = setChangedInputs;
                                }
                              }

                              //set timeline datas
                              let actionsArray = [];
                              if (
                                res.data.trade.SavedBy != null &&
                                res.data.trade.SavedBy != ""
                              ) {
                                actionsArray.push({
                                  type: "Saved",
                                  user: res.data.trade.SavedBy ?? "",
                                  date:
                                    res.data.trade.SaveDate.split(" ")[0] +
                                    " " +
                                    res.data.trade.SaveTime.split(" ")[0],
                                  reason: "-",
                                });
                              }

                              if (
                                res.data.trade.AmendedBy != null &&
                                res.data.trade.AmendedBy != ""
                              ) {
                                actionsArray.push({
                                  type: "Amended",
                                  user: res.data.trade.AmendedBy ?? "",
                                  date:
                                    res.data.trade.AmendmentDate.split(" ")[0] +
                                    " " +
                                    res.data.trade.AmendmentTime.split(" ")[0],
                                  reason: res.data.trade.AmendmentReason,
                                });
                              }
                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].VerificationUser !=
                                    null &&
                                  res.data.actions[0].VerificationUser != ""
                                ) {
                                  this.$refs.refCreateJournalTrade.checkVerify = true;
                                  actionsArray.push({
                                    type: "Verified",
                                    user:
                                      res.data.actions[0].VerificationUser ??
                                      "",
                                    date:
                                      res.data.actions[0].VerificationDateTime,
                                    reason: "-",
                                  });
                                }
                              }

                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].JournalUser != null &&
                                  res.data.actions[0].JournalUser != ""
                                ) {
                                  actionsArray.push({
                                    type: "Journal Created",
                                    user: res.data.actions[0].JournalUser ?? "",
                                    date: res.data.actions[0].JournalDateTime,
                                    reason: "-",
                                  });
                                }
                              }

                              if (
                                res.data.trade.CancelledBy != null &&
                                res.data.trade.CancelledBy != ""
                              ) {
                                actionsArray.push({
                                  type: "Canceled",
                                  user: res.data.trade.CancelledBy ?? "",
                                  date:
                                    res.data.trade.CancelDate.split(" ")[0] +
                                    " " +
                                    res.data.trade.CancelTime.split(" ")[0],
                                  reason: res.data.trade.CancelReason,
                                });
                              }

                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].ReversalInitiatedBy !=
                                    null &&
                                  res.data.actions[0].ReversalInitiatedBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Reversal Initiated",
                                    user:
                                      res.data.actions[0].ReversalInitiatedBy ??
                                      "",
                                    date:
                                      res.data.actions[0]
                                        .ReversalInitiatedDateTime,
                                    reason:
                                      res.data.actions[0]
                                        .ReversalInitiatedReason,
                                  });
                                }
                              }

                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].ReversedBy != null &&
                                  res.data.actions[0].ReversedBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Reversed",
                                    user: res.data.actions[0].ReversedBy ?? "",
                                    date: res.data.actions[0].ReversedDateTime,
                                    reason: "-",
                                  });
                                }
                              }

                              this.$refs.refCreateJournalTrade.actionsData = actionsArray;
                              this.$refs.refCreateJournalTrade.createJournalTradeModal = true;
                            } else {
                              let verifyList = [];
                              verifyList.push(item);
                              this.tradeMultipleChangeStatusOpr(
                                1,
                                verifyList,
                                "Create Journal"
                              );
                            }
                          } else {
                            this.canceledToastMessage();
                          }
                        } else {
                          this.notFoundMessage();
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    this.checkJournalCreatedMessage(res.data.message);
                  }
                });
              } else {
                if (res.data.info == "not_verified") {
                  this.checkTradeUnVerifyOperationLangMessage();
                } else {
                  this.checkTradeVerifyOperationMessage(res.data.message);
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    viewSelectedTrade(item) {
      this.$checkAccessRight("FxExecuted", "View").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            store
              .dispatch("fxSFS/getViewSelectedFxTrade", {
                auth: this.user,
                tradeId: item.ID,
                tcUti: item.TcUti,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.$refs.ViewSelectedTrade.viewSelectedTradeModal = true;
                  this.$refs.ViewSelectedTrade.fetchedTradeData =
                    res.data.trade;

                    // set changes red color inputs
                  if (res.data.changedInputs) {
                      let setChangedInputs = _.map(
                        res.data.changedInputs,
                        (value, key) => ({ key, value })
                      );
                      if (setChangedInputs.length > 0) {
                        this.$refs.ViewSelectedTrade.changedInputs = setChangedInputs;
                      }
                    }

                  //set timeline datas
                  let actionsArray = [];
                  if (
                    res.data.trade.SavedBy != null &&
                    res.data.trade.SavedBy != ""
                  ) {
                    actionsArray.push({
                      type: "Saved",
                      user: res.data.trade.SavedBy ?? "",
                      date:
                        res.data.trade.SaveDate.split(" ")[0] +
                        " " +
                        res.data.trade.SaveTime.split(" ")[0],
                      reason: "-",
                    });
                  }

                  if (
                    res.data.trade.AmendedBy != null &&
                    res.data.trade.AmendedBy != ""
                  ) {
                    actionsArray.push({
                      type: "Amended",
                      user: res.data.trade.AmendedBy ?? "",
                      date:
                        res.data.trade.AmendmentDate.split(" ")[0] +
                        " " +
                        res.data.trade.AmendmentTime.split(" ")[0],
                      reason: res.data.trade.AmendmentReason,
                    });
                  }
                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].VerificationUser != null &&
                      res.data.actions[0].VerificationUser != ""
                    ) {
                      this.$refs.ViewSelectedTrade.checkVerify = true;
                      actionsArray.push({
                        type: "Verified",
                        user: res.data.actions[0].VerificationUser ?? "",
                        date: res.data.actions[0].VerificationDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].JournalUser != null &&
                      res.data.actions[0].JournalUser != ""
                    ) {
                      actionsArray.push({
                        type: "Journal Created",
                        user: res.data.actions[0].JournalUser ?? "",
                        date: res.data.actions[0].JournalDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (
                    res.data.trade.CancelledBy != null &&
                    res.data.trade.CancelledBy != ""
                  ) {
                    actionsArray.push({
                      type: "Canceled",
                      user: res.data.trade.CancelledBy ?? "",
                      date:
                        res.data.trade.CancelDate.split(" ")[0] +
                        " " +
                        res.data.trade.CancelTime.split(" ")[0],
                      reason: res.data.trade.CancelReason,
                    });
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversalInitiatedBy != null &&
                      res.data.actions[0].ReversalInitiatedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Reversal Initiated",
                        user: res.data.actions[0].ReversalInitiatedBy ?? "",
                        date: res.data.actions[0].ReversalInitiatedDateTime,
                        reason: res.data.actions[0].ReversalInitiatedReason,
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversedBy != null &&
                      res.data.actions[0].ReversedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Reversed",
                        user: res.data.actions[0].ReversedBy ?? "",
                        date: res.data.actions[0].ReversedDateTime,
                        reason: "-",
                      });
                    }
                  }

                  this.$refs.ViewSelectedTrade.actionsData = actionsArray;
                } else {
                  this.notFoundMessage();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    copySelectedTrade(item) {
      this.$checkAccessRight("FxExecuted", "Save").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("fxSFS/getCopySelectedFxTrade", {
              auth: this.user,
              tradeId: item.ID,
              tcUti: item.TcUti,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                if (this.getChoice()) {
                  this.$refs.refCopyTrade.fetchedTradeData = res.data.trade;
                  this.$refs.refCopyTrade.copyTradeModal = true;

                  //set timeline datas
                  let actionsArray = [];
                  if (
                    res.data.trade.SavedBy != null &&
                    res.data.trade.SavedBy != ""
                  ) {
                    actionsArray.push({
                      type: "Saved",
                      user: res.data.trade.SavedBy ?? "",
                      date:
                        res.data.trade.SaveDate.split(" ")[0] +
                        " " +
                        res.data.trade.SaveTime.split(" ")[0],
                      reason: "-",
                    });
                  }

                  if (
                    res.data.trade.AmendedBy != null &&
                    res.data.trade.AmendedBy != ""
                  ) {
                    actionsArray.push({
                      type: "Amended",
                      user: res.data.trade.AmendedBy ?? "",
                      date:
                        res.data.trade.AmendmentDate.split(" ")[0] +
                        " " +
                        res.data.trade.AmendmentTime.split(" ")[0],
                      reason: res.data.trade.AmendmentReason,
                    });
                  }
                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].VerificationUser != null &&
                      res.data.actions[0].VerificationUser != ""
                    ) {
                      actionsArray.push({
                        type: "Verified",
                        user: res.data.actions[0].VerificationUser ?? "",
                        date: res.data.actions[0].VerificationDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].JournalUser != null &&
                      res.data.actions[0].JournalUser != ""
                    ) {
                      actionsArray.push({
                        type: "Journal Created",
                        user: res.data.actions[0].JournalUser ?? "",
                        date: res.data.actions[0].JournalDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (
                    res.data.trade.CancelledBy != null &&
                    res.data.trade.CancelledBy != ""
                  ) {
                    actionsArray.push({
                      type: "Canceled",
                      user: res.data.trade.CancelledBy ?? "",
                      date:
                        res.data.trade.CancelDate.split(" ")[0] +
                        " " +
                        res.data.trade.CancelTime.split(" ")[0],
                      reason: res.data.trade.CancelReason,
                    });
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversalInitiatedBy != null &&
                      res.data.actions[0].ReversalInitiatedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Reversal Initiated",
                        user: res.data.actions[0].ReversalInitiatedBy ?? "",
                        date: res.data.actions[0].ReversalInitiatedDateTime,
                        reason: res.data.actions[0].ReversalInitiatedReason,
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversedBy != null &&
                      res.data.actions[0].ReversedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Reversed",
                        user: res.data.actions[0].ReversedBy ?? "",
                        date: res.data.actions[0].ReversedDateTime,
                        reason: "-",
                      });
                    }
                  }

                  this.$refs.refCopyTrade.actionsData = actionsArray;
                } else {
                  let verifyList = [];
                  verifyList.push(item);
                  this.tradeMultipleSelectCopyOpr(1, verifyList);
                }
              } else {
                this.notFoundMessage();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getAmendDetails(item) {
      this.$checkAccessRight("FxExecuted", "View").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == "0") {
            if (item.ID != 0) {
              this.$router.push({
                path: `/fxExecuted/amend-details/${item.ID}`,
              });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        }
      });
    },
    viewJournalEntries(item) {
      this.$checkAccessRight("Journal", "View").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == "0") {
            this.checkJournalCreated("JournalDisplay", item.TcUti).then(
              (res) => {
                if (res.data.info == true) {
                  //set and check trade data
                  let tradeData = this.setTradeData(item);
                  /////

                  store
                    .dispatch("fxSFS/getViewFxJournalTrade", {
                      auth: this.user,
                      tradeId: item.ID,
                      tcUti: item.TcUti,
                      trade: tradeData,
                    })
                    .then((res) => {
                      if (res.data.info == "Pass") {
                        this.$refs.refViewJournalEntries.journalEntries =
                          res.data.entries;

                        this.$refs.refViewJournalEntries.createJournalOperation = false;

                        //set timeline datas
                        let actionsArray = [];
                        if (
                          res.data.trade.SavedBy != null &&
                          res.data.trade.SavedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Saved",
                            user: res.data.trade.SavedBy ?? "",
                            date:
                              res.data.trade.SaveDate.split(" ")[0] +
                              " " +
                              res.data.trade.SaveTime.split(" ")[0],
                            reason: "-",
                          });
                        }

                        if (
                          res.data.trade.AmendedBy != null &&
                          res.data.trade.AmendedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Amended",
                            user: res.data.trade.AmendedBy ?? "",
                            date:
                              res.data.trade.AmendmentDate.split(" ")[0] +
                              " " +
                              res.data.trade.AmendmentTime.split(" ")[0],
                            reason: res.data.trade.AmendmentReason,
                          });
                        }
                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].VerificationUser != null &&
                            res.data.actions[0].VerificationUser != ""
                          ) {
                            this.$refs.refViewJournalEntries.checkVerify = true;
                            actionsArray.push({
                              type: "Verified",
                              user: res.data.actions[0].VerificationUser ?? "",
                              date: res.data.actions[0].VerificationDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].JournalUser != null &&
                            res.data.actions[0].JournalUser != ""
                          ) {
                            actionsArray.push({
                              type: "Journal Created",
                              user: res.data.actions[0].JournalUser ?? "",
                              date: res.data.actions[0].JournalDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (
                          res.data.trade.CancelledBy != null &&
                          res.data.trade.CancelledBy != ""
                        ) {
                          actionsArray.push({
                            type: "Canceled",
                            user: res.data.trade.CancelledBy ?? "",
                            date:
                              res.data.trade.CancelDate.split(" ")[0] +
                              " " +
                              res.data.trade.CancelTime.split(" ")[0],
                            reason: res.data.trade.CancelReason,
                          });
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversalInitiatedBy != null &&
                            res.data.actions[0].ReversalInitiatedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversal Initiated",
                              user:
                                res.data.actions[0].ReversalInitiatedBy ?? "",
                              date:
                                res.data.actions[0].ReversalInitiatedDateTime,
                              reason:
                                res.data.actions[0].ReversalInitiatedReason,
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversedBy != null &&
                            res.data.actions[0].ReversedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversed",
                              user: res.data.actions[0].ReversedBy ?? "",
                              date: res.data.actions[0].ReversedDateTime,
                              reason: "-",
                            });
                          }
                        }

                        this.$refs.refViewJournalEntries.actionsData = actionsArray;
                        this.$refs.refViewJournalEntries.viewJournalTradeModal = true;

                        if (res.data.amount_info == "not_zero") {
                          this.checkJournalOperationsMessage(
                            res.data.message,
                            10000
                          );
                        }
                      } else {
                        this.notFoundMessage();
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  this.checkJournalCreatedMessage(res.data.message);
                }
              }
            );
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    checkTradeOperations(trade) {
      return new Promise((resolve, reject) => {
        store
          .dispatch("fxSFS/checkTradeVerifyOperation", {
            auth: this.user,
            trade: trade,
          })
          .then((res) => {
            if (res.data.info == "not_verified") {
              store
                .dispatch("fxSFS/checkFxTradeUserOperation", {
                  auth: this.user,
                  trade: trade,
                })
                .then((res) => {
                  if (res.data.info == "user_doesnt_same") {
                    if (trade.EntryType == "Order") {
                      this.$swal({
                        title: this.$t("fx_spot_forward_swap.swal.order_trade"),
                        text: this.$t(
                          "fx_spot_forward_swap.swal.entry_saved_order_want_amend_trade"
                        ),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: this.$t(
                          "fx_spot_forward_swap.swal.confirm_button"
                        ),
                        cancelButtonText: this.$t(
                          "fx_spot_forward_swap.swal.cancel_button"
                        ),
                        customClass: {
                          confirmButton: "btn btn-success mr-1",
                          cancelButton: "btn btn-danger",
                          denyButton: "btn btn-primary",
                        },
                        showClass: {
                          popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                          popup: "animate__animated animate__fadeOutUp",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          this.amendUpdate(trade);
                        }
                      });
                    } else {
                      resolve(true);
                    }
                  } else if (
                    res.data.info == "same_user_amend" ||
                    res.data.info == "same_user_save"
                  ) {
                    this.checkTradeUserOperationMessage(res.data.message, 8000);
                  } else {
                    this.checkTradeUserOperationMessage(res.data.message, 8000);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              if (res.data.info == "verified") {
                this.checkTradeVerifyOperationLangMessage(
                  res.data.user,
                  res.data.date
                );
              } else {
                this.checkTradeVerifyOperationMessage(res.data.message);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },

    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeVerifyOperationLangMessage(user, date) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text:
            this.$t("backend_language_message.verify_trade1") +
            " " +
            user +
            " " +
            this.$t("backend_language_message.verify_trade_on") +
            " " +
            date,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeUnVerifyOperationLangMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("backend_language_message.un_verify_message"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeUserOperationMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    checkJournalCreated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("fxSFS/checkTCUtiJournalCreated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(
          this.$t("fx_spot_forward_swap.messages.tc_uti_not_found")
        );
      }
    },
    checkEntryReversed(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("fxSFS/checkTCUtiReserved", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(
          this.$t("fx_spot_forward_swap.messages.tc_uti_not_found")
        );
      }
    },
    checkReversalInitiated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("fxSFS/checkReversalInitiated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(
          this.$t("fx_spot_forward_swap.messages.tc_uti_not_found")
        );
      }
    },

    setTradeData(val) {
      let setTrade = {
        costCenter: val.CostCenter ?? null,

        orderDurationDate:
          val.OrderDurationDate != null
            ? this.dateFormat(val.OrderDurationDate)
            : null,
        orderDurationTime:
          val.OrderDurationTime != null ? val.OrderDurationTime : null,
        entryType: val.EntryType ?? null,
        buySell: val.BuySell ?? null,
        orderType: val.OrderType ?? null,
        timeInForce: val.TimeinForce ?? null,
        orderDate:
          val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
        orderTime: val.OrderTime != null ? val.OrderTime : null,
        cptyOrderDate:
          val.CounterpartyOrderDate != null
            ? this.dateFormat(val.CounterpartyOrderDate)
            : null,
        cptyOrderTime:
          val.CounterpartyOrderTime != null ? val.CounterpartyOrderTime : null,
        enableConnectedOCOOrders: val.EnableOCO == "true" ? "true" : "false",
        stopPrice: val.StopPrice != null ? parseFloat(val.StopPrice) : null,
        trailingAmount:
          val.TrailingAmount != null ? parseFloat(val.TrailingAmount) : null,
        takeProfitLimit:
          val.TakeProfitLimit != null ? parseFloat(val.TakeProfitLimit) : null,
        stopLossStop: val.StopLoss != null ? parseFloat(val.StopLoss) : null,
        stopLossLimit:
          val.StopLossLimit != null ? parseFloat(val.StopLossLimit) : null,
        client: val.Client ?? null,
        clientAccount: val.ClientAcc != null ? val.ClientAcc : null,
        counterparty: val.Counterparty ?? null,
        cptyAccount: val.CptyAcc ?? null,
        productType: val.ProductType ?? null,
        settlementType: val.SettlementType ?? null,
        cptyRate: val.CptyRate != null ? parseFloat(val.CptyRate) : null,

        clientRate: val.ClientRate != null ? parseFloat(val.ClientRate) : null,

        cptyAmount1:
          val.CptyAmount1 != null ? parseFloat(val.CptyAmount1) : null,

        cptyAmount2:
          val.CptyAmount2 != null ? parseFloat(val.CptyAmount2) : null,

        cptyCcy1: val.Ccy1 != null ? val.Ccy1 : null,
        cptyCcy2: val.Ccy2 != null ? val.Ccy2 : null,
        cptyBuySell1: val.BuySell == "Buy" ? "Buys" : "Sells",
        cptyBuySell2: val.BuySell == "Buy" ? "Sells" : "Buys",
        spread: val.Spread != null ? parseFloat(val.Spread) : null,
        clientAmount1:
          val.ClientAmount1 != null ? parseFloat(val.ClientAmount1) : null,
        clientAmount2:
          val.ClientAmount2 != null ? parseFloat(val.ClientAmount2) : null,
        clientCcy1: val.Ccy1 != null ? val.Ccy1 : null,
        clientCcy2: val.Ccy2 != null ? val.Ccy2 : null,
        clientBuySell1: val.BuySell == "Buy" ? "Buys" : "Sells",
        clientBuySell2: val.BuySell == "Buy" ? "Sells" : "Buys",
        spotRef: val.SpotRef != null ? parseFloat(val.SpotRef) : null,
        spreadAmount:
          val.SpreadAmount != null ? parseFloat(val.SpreadAmount) : null,
        spreadCcy: val.SpreadCcy != null ? val.SpreadCcy : null,
        comissionAmount:
          val.CommAmount != null ? parseFloat(val.CommAmount) : null,
        comissionCcy: val.CommCcy != null ? val.CommCcy : null,
        cutOffTime: val.CutOffTime != null ? val.CutOffTime : null,
        deliverableCcy: val.DeliverableCcy != null ? val.DeliverableCcy : null,
        tradeDate:
          val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
        settlementDate:
          val.SettlementDate != null ? this.dateFormat(val.SettlDate) : null,
        executionTime: val.ExecTime != null ? val.ExecTime : null,
        counterpartyTrader: val.CptyTrader != null ? val.CptyTrader : null,
        scfUser: val.TcUser != null ? val.TcUser : null,
        orderTakenVia: val.TakenVia != null ? val.TakenVia : null,
        orderGivenThrough: val.GivenThrg != null ? val.GivenThrg : null,
        actingCapaticy: val.ActingCap != null ? val.ActingCap : null,
        traderNote: val.TraderNote != null ? val.TraderNote : null,
        ocoLink: val.OCOLink != null ? val.OCOLink : null,
        swapTradeDate:
          val.SwapTradeDate != null ? this.dateFormat(val.SwapTradeDate) : null,
          swapMaturity: val.SwapMaturity != null ? val.SwapMaturity : null,
        swapSettleDate:
          val.SwapSettlementDate != null
            ? this.dateFormat(val.SwapSettlementDate)
            : null,
            swapForwardRate:
          val.SwapForwardRate != null ? parseFloat(val.SwapForwardRate) : null,
        swapCptyRate:
          val.SwapCptyRate != null ? parseFloat(val.SwapCptyRate) : null,
        swapCptyAmount1:
          val.SwapCptyAmount1 != null ? parseFloat(val.SwapCptyAmount1) : null,
        swapCptyCcy1: val.SwapCptyCcy1 != null ? val.SwapCptyCcy1 : null,
        swapCptyBuySell1:
          val.SwapCptyBuySell1 != null ? val.SwapCptyBuySell1 : null,
        swapCptyAmount2:
          val.SwapCptyAmount2 != null ? parseFloat(val.SwapCptyAmount2) : null,
        swapCptyCcy2: val.SwapCptyCcy2 != null ? val.SwapCptyCcy2 : null,
        swapCptyBuySell2:
          val.SwapCptyBuySell2 != null ? val.SwapCptyBuySell2 : null,
        swapSpread: val.SwapSpread != null ? val.SwapSpread : null,
        swapClientRate:
          val.SwapClientRate != null ? parseFloat(val.SwapClientRate) : null,
        swapClientAmount1:
          val.SwapClientAmount1 != null
            ? parseFloat(val.SwapClientAmount1)
            : null,
        swapClientCcy1: val.SwapClientCcy1 != null ? val.SwapClientCcy1 : null,
        swapClientBuySell1:
          val.SwapClientBuySell1 != null ? val.SwapClientBuySell1 : null,
        swapClientAmount2:
          val.SwapClientAmount2 != null
            ? parseFloat(val.SwapClientAmount2)
            : null,
        swapClientCcy2: val.SwapClientCcy2 != null ? val.SwapClientCcy2 : null,
        swapClientBuySell2:
          val.SwapClientBuySell2 != null ? val.SwapClientBuySell2 : null,
        swapSpreadAmount:
          val.SwapSpreadAmount != null
            ? parseFloat(val.SwapSpreadAmount)
            : null,
        swapSpreadCcy: val.SwapSpreadCcy != null ? val.SwapSpreadCcy : null,
        tcUti: val.TcUti != null ? val.TcUti : null,
        uniqueLinkID: val.UniqueLinkId != null ? val.UniqueLinkId : null,
        tradeRef: val.ID,
        saveDate: val.SaveDate != null ? val.SaveDate : null,
        savetime: val.SaveTime != null ? val.SaveTime : null,
        savedBy: val.SavedBy != null ? val.SavedBy : null,
        amendedBy: val.AmendedBy != null ? val.AmendedBy : null,
        amendmentDate: val.AmendmentDate != null ? val.AmendmentDate : null,
        amendmentReason:
          val.AmendmentReason != null ? val.AmendmentReason : null,
          amentmentTime: val.AmendmentTime != null ? val.AmendmentTime : null,
        cancelDate: val.CancelDate != null ? val.CancelDate : null,
        cancelReason: val.CancelReason != null ? val.CancelReason : null,
        cancelTime: val.CancelTime != null ? val.CancelTime : null,
        cancelled: val.Cancelled != "0" ? val.CancelTime : "1",
        cancelledBy: val.CancelledBy != null ? val.CancelledBy : null,
      };

      return setTrade;
    },
    setCopyTradeData(val) {

      let setTrade = {
        costCenter: val.CostCenter ?? null,

        orderDurationDate:
          val.OrderDurationDate != null
            ? this.dateFormat(val.OrderDurationDate)
            : null,
        orderDurationTime:
          val.OrderDurationTime != null ? val.OrderDurationTime : null,
        entryType: val.EntryType ?? null,
        buySell: val.BuySell ?? null,
        orderType: val.OrderType ?? null,
        timeInForce: val.TimeinForce ?? null,
        orderDate:
          val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
        orderTime: val.OrderTime != null ? val.OrderTime : null,
        cptyOrderDate:
          val.CounterpartyOrderDate != null
            ? this.dateFormat(val.CounterpartyOrderDate)
            : null,
        counterpartyOrderTime:
          val.CounterpartyOrderTime != null ? val.CounterpartyOrderTime : null,
        enableOCO: val.EnableOCO == "true" ? "true" : "false",
        stopPrice: val.StopPrice != null ? parseFloat(val.StopPrice) : null,
        trailingAmount:
          val.TrailingAmount != null ? parseFloat(val.TrailingAmount) : null,
        takeProfitLimit:
          val.TakeProfitLimit != null ? parseFloat(val.TakeProfitLimit) : null,
        stopLoss: val.StopLoss != null ? parseFloat(val.StopLoss) : null,
        stopLossLimit:
          val.StopLossLimit != null ? parseFloat(val.StopLossLimit) : null,
        client: val.Client ?? null,
        clientAcc: val.ClientAcc != null ? val.ClientAcc : null,
        counterparty: val.Counterparty ?? null,
        cptyAccount: val.CptyAcc ?? null,
        productType: val.ProductType ?? null,
        settlementType: val.SettlementType ?? null,
        cptyRate: val.CptyRate != null ? parseFloat(val.CptyRate) : null,

        clientRate: val.ClientRate != null ? parseFloat(val.ClientRate) : null,

        cptyAmount1:
          val.CptyAmount1 != null ? parseFloat(val.CptyAmount1) : null,

        cptyAmount2:
          val.CptyAmount2 != null ? parseFloat(val.CptyAmount2) : null,

        cptyCcy1: val.Ccy1 != null ? val.Ccy1 : null,
        cptyCcy2: val.Ccy2 != null ? val.Ccy2 : null,
        cptyBuySell1: val.CptyBuySell1 == "Buys" ? "Buys" : "Sells",
        cptyBuySell2: val.CptyBuySell1 == "Buys" ? "Sells" : "Buys",
        spread: val.Spread != null ? parseFloat(val.Spread) : null,
        clientAmount1:
          val.ClientAmount1 != null ? parseFloat(val.ClientAmount1) : null,
        clientAmount2:
          val.ClientAmount2 != null ? parseFloat(val.ClientAmount2) : null,
        clientCcy1: val.Ccy1 != null ? val.Ccy1 : null,
        clientCcy2: val.Ccy2 != null ? val.Ccy2 : null,
        clientBuySell1: val.CptyBuySell1 == "Buys" ? "Buys" : "Sells",
        clientBuySell2: val.CptyBuySell1 == "Buys" ? "Sells" : "Buys",
        spotRef: val.SpotRef != null ? parseFloat(val.SpotRef) : null,
        spreadAmount:
          val.SpreadAmount != null ? parseFloat(val.SpreadAmount) : null,
        spreadCcy: val.SpreadCcy != null ? val.SpreadCcy : null,
        comissionAmount:
          val.CommAmount != null ? parseFloat(val.CommAmount) : null,
        comissionCcy: val.CommCcy != null ? val.CommCcy : null,
        cutOffTime: val.CutOffTime != null ? val.CutOffTime : null,
        deliverableCcy: val.DeliverableCcy != null ? val.DeliverableCcy : null,
        tradeDate:
          val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
        settlementDate:
          val.SettlementDate != null ? this.dateFormat(val.SettlDate) : null,
        executionTime: val.ExecTime != null ? val.ExecTime : null,
        cptyTrader: val.CptyTrader != null ? val.CptyTrader : null,
        tcUser: val.TcUser != null ? val.TcUser : null,
        orderTakenVia: val.TakenVia != null ? val.TakenVia : null,
        orderGivenThrough: val.GivenThrg != null ? val.GivenThrg : null,
        actingCapaticy: val.ActingCap != null ? val.ActingCap : null,
        traderNote: val.TraderNote != null ? val.TraderNote : null,
        ocoLink: val.OCOLink != null ? val.OCOLink : null,
        swapTradeDate:
          val.SwapTradeDate != null ? this.dateFormat(val.SwapTradeDate) : null,
          swapMaturity: val.SwapMaturity != null ? val.SwapMaturity : null,
        swapSettleDate:
          val.SwapSettlementDate != null
            ? this.dateFormat(val.SwapSettlementDate)
            : null,
            swapForwardRate:
          val.SwapForwardRate != null ? parseFloat(val.SwapForwardRate) : null,
        swapCptyRate:
          val.SwapCptyRate != null ? parseFloat(val.SwapCptyRate) : null,
        swapCptyAmount1:
          val.SwapCptyAmount1 != null ? parseFloat(val.SwapCptyAmount1) : null,
        swapCptyCcy1: val.SwapCptyCcy1 != null ? val.SwapCptyCcy1 : null,
        swapCptyBuySell1:
          val.SwapCptyBuySell1 != null ? val.SwapCptyBuySell1 : null,
        swapCptyAmount2:
          val.SwapCptyAmount2 != null ? parseFloat(val.SwapCptyAmount2) : null,
        swapCptyCcy2: val.SwapCptyCcy2 != null ? val.SwapCptyCcy2 : null,
        swapCptyBuySell2:
          val.SwapCptyBuySell2 != null ? val.SwapCptyBuySell2 : null,
        swapSpread: val.SwapSpread != null ? val.SwapSpread : null,
        swapClientRate:
          val.SwapClientRate != null ? parseFloat(val.SwapClientRate) : null,
        swapClientAmount1:
          val.SwapClientAmount1 != null
            ? parseFloat(val.SwapClientAmount1)
            : null,
        swapClientCcy1: val.SwapClientCcy1 != null ? val.SwapClientCcy1 : null,
        swapClientBuySell1:
          val.SwapClientBuySell1 != null ? val.SwapClientBuySell1 : null,
        swapClientAmount2:
          val.SwapClientAmount2 != null
            ? parseFloat(val.SwapClientAmount2)
            : null,
        swapClientCcy2: val.SwapClientCcy2 != null ? val.SwapClientCcy2 : null,
        swapClientBuySell2:
          val.SwapClientBuySell2 != null ? val.SwapClientBuySell2 : null,
        swapSpreadAmount:
          val.SwapSpreadAmount != null
            ? parseFloat(val.SwapSpreadAmount)
            : null,
        swapSpreadCcy: val.SwapSpreadCcy != null ? val.SwapSpreadCcy : null,
        tcUti: val.TcUti != null ? val.TcUti : null,
        uniqueLinkId: val.UniqueLinkId != null ? val.UniqueLinkId : null,
        tradeRef: val.ID,
        amendedBy: val.AmendedBy != null ? val.AmendedBy : null,
        amendmentDate: val.AmendmentDate != null ? val.AmendmentDate : null,
        amendmentReason:
          val.AmendmentReason != null ? val.AmendmentReason : null,
          amentmentTime: val.AmendmentTime != null ? val.AmendmentTime : null,
          canceledDate: val.CancelDate != null ? val.CancelDate : null,
          canceledReason: val.CancelReason != null ? val.CancelReason : null,
        canceledTime: val.CancelTime != null ? val.CancelTime : null,
        canceled: val.Cancelled != "0" ? val.CancelTime : "1",
        canceledBy: val.CancelledBy != null ? val.CancelledBy : null,
        orderPrice: null,
      };

      return setTrade;
    },

    checkJournalCreatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkEntryReversedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkReversalInitiatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t(
            "fx_spot_forward_swap.toast_messages.database_connection_error"
          ),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("fx_spot_forward_swap.toast_messages.data_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    canceledToastMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t(
            "fx_spot_forward_swap.messages.trade_cancelled_message"
          ),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    cancelOpearionNotDoneMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t(
            "fx_spot_forward_swap.messages.cancelled_operation_not_done"
          ),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectAllProcesesSuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "multiCheckBoxTitles.multiselect_processes_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    multiSelectAllProcesesWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t(
            "multiCheckBoxTitles.multiselect_processes_warning"
          ),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectCopyWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t(
            "multiCheckBoxTitles.multiselect_copy_warning"
          ),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectAllTradesCopySuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "multiCheckBoxTitles.multiselect_copy_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    
    sendedMailSuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("transfers.mail.mail_send_success"),
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
    },
    tradeCancelledErrorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    warningToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundSearchData() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Found Search Data",
          text: this.$t("fx_spot_forward_swap.messages.not_found_search_data"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t(
            "fx_spot_forward_swap.general_messages.not_authorized_message"
          ),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    tradeCancelledMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "fx_spot_forward_swap.toast_messages.trade_cancelled_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successOrderColumnsMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("fx_spot_forward_swap.toast_messages.success_order_columns"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },

    tradeIDDoesntZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_spot_forward_swap.messages.trade_id_cannot_zero"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    validReasonTextMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_spot_forward_swap.reason.enter_valid_reason"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalOperationsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("YYYY-MM-DD");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val)).format("MM-DD-YYYY HH:mm:ss");
      }
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    clockFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("HH:mm:ss");
      } else {
        return "-";
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    clearTradeDate() {
      this.filterTradeDate = null;
      (this.tradeRangeDate = null),
        (this.filterTradeDateStart = null),
        (this.filterTradeDateEnd = null),
        this.runLastSearch();
    },
    clearSettlementDate() {
      this.filterSettlementDate = null;
      (this.settleRangeDate = null),
        (this.filterSettleDateStart = null),
        (this.filterSettleDateEnd = null),
        this.runLastSearch();
    },
    handleScroll(e) {
      // this.windowTop = window.top.scrollY
      // if(this.windowTop > 300) {
      //   console.log(this.$refs.refFXSFSTable.$el)
      // }
    },

    getChoice() {
      return this.userData.personal_settings.find((e) => e.key == "fx_tickets")
        .choice;
    },



    exportXlsx() {
      let columns = []
      this.tableColumns.forEach(element => {
        columns.push(element.key)
        
      });

      let datas = this.filteredTrades.map(item => {
        const orderedItem = {};
        columns.forEach(column => {
          if(column == 'EntryType2'){
              orderedItem[column] = 'Main Entry';
            }
          if (column in item) {
          
            
            if(column == 'Cancelled'){
              if(item[column] == 1){
                orderedItem[column] = 'Cancelled';
              }
              else{
                orderedItem[column] = 'Not Cancelled';

              }
            }
            else{
              orderedItem[column] = item[column];

            }
          }
        });
        return orderedItem;
      });

      let columnNames = []

      this.tableColumns.forEach(element => {
        columnNames.push(element.label)
        
      });

      store
        .dispatch("fxSFS/exportXlsx",{data:datas,headings:columnNames})
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "fx.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    exportCsv() {
      let columns = []
      this.tableColumns.forEach(element => {
        columns.push(element.key)
        
      });

      let datas = this.filteredTrades.map(item => {
        const orderedItem = {};
        columns.forEach(column => {
          if(column == 'EntryType2'){
              orderedItem[column] = 'Main Entry';
            }
          if (column in item) {
          
            
            if(column == 'Cancelled'){
              if(item[column] == 1){
                orderedItem[column] = 'Cancelled';
              }
              else{
                orderedItem[column] = 'Not Cancelled';

              }
            }
            else{
              orderedItem[column] = item[column];

            }
          }
        });
        return orderedItem;
      });

      let columnNames = []

      this.tableColumns.forEach(element => {
        columnNames.push(element.label)
        
      });
      store
        .dispatch("fxSFS/exportCsv",{data:datas,headings:columnNames})
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "fx.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("fxTradesShow", val);
      },
    },
    tradeRangeDate: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.filterTradeDateStart = this.tradeRangeDate.split(" to ")[0];
            this.filterTradeDateEnd = this.tradeRangeDate.split(" to ")[1];

            // this.getFilterTradeDate();
            if (
              this.filterTradeDateStart != "null" &&
              this.filterTradeDateEnd != "null"
            ) {
              //this.getFilterTradeDate();
            } else {
              this.filterTradeDateStart = null;
              this.filterTradeDateEnd = null;
            }
          }
        }
      },
    },
    settleRangeDate: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.filterSettleDateStart = this.settleRangeDate.split(" to ")[0];
            this.filterSettleDateEnd = this.settleRangeDate.split(" to ")[1];

            // this.listRefresh();
            if (
              this.filterSettleDateStart != "null" &&
              this.filterSettleDateEnd != "null"
            ) {
              //this.listRefresh();
            } else {
              this.filterSettleDateStart = null;
              this.filterSettleDateEnd = null;
            }
          }
        }
      },
    },
    // treeTablecolumns: {
    //   handler: function(val, before) {
    //     this.loading = true;
    //     if (val.length > 0) {
    //       let filteredColumns = [];
    //       filteredColumns.push({
    //         stickyColumn: true,
    //         key: "ID",
    //         label: "ID",
    //         // thClass: "text-left",
    //         tdClass: "text-center ID_background",
    //         // thStyle: 'width: 30%',
    //         // sortable: true,
    //         class: "text-start sticky-column",
    //         variant: "primary",
    //       });
    //       this.tableOrjinalColumns.forEach((o) => {
    //         if (val.find((v) => v.value == o.key)) {
    //           filteredColumns.push(o);
    //         }
    //       });
    //       this.tableColumns = filteredColumns;
    //     } else {
    //       this.tableColumns = this.tableOrjinalColumns;
    //     }
    //     this.loading = false;
    //   },
    // },
    treeStatus: {
      handler: function(val, before) {
        let allFilteredData = [];
        
        if (val.length > 0) {
          val.forEach((s) => {
            if (s.value == "Execution") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allFxSFSNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                EntryType: "Execution",
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Verified") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allFxSFSNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 0,
                ReversalInitiated: 0,
                Reversed: 0,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Journal Created") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allFxSFSNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 0,
                Reversed: 0,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Revers Inititated") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allFxSFSNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 1,
                Reversed: 0,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Reversed") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allFxSFSNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 1,
                Reversed: 1,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Cancelled") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allFxSFSNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Cancelled: 1,
              });

              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            }
          });

          let uniqueData = _.uniqBy(allFilteredData, function(e) {
            return e.ID;
          });

          this.allFxSFSData = uniqueData;
          this.totalRows = uniqueData.length;
        } else {
          this.allFxSFSData = this.allFxSFSNotFilterData;
          this.totalRows = this.allFxSFSNotFilterData.length;
        }
      },
    },
    selectedMultipleTrades: {
      handler: function(val, old) {
        if (val.length > 0) {
          this.multiSelectListStatusData = [];
          if (
            _.filter(val, {
              EntryType: "Execution",
              Verified: 0,
              JournalCreated: 0,
              ReversalInitiated: 0,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Verify",
              title: this.$t("multiCheckBoxTitles.verify"),
              icon: "CheckSquareIcon",
              color: "green",
            });
          }

          if (
            _.filter(val, {
              EntryType: "Execution",
              Verified: 1,
              JournalCreated: 0,
              ReversalInitiated: 0,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Create Journal",
              title: this.$t("multiCheckBoxTitles.journal"),
              icon: "ClipboardIcon",
              color: "#0b4666",
            });
          }

          if (
            _.filter(val, {
              EntryType: "Execution",
              Verified: 1,
              JournalCreated: 1,
              ReversalInitiated: 0,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Revers Inititated",
              title: this.$t("multiCheckBoxTitles.revers_initiated"),
              icon: "PlayIcon",
              color: "rgb(255, 255, 153)",
            });
          }

          if (
            _.filter(val, {
              EntryType: "Execution",
              Verified: 1,
              JournalCreated: 1,
              ReversalInitiated: 1,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Reversed",
              title: this.$t("multiCheckBoxTitles.reversed"),
              icon: "CornerUpLeftIcon",
              color: "coral",
            });
          }

          if (
            _.filter(val, {
              EntryType: "Execution",
              Verified: 1,
              JournalCreated: 1,
              ReversalInitiated: 1,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Undo Initiate Reversal",
              title: this.$t("multiCheckBoxTitles.undo_initiate_reversal"),
              icon: "ChevronsLeftIcon",
              color: "black",
            });
          }

          if (
            _.filter(val, {
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Cancel",
              title: this.$t("multiCheckBoxTitles.cancel"),
              icon: "XSquareIcon",
              color: "#ea5455",
            });
          }
        } else {
          this.multiSelectListStatusData = [];
        }
      },
    },
  },
};
</script>

<style>
.treeSelect .vue-treeselect__control {
  height: 38px !important;
  max-height: 38px !important;
}

.table-executed {
  background-color: #e9fafa !important;
}
.table-verified {
  background-color: #00ff7f94 !important;
}
.table-journalCreate {
  background-color: #e1d7f5 !important;
}
.table-expiredOrder {
  background-color: silver !important;
}
.table-reverseInitiated {
  background-color: #ffff99 !important;
}

.table-reversed {
  background-color: #f8ac91!important;
}

.table-canceled {
  background-color: #ffb8b8 !important;
}

.TcUti {
  width: 100%;
}

.column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-width: 150px !important;
  min-height: 38.59px !important;
}

.ID_column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-height: 38.59px !important;
}


.sticky-column { position: sticky; background-color: #fff; z-index: 1; }
.sticky-column:nth-child(1) { left: 0; }
.sticky-column:nth-child(2) { left: 50px; }
.other-col {  z-index: 0; }

.b-table-sticky-column:last-child {
  right: 0;
}





.ID_background {
  background-color: gainsboro !important;
}

.sticky-header {
  position: sticky;
  top: 78px;
  z-index: 999;
  /* Add other styling as needed */
}


.b-table-sticky-header{
  max-height : unset !important;
  height: inherit !important;

}

.hide-rows thead tr[role="row"] { display: none; }

.table-padding {padding: 1rem !important; white-space:nowrap}


</style>

<style lang="scss" scoped>
.top-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue-context/src/sass/vue-context";
</style>
