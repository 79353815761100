<template>
  <div>
    {{ /*view selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      scrollable
      v-model="copyTradeModal"
      :title="$t('fx_spot_forward_swap.titles.copy_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('fx_spot_forward_swap.modals.copy_trade_modal_ok')"
      :cancel-title="$t('fx_spot_forward_swap.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      :no-enforce-focus="true"
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <!-- /*******************************Order Details***************************************************************** */ -->
              <b-row class="mt-1">
                <b-card
                  :title="$t('fx_spot_forward_swap.titles.order_details')"
                  style="width: 100% !important;"
                >
                  <hr />
                  <b-row>
                    <div
                      class="d-flex justify-content-end"
                      style="width: 100%;"
                    >
                      <!-- order date-->
                      <b-col cols="6" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.order_date')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.order_date')
                            "
                            rules="required"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.order_date')
                              "
                              v-model="tradeForm.orderDate"
                              onClose="testClose"
                              class="form-control"
                              :config="orderDateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="checkOrdersDate('Order Date')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order time-->
                      <b-col cols="6" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.order_time')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.order_time')
                            "
                            rules="required|min:8"
                          >
                            <cleave
                              id="orderTime"
                              v-model="tradeForm.orderTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.order_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="checkTime('Order Time')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cpty order date-->
                      <b-col cols="6" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_order_date'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_order_date'
                              )
                            "
                            rules="required"
                          >
                            <flat-pickr
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_order_date'
                                )
                              "
                              v-model="tradeForm.cptyOrderDate"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="
                                checkTraSettlCptyDate('Cpty Order Date')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty order time-->
                      <b-col cols="6" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_order_time'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_order_time'
                              )
                            "
                            rules="required|min:8"
                          >
                            <cleave
                              id="orderTime"
                              v-model="tradeForm.cptyOrderTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_order_time'
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="checkTime('Execution Time')"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration date -->
                      <b-col cols="6" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.order_duration_date'
                            )
                          "
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_duration_date'
                              )
                            "
                            :rules="
                              tradeForm.timeInForce == 'GTD' ? 'required' : ''
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.order_duration_date'
                                )
                              "
                              v-model="tradeForm.orderDurationDate"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="orderDurationDataDisabled"
                              @on-change="
                                checkOrdersDate('Order Duration Date')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration time -->
                      <b-col cols="6" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.order_duration_time'
                            )
                          "
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_duration_time'
                              )
                            "
                            :rules="
                              tradeForm.timeInForce == 'GTD' ? 'required|min:8' : ''
                            "
                          >
                            <cleave
                              id="orderDurationTime"
                              v-model="tradeForm.orderDurationTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.order_duration_time'
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="
                                checkOrdersTime('Order Duration Time')
                              "
                              :disabled="orderDurationTimeDisabled"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                    <!-- entry type -->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.entry_type')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.entry_type')
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.entryType"
                            :options="optEntryTypes"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_entry_type'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- buy/sell -->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.buy_sell')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.buy_sell')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.buySell"
                            :options="optbuySells"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_buy_sell'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            @option:selected="changedBuySell(tradeForm.buySell)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- order type-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.order_type')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.order_type')
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.orderType"
                            :options="optOrderTypes"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_order_type'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- time in force-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.time_in_force')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.time_in_force')
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.timeInForce"
                            :options="optTimeInForces"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_time_in_force'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            @option:selected="changeTimeInForceType"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Stop Price -->
                    <b-col
                      cols="3"
                      xl="3"
                      lg="3"
                      md="3"
                      sm="12"
                      v-if="
                        tradeForm.orderType == 'Stop' ||
                          tradeForm.orderType == 'Stop Limit' ||
                          tradeForm.orderType == 'Trailing Stop'
                      "
                    >
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.stop_price')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.stop_price')
                          "
                          :rules="
                            tradeForm.orderType == 'Stop'
                              ? 'required|min_value:0'
                              : '' || tradeForm.orderType == 'Trailing Stop'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="stopPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.stopPrice"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.stop_price')
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Trailing Amount -->
                    <b-col
                      cols="3"
                      xl="3"
                      lg="3"
                      md="3"
                      sm="12"
                      v-if="tradeForm.orderType == 'Trailing Stop'"
                    >
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.trailing_amount')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.trailing_amount'
                            )
                          "
                          :rules="
                            tradeForm.orderType == 'Trailing Stop'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <b-form-input
                            id="trailingAmount"
                            v-model.number="tradeForm.trailingAmount"
                            name="trailingAmount"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.trailing_amount'
                              )
                            "
                            autofocus
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <hr />
                    </b-col>
                    <div
                      class="d-flex"
                      style="width: 100%;"
                      v-if="tradeForm.productType != 'SWAP'"
                    >
                      <!-- enable connected OCO orders-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="enable OCO orders"
                          >
                            <b-form-checkbox
                              v-model="tradeForm.enableConnectedOCOOrders"
                            >
                              {{
                                $t("fx_spot_forward_swap.trade_form.enable_oco")
                              }}
                            </b-form-checkbox>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Take Profit Limit -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.take_profit_limit'
                            )
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.take_profit_limit'
                              )
                            "
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="takeProfitLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.takeProfitLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.take_profit_limit'
                                )
                              "
                              @blur.native="
                                checkEnableOCOInputs('takeProfitLimit')
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Stop -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.stop_loss_stop')
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.stop_loss_stop'
                              )
                            "
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossStop"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossStop"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.stop_loss_stop'
                                )
                              "
                              @blur.native="
                                checkEnableOCOInputs('stopLossStop')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Limit -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.stop_loss_limit'
                            )
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.stop_loss_limit'
                              )
                            "
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.stop_loss_limit'
                                )
                              "
                              @blur.native="
                                checkEnableOCOInputs('stopLossLimit')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                  </b-row>
                </b-card>
              </b-row>

              <!-- /******************************* Client & Counterparty Details***************************************************************** */ -->
              <b-row>
                <b-card
                  :title="
                    $t(
                      'fx_spot_forward_swap.titles.client_counterparty_details'
                    )
                  "
                  style="width: 100% !important;"
                >
                  <hr />
                  <b-row>
                    <div class="d-flex" style="width: 100%;">
                      <!-- client -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.client')"
                        >
                        <div class="d-flex align-items-center">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.client')"
                            style="width: 100%;"
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              v-model="tradeForm.client"
                              :options="optClients"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_client'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :loading="loadingClient"
                              :filterable="true"
                              @option:selecting="selectedClient"
                              
                            />
                            <!-- @search="
                                (search, loading) => {
                                  loading(false);
                                  searchClient(search);
                                }
                              " -->
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                          <b-dropdown
                            variant="link"
                            no-caret
                            :right="$store.state.appConfig.isRTL"
                            class="input-dropdown animate__animated animate__fadeIn"
                            v-if="this.searchedClients.length > 0"
                          >
                            <template #button-content class="p-1">
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                              />
                            </template>

                            <b-dropdown-item @click="seeFilteredClientModal">
                              <feather-icon icon="EyeIcon" />
                              <span class="align-middle ml-50">{{
                                $t("general_text.see_verified_clients")
                              }}</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                        </b-form-group>
                      </b-col>
                      <!-- client account -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.client_account')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_account'
                              )
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-form-input
                              id="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_account'
                                )
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- counter party -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.counterparty')
                          "
                        >
                        <div class="d-flex align-items-center">
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.counterparty')
                            "
                            style="width: 100%;"
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              v-model="tradeForm.counterparty"
                              :options="optCounterParties"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_counterparty'
                                )
                              "
                              :reduce="(val) => val"
                              :loading="loadingCounterparty"
                              :filterable="true"
                              @option:selecting="selectedCounterparty"
                              
                            />
                            <!-- @search="
                                (search, loading) => {
                                  loading(false);
                                  searchCounterparty(search);
                                }
                              " -->
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                          <b-dropdown
                            variant="link"
                            no-caret
                            :right="$store.state.appConfig.isRTL"
                            class="input-dropdown animate__animated animate__fadeIn"
                            v-if="this.searchedCounterparties.length > 0"
                          >
                            <template #button-content class="p-1">
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                              />
                            </template>

                            <b-dropdown-item @click="seeFilteredCounterpartyModal">
                              <feather-icon icon="EyeIcon" />
                              <span class="align-middle ml-50">{{
                                $t("general_text.see_verified_clients")
                              }}</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                        </b-form-group>
                      </b-col>
                      <!-- cpty account -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.cpty_account')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.cpty_account')
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-form-input
                              id="cptyAccount"
                              v-model="tradeForm.cptyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_account'
                                )
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                  </b-row>
                </b-card>
              </b-row>

              <b-row>
                <!-- /******************************* FX Details***************************************************************** */ -->
                <b-col cols="12" sm="12" md="5" lg="5" xl="5" class="pl-0">
                  <b-card
                    :title="$t('fx_spot_forward_swap.titles.fx_details')"
                    style="width: 100% !important;"
                  >
                    <hr />
                    <!-- product type-->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.product_type')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.product_type')
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.productType"
                            :options="optproductTypes"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_product_type'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            @option:selected="
                              selectedProductType(tradeForm.productType)
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- settlement type-->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.settlement_type')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.settlement_type'
                            )
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.settlementType"
                            :options="optSettlementTypes"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_settlement_type'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            @option:selected="
                              selectedSettlementType(tradeForm.settlementType)
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cost_center')"
                      >
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cost_center')"
                          rules="required"
                        >

                      <v-select
                      v-model="tradeForm.costCenter"
                      :options="optCostCenters"
                      :clearable="false"
                      :placeholder="$t('equity_bond.trade_form.select_cost_center')"
                      :reduce="(val) => val.CostCenter"
                      label = 'CostCenter'

                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                  </b-form-group>
                    </b-col>



                    <!-- Counterparty Rate -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.counterparty_rate'
                          )
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.counterparty_rate'
                            )
                          "
                          :rules="
                            tradeForm.entryType == 'Execution'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <b-form-checkbox
                                plain
                                name="checkbox-input"
                                v-model="tradeForm.cptyCalculateFromRate1"
                                v-b-tooltip.hover.top
                                :title="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.counterparty_rate_tooltip'
                                  )
                                "
                                @change="changedCptyCalculateFromRate1"
                              />
                            </b-input-group-prepend>

                            <cleave
                              id="counterpartyRate"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.counterpartyRate"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.counterparty_rate'
                                )
                              "
                              @blur.native="
                                changedCounterpartyRate(
                                  tradeForm.counterpartyRate
                                )
                              "
                              :disabled="!tradeForm.cptyCalculateFromRate1"
                            />
                          </b-input-group>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <hr />
                    <div
                      class="d-flex justify-content-center mb-1"
                      style="text-decoration:underline;"
                    >
                      <h5>{{ $t("fx_spot_forward_swap.trade_form.sfc") }}</h5>
                    </div>
                    <!-- SFC -->
                    <div class="d-flex">
                      <!-- cptyAmount1 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.cpty_amount1')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.cpty_amount1')
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="cpty_amount1"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cptyAmount1"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_amount1'
                                )
                              "
                              @blur.native="
                                calculateAsCptyAmount1(tradeForm.cptyAmount1)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cptyCcy1 -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        class="pl-0"
                      >
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.cptyCcy1"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCptyCcy1(tradeForm.productType)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cptyBuySell1 -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        class="pl-0"
                      >
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.cpty_buy_sell1')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_buy_sell1'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.cptyBuySell1"
                              :options="optcptyBuySells"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_buy_sell1'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                    <!-- SFC 2 -->
                    <div class="d-flex">
                      <!-- cptyAmount2 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.cpty_amount2')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.cpty_amount2')
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="cpty_amount2"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cptyAmount2"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_amount2'
                                )
                              "
                              @blur.native="
                                calculateAsCptyAmount2(tradeForm.cptyAmount2)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cptyCcy2 -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        class="pl-0"
                      >
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.cptyCcy2"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCptyCcy2(tradeForm.productType)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cptyBuySell2 -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        class="pl-0"
                      >
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.cpty_buy_sell2')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_buy_sell2'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.cptyBuySell2"
                              :options="optcptyBuySells"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_buy_sell2'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                    <hr />
                    <!-- calculate client rate radio buttons -->
                    <b-col cols="12" sm="12" xl="12" lg="12" md="12">
                      <b-form-group>
                        <b-form-radio-group
                          v-model="tradeForm.calcFromSpread"
                          :options="optCalcFromSpread"
                          name="radios-stacked"
                          stacked
                          class="fxDetailRadio"
                          @change="
                            changedCalcFromSpread(tradeForm.calcFromSpread)
                          "
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Spread -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.spread')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.spread')"
                          :rules="
                            tradeForm.calculateCommissionFromRate2 && tradeForm.calcFromSpread == 'Calculate Client Rate From Spread'
                              ? 'min_value:0'
                              : ''
                          "
                        >
                          <b-input-group append="pips">
                            <cleave
                              id="spread"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.spread"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.spread')
                              "
                              @blur.native="changedSpread(tradeForm.spread)"
                              :disabled="
                                tradeForm.calcFromSpread !=
                                  'Calculate Client Rate From Spread'
                              "
                            />
                          </b-input-group>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Client Rate -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.client_rate')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.client_rate')
                          "
                          :rules="
                            tradeForm.entryType == 'Execution'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <b-form-checkbox
                                plain
                                name="checkbox-input"
                                v-model="tradeForm.clientCalculateFromRate2"
                                v-b-tooltip.hover.top
                                :title="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.client_rate_tooltip'
                                  )
                                "
                                @change="changedClientCalculateFromRate2"
                                :disabled="
                                  tradeForm.calcFromSpread ==
                                    'Calculate Client Rate From Spread'
                                "
                              >
                              </b-form-checkbox>
                            </b-input-group-prepend>

                            <cleave
                              id="clientRate"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientRate"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_rate'
                                )
                              "
                              @blur.native="
                                changedClientRate(tradeForm.clientRate)
                              "
                              :disabled="
                                !tradeForm.clientCalculateFromRate2 ||
                                  tradeForm.calcFromSpread ==
                                    'Calculate Client Rate From Spread'
                              "
                            />
                          </b-input-group>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Client -->
                    <hr />
                    <div
                      class="d-flex justify-content-center mb-1"
                      style="text-decoration:underline;"
                    >
                      <h5>
                        {{ $t("fx_spot_forward_swap.trade_form.client2") }}
                      </h5>
                    </div>
                    <div class="d-flex">
                      <!-- clientAmount1 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.client_amount1')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_amount1'
                              )
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientAmount1"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_amount1'
                                )
                              "
                              @blur.native="
                                calculateAsClientAmount1(
                                  tradeForm.clientAmount1
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- clientCcy1 -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        class="pl-0"
                      >
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientCcy1"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- clientBuySell1 -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        class="pl-0"
                      >
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.client_buy_sell1'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_buy_sell1'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientBuySell1"
                              :options="optclientBuySells"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_buy_sell1'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                    <!-- Client 2 -->
                    <div class="d-flex">
                      <!-- clientAmount2 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.client_amount2')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_amount2'
                              )
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientAmount2"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_amount2'
                                )
                              "
                              @blur.native="
                                calculateAsClientAmount2(
                                  tradeForm.clientAmount2
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- clientCcy2 -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        class="pl-0"
                      >
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientCcy2"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- clientBuySell2 -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        class="pl-0"
                      >
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.client_buy_sell2'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_buy_sell2'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientBuySell2"
                              :options="optclientBuySells"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_buy_sell2'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                    <hr />

                    <!-- Spot Ref -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.spot_ref')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="spot_ref"
                          :rules="
                            tradeForm.entryType == 'Execution'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                        <cleave
                              id="spot_ref"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.spotRef"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.spot_ref'
                                )
                              "
                              @blur.native="
                                changeSpotRef(
                                  tradeForm.spotRef
                                )
                              "
                            />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Spred comm -->
                    <div class="d-flex">
                      <!-- spread comm -->
                      <b-col cols="12" xl="9" lg="9" md="9" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.spread_comm')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.spread_comm')
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required'
                                : ''
                            "
                          >
                            <cleave
                              id="spreadAmount"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.spreadAmount"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.spread_comm'
                                )
                              "
                              @blur.native="
                                changeSpreadAmount(
                                  tradeForm.spreadAmount
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- spread price types CCY -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        class="pl-0"
                      >
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.spreadCcy"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              @option:selected="
                                selectedSpreadCcy(tradeForm.productType)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <!-- Comission -->
                    <div class="d-flex">
                      <!-- comission -->
                      <b-col cols="12" xl="9" lg="9" md="9" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.comission')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.comission')
                            "
                            rules="min_value:0"
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.comissionAmount"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.comission')
                              "
                              @blur.native="
                                changeComissionAmount(
                                  tradeForm.comissionAmount
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- comission CCY -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        class="pl-0"
                      >
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.price_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.price_type')
                            "
                          >
                            <v-select
                              v-model="tradeForm.comissionCcy"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.price_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <!-- cut off time -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.cut_off_time')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.cut_off_time')
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' ? 'required' : ''
                          "
                        >
                          <v-select
                            :disabled="
                              tradeForm.settlementType == 'PHYSICAL' ||
                                tradeForm.productType != 'FORWARD'
                            "
                            v-model="tradeForm.cutOffTime"
                            :options="optCutOffTimes"
                            :clearable="false"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.cut_off_time')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- deliverable ccy-->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.deliverable_ccy')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.deliverable_ccy'
                            )
                          "
                          rules="required"
                        >
                          <v-select
                            :disabled="
                              tradeForm.settlementType == 'PHYSICAL' ||
                                tradeForm.productType != 'FORWARD'
                            "
                            v-model="tradeForm.deliverableCcy"
                            :options="optPriceTypes"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.deliverable_ccy'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-card>
                </b-col>

                <b-col cols="12" sm="12" md="7" lg="7" xl="7">
                  <!-- /******************************* Swap Details***************************************************************** */ -->
                  <b-row
                    v-if="tradeForm.productType == 'SWAP'"
                    class="animate__animated animate__fadeIn"
                  >
                    <b-card
                      :title="$t('fx_spot_forward_swap.titles.swap_details')"
                      style="width: 100% !important;"
                    >
                      <div class="d-flex" style="width: 100%;">
                        <!-- Swap Trade Date -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_trade_date'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_trade_date'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <b-input-group
                                :class="
                                  tradeForm.swapTradeDateName == 'Sunday' ||
                                  tradeForm.swapTradeDateName == 'Saturday'
                                    ? 'swapTradeName'
                                    : ''
                                "
                                :append="tradeForm.swapTradeDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.swap_trade_date'
                                    )
                                  "
                                  v-model="tradeForm.swapTradeDate"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  :disabled="true"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Maturity -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.maturity')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.maturity')
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required|min_value:0'
                                  : ''
                              "
                            >
                              <b-input-group append="Days">
                                <b-form-input
                                  id="maturity"
                                  v-model.number="tradeForm.maturity"
                                  :state="errors.length > 0 ? false : null"
                                  type="number"
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.maturity'
                                    )
                                  "
                                  autofocus
                                  @change="
                                    setSwapTradetoSettleDays(tradeForm.maturity)
                                  "
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Swap settle Date -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_settle_date'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_settle_date'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <b-input-group
                                :class="
                                  tradeForm.swapSettleDateName == 'Sunday' ||
                                  tradeForm.swapSettleDateName == 'Saturday'
                                    ? 'swapSettleName'
                                    : ''
                                "
                                :append="tradeForm.swapSettleDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.swap_settle_date'
                                    )
                                  "
                                  v-model="tradeForm.swapSettleDate"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  @on-change="
                                    arrangeSwapTradetoSettleDays(
                                      'Swap Settle Date'
                                    )
                                  "
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <div class="d-flex" style="width: 100%;">
                        <!-- Swap Points -->
                        <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.swap_points')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_points'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required|min_value:0'
                                  : ''
                              "
                            >
                              <b-input-group append="pips">
                                <cleave
                                  id="swap_points"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.swapPoints"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.swap_points'
                                    )
                                  "
                                  @blur.native="
                                    changedSwapPoints(tradeForm.swapPoints)
                                  "
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- swap cpty rate -->
                        <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_rate'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_cpty_rate'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required|min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="swap_cpty_rate"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapCptyRate"
                                class="form-control"
                                :raw="true"
                                :options="cleave4DecimalOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_cpty_rate'
                                  )
                                "
                                :disabled="true"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <!-- Swap SFC -->
                      <hr />
                      <div
                        class="d-flex justify-content-center mb-1"
                        style="text-decoration:underline;"
                      >
                        <h5>
                          {{ $t("fx_spot_forward_swap.trade_form.swap_sfc") }}
                        </h5>
                      </div>

                      <div class="d-flex">
                        <!-- swap cptyAmount1 -->
                        <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_amount1'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_cpty_amount1'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required|min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="clientPrice"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapCptyAmount1"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_cpty_amount1'
                                  )
                                "
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swapCptyCcy1 -->
                        <b-col
                          cols="12"
                          xl="3"
                          lg="3"
                          md="3"
                          sm="12"
                          class="pl-0"
                        >
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-model="tradeForm.swapCptyCcy1"
                                :options="optPriceTypes"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!--swap  cptyBuySell 1 -->
                        <b-col
                          cols="12"
                          xl="3"
                          lg="3"
                          md="3"
                          sm="12"
                          class="pl-0"
                        >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_buysell1'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_cpty_buysell1'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-model="tradeForm.swapCptyBuySell1"
                                :options="optcptyBuySells"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_cpty_buysell1'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <!-- swap SFC 2 -->
                      <div class="d-flex">
                        <!-- swap cptyAmount 2 -->
                        <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_amount2'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_cpty_amount2'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required|min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="clientPrice"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapCptyAmount2"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_cpty_amount2'
                                  )
                                "
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swap cptyPriceType 2 -->
                        <b-col
                          cols="12"
                          xl="3"
                          lg="3"
                          md="3"
                          sm="12"
                          class="pl-0"
                        >
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-model="tradeForm.swapCptyCcyType2"
                                :options="optPriceTypes"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swap cptyBuySell 2 -->
                        <b-col
                          cols="12"
                          xl="3"
                          lg="3"
                          md="3"
                          sm="12"
                          class="pl-0"
                        >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_buysell2'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_cpty_buysell2'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-model="tradeForm.swapCptyBuySell2"
                                :options="optcptyBuySells"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_cpty_buysell2'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <hr />

                      <!-- swap calculate client rate radio buttons -->
                      <b-col
                        cols="12"
                        sm="12"
                        xl="12"
                        lg="12"
                        md="12"
                        class="mt-1 mb-1"
                      >
                        <b-form-group>
                          <b-form-radio-group
                            v-model="tradeForm.swapCalcFromSpread"
                            :options="optCalcFromSpread"
                            name="radios-stacked2"
                            @change="changeSwapCalcFromSpread"
                          />
                        </b-form-group>
                      </b-col>

                      <div class="d-flex" style="width: 100%;">
                        <!-- Swap Spread -->
                        <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.swap_spread')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_spread'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required|min_value:0'
                                  : ''
                              "
                            >
                              <b-input-group append="pips">
                                <cleave
                                  id="swap_spread"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.swapSpread"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.swap_spread'
                                    )
                                  "
                                  @blur.native="
                                    changedSwapSpread(tradeForm.swapSpread)
                                  "
                                  :disabled="
                                  tradeForm.swapCalcFromSpread ==
                                    'Calculate Spread From Client Rate'
                                "
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- swap Client Rate -->
                        <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_rate'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_client_rate'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required|min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="clientRate"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapClientRate"
                                class="form-control"
                                :raw="true"
                                :options="cleave4DecimalOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_client_rate'
                                  )
                                "
                                :disabled="
                                  tradeForm.swapCalcFromSpread ==
                                    'Calculate Client Rate From Spread'
                                "
                                @blur.native="
                                    changedSwapClientRate(tradeForm.swapSpread)
                                  "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <!-- swap Client -->
                      <hr />
                      <div
                        class="d-flex justify-content-center mb-1"
                        style="text-decoration:underline;"
                      >
                        <h5>
                          {{
                            $t("fx_spot_forward_swap.trade_form.swap_client")
                          }}
                        </h5>
                      </div>
                      <div class="d-flex">
                        <!-- swap clientAmount1 -->
                        <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_amount1'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_client_amount1'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required|min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="clientPrice"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapClientAmount1"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_client_amount1'
                                  )
                                "
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swap clientPriceType 1 -->
                        <b-col
                          cols="12"
                          xl="3"
                          lg="3"
                          md="3"
                          sm="12"
                          class="pl-0"
                        >
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-model="tradeForm.swapClientCcy1"
                                :options="optPriceTypes"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swap clientBuySell 1 -->
                        <b-col
                          cols="12"
                          xl="3"
                          lg="3"
                          md="3"
                          sm="12"
                          class="pl-0"
                        >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_buysell1'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_client_buysell1'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-model="tradeForm.swapClientBuySell1"
                                :options="optclientBuySells"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_client_buysell1'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <!-- swap Client 2 -->
                      <div class="d-flex">
                        <!-- swap clientAmount 2 -->
                        <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_amount2'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_client_amount2'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required|min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="clientPrice"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapClientAmount2"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_client_amount2'
                                  )
                                "
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swap clientPriceType 2 -->
                        <b-col
                          cols="12"
                          xl="3"
                          lg="3"
                          md="3"
                          sm="12"
                          class="pl-0"
                        >
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-model="tradeForm.swapClientCcy2"
                                :options="optPriceTypes"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swap clientBuySell 2 -->
                        <b-col
                          cols="12"
                          xl="3"
                          lg="3"
                          md="3"
                          sm="12"
                          class="pl-0"
                        >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_buysell2'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_client_buysell2'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-model="tradeForm.swapClientBuySell2"
                                :options="optclientBuySells"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_client_buysell2'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <hr />
                      <!--swap Spred amount -->
                      <div class="d-flex">
                        <!-- swap spread amount -->
                        <b-col cols="12" xl="9" lg="9" md="9" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.spread_amount'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.spread_amount'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.productType == 'SWAP'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <cleave
                                id="clientPrice"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapSpreadAmount"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.spread_amount'
                                  )
                                "
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!--swap  spread price types CCY -->
                        <b-col
                          cols="12"
                          xl="3"
                          lg="3"
                          md="3"
                          sm="12"
                          class="pl-0"
                        >
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.price_type')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.price_type')
                              "
                              :rules="
                                tradeForm.entryType == 'Execution'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-model="tradeForm.swapSpreadCcy"
                                :options="optPriceTypes"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.price_type'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                    </b-card>
                  </b-row>

                  <!-- /******************************* Trade Details***************************************************************** */ -->
                  <b-row>
                    <b-card
                      :title="$t('fx_spot_forward_swap.titles.trade_details')"
                      style="width: 100% !important;"
                    >
                      <hr />
                      <div class="d-flex" style="width: 100%;">
                        <!-- Trade Date -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.trade_date')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.trade_date')
                              "
                            >
                              <b-input-group
                                :class="
                                  tradeForm.tradeDateName == 'Sunday' ||
                                  tradeForm.tradeDateName == 'Saturday'
                                    ? 'tradeDateName'
                                    : ''
                                "
                                :append="tradeForm.tradeDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.trade_date'
                                    )
                                  "
                                  v-model="tradeForm.tradeDate"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  @on-change="
                                    checkTraSettlCptyDate('Trade Date')
                                  "
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                         <!-- T+ -->
                         <b-col
                          cols="6"
                          xl="1"
                          lg="1"
                          md="1"
                          sm="12"
                          style="padding-left: 0.5rem !important; padding-right:0.5rem !important"
                        >
                          <b-form-group label="T+">
                            <validation-provider
                              #default="{ errors }"
                              name="T+"
                              rules="min_value:0"
                            >
                              <b-form-input
                                id="T+"
                                v-model.number="tradeForm.Tplus"
                                name="T+"
                                placeholder="T+"
                                autofocus
                                @change="arrangeTPlus"
                                type="number"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Settlement Date -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.settlement_date'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.settlement_date'
                                )
                              "
                            >
                              <b-input-group
                                :class="
                                  tradeForm.settlementDateName == 'Sunday' ||
                                  tradeForm.settlementDateName == 'Saturday'
                                    ? 'settlementDateName'
                                    : ''
                                "
                                :append="tradeForm.settlementDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.settlement_date'
                                    )
                                  "
                                  v-model="tradeForm.settlementDate"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  @on-change="
                                    checkTraSettlCptyDate('Settlement Date')
                                  "
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- execution time -->
                        <b-col cols="6" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.execution_time'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.execution_time'
                                )
                              "
                              rules="required|min:8"
                            >
                              <cleave
                                id="executionTime"
                                v-model="tradeForm.executionTime"
                                class="form-control"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.execution_time'
                                  )
                                "
                                :state="errors.length > 0 ? false : null"
                                @blur.native="checkTime('Execution Time')"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <div class="d-flex" style="width: 100%;">
                        <!-- counter party trader -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.counterparty_trader'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.counterparty_trader'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.counterpartyTrader"
                                :options="optCounterPartyTraders"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_counterparty_trader'
                                  )
                                "
                                :reduce="(val) => val"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- sfc user -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.sfc_user')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.sfc_user')
                              "
                              rules="required"
                            >
                              <v-select
                                v-model="tradeForm.scfUser"
                                :options="optScfUsers"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_sfc_user'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order taken via -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_taken_via'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.order_taken_via'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderTakenVia"
                                :options="optOrderTakenVias"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_order_taken_via'
                                  )
                                "
                                :reduce="(val) => val"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <div class="d-flex" style="width:100%">
                        <!-- order taken through -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_given_through'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.order_given_through'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderGivenThrough"
                                :options="optOrderGivenThroughs"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_order_given_through'
                                  )
                                "
                                :reduce="(val) => val"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- acting capacity -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.acting_capacity'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.acting_capacity'
                                )
                              "
                              rules="required"
                            >
                              <v-select
                                v-model="tradeForm.actingCapaticy"
                                :options="optActingCapacities"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_acting_capacity'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <!-- note -->
                      <b-col cols="12" xl="8" lg="8" md="8" sm="12">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.note')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.note')"
                          >
                            <b-form-textarea
                              id="textarea-no-resize"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.note')
                              "
                              rows="2"
                              no-resize
                              v-model="tradeForm.note"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-card>
                  </b-row>

                  <!-- /******************************* Settlement Details***************************************************************** */ -->
                  <b-row class="d-flex justify-content-end">
                    <b-card
                      title="Settlement Details"
                      style="width: 100% !important;"
                    >
                      <hr />
                      <div class="d-flex">
                        <!-- UTI  -->
                        <b-col cols="12" xl="5" lg="5" md="5" sm="12">
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.uti')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.uti')"
                            >
                              <b-form-input
                                id="UTI"
                                v-model="tradeForm.uti"
                                name="UTI"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.uti')
                                "
                                autofocus
                                :disabled="true"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Unique Link ID -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.unique_link_id'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.unique_link_id'
                                )
                              "
                            >
                              <b-form-input
                                id="uniqueLinkID"
                                v-model.number="tradeForm.uniqueLinkID"
                                type="number"
                                name="uniqueLinkID"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.unique_link_id'
                                  )
                                "
                                autofocus
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Trade Ref -->
                        <!-- <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.trade_ref')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.trade_ref')
                              "
                            >
                              <b-form-input
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.trade_ref'
                                  )
                                "
                                autofocus
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                      </div>

                      <div class="d-flex">
                        <!-- Trade Start  -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="trade start"
                            >
                              <flat-pickr
                                placeholder="Trade Start"
                                v-model="tradeForm.tradeStart"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                        <!-- Trade end -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="trade end"
                            >
                              <flat-pickr
                                placeholder="Trade End"
                                v-model="tradeForm.tradeEnd"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                      </div>
                      <div class="d-flex">
                        <!-- Settle Start  -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="settle start"
                            >
                              <flat-pickr
                                placeholder="Settle Start"
                                v-model="tradeForm.settleStart"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                        <!-- Settle end -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="settle end"
                            >
                              <flat-pickr
                                placeholder="Settle End"
                                v-model="tradeForm.settleEnd"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                      </div>
                      <div class="d-flex">
                        <!-- entry type -->
                        <!-- <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.entry_type2'
                                )
                              "
                            >
                              <b-form-input
                                id="tradeRef"
                                v-model="tradeForm.entryType2"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.entry_type2'
                                  )
                                "
                                autofocus
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                      </div>
                    </b-card>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" /> <b
                >{{$t('fx_spot_forward_swap.overlay_messages.copy_message')}}</b> 
            </p>
          </div>
        </template>
      </b-overlay>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            id="tradeAction-button"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            class="float-left mb-0"
            :disabled="actionsData == null || actionsData.length == 0"
          >
            <feather-icon
              icon="EyeIcon"
              size="16"
              style="margin-right: 0.2rem;"
            />

            <span class="text-nowrap">{{ $t('fx_spot_forward_swap.buttons.trade_actions') }}</span>
          </b-button>
          <b-popover
            target="tradeAction-button"
            placement="top"
            triggers="click"
            id="actions-popover"
          >
            <template #title>
              {{ $t('fx_spot_forward_swap.titles.trade_actions') }}
            </template>
            <b-table-simple hover small caption-top responsive bordered>
              <b-tbody>
                <b-tr v-for="(action, index) in actionsData" :key="index">
                  <td style="width: 20%;">
                    {{ action.type }}
                  </td>
                  <td style="width: 20%;">
                    {{ action.user }}
                  </td>
                  <td style="width: 30%;">
                    {{ action.date }}
                  </td>
                  <td style="width: 30%;">
                    {{ action.reason }}
                  </td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-popover>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="md"
            class="float-right"
            @click="tradeOkOperation"
          >
          {{$t('fx_spot_forward_swap.modals.copy_trade_modal_ok')}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            size="md"
            class="float-right mr-1"
            @click="resetTradeModal"
          >
          {{$t('fx_spot_forward_swap.modals.modal_close')}}
          </b-button>
        </div>
      </template>
      {{/****END*** amend for trades modal*/}}
    </b-modal>
    {{/** view filtered client modal*/}}
    <b-modal
      id="clientModal"
      v-model="viewFilteredClientModal"
      :title="$t('client_table_modal.filtered_clients')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetClientModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      scrollable
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedClients"
                selectable
                select-mode="single"
                @row-clicked="selectedClientFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="
                  $t('equity_bond.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered modal*/}}
    </b-modal>
    {{/** view filtered counterparty modal*/}}
    <b-modal
      id="counterpartyModal"
      v-model="viewFilteredCounterpartyModal"
      :title="$t('client_table_modal.filtered_counterparties')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetCounterpartyModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      scrollable
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedCounterparties"
                selectable
                select-mode="single"
                @row-clicked="selectedCounterpartyFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="
                  $t('equity_bond.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered counterparty modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import fXSpotForwardSwapStoreModule from "./FXSpotForwardSwapStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  alphaNum,
  email,
  max,
  max_value,
  min_value,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
    AppTimeline,
    AppTimelineItem,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
      const FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME = "fxSFS";
      // Register module
      if (!store.hasModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME))
        store.registerModule(
          FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME,
          fXSpotForwardSwapStoreModule
        );
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME))
          store.unregisterModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME);
      });
    },

  mounted() {},
  props: {
    
    searchedCounterparties: {
      type: Array,
        default: function() {
        return [];
      }
    },
    searchedClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCostCenters: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCounterParties: {
      type: Array,
        default: function() {
        return [];
      }
    }
},

  data() {
    return {
      copyTradeModal: false,
      tradeModalLoading: false,
      loadingClient: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      actionsData: null,
      checkVerify: false,
      orderDurationDataDisabled: true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optproductTypes: ["SPOT", "FORWARD", "SWAP"],
      optSettlementTypes: ["PHYSICAL", "CASH"],
      optCalcFromSpread: [
        {
          text: "Calculate Client Rate From Spread",
          value: "Calculate Client Rate From Spread",
        },
        {
          text: "Calculate Spread From Client Rate",
          value: "Calculate Spread From Client Rate",
        },
      ],

      optPriceTypes: [],
      optCounterPartyTraders: [],
      optCutOffTimes: ["10:00 NY", "12:00 LN"],
      optcptyBuySells: [],
      optclientBuySells: [],
      optScfUsers: [],
      optActingCapacities: ["PRINCIPAL", "AGENT"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      fetchedTradeData: {},
      oldTradeFormData: {},
      checkedHolidayDate: null,
      tradeForm: {
        costCenter:null,
        orderDurationDate: null,
        orderDurationTime: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderTime: null,
        cptyOrderDate: null,
        cptyOrderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        productType: null,
        settlementType: null,
        counterpartyRate: null,
        cptyCalculateFromRate1: true,
        clientRate: null,
        clientCalculateFromRate2: true,
        cptyAmount1: null,
        cptyAmount2: null,
        cptyCcy1: null,
        cptyCcy2: null,
        cptyBuySell1: null,
        cptyBuySell2: null,
        calcFromSpread: "Calculate Spread From Client Rate",
        spread: null,
        clientAmount1: null,
        clientAmount2: null,
        clientCcy1: null,
        clientCcy2: null,
        clientBuySell1: null,
        clientBuySell2: null,
        spotRef: null,
        spreadAmount: null,
        spreadCcy: null,
        comissionAmount: null,
        comissionCcy: null,
        cutOffTime: null,
        deliverableCcy: null,
        tradeDate: null,
        tradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        Tplus: null,
        executionTime: null,
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        swapTradeDate: null,
        swapTradeDateName: null,
        maturity: null,
        swapSettleDate: null,
        swapSettleDateName: null,
        swapPoints: null,
        swapCptyRate: null,
        swapCptyAmount1: null,
        swapCptyCcy1: null,
        swapCptyBuySell1: null,
        swapCptyAmount2: null,
        swapCptyCcyType2: null,
        swapCptyBuySell2: null,
        swapSpread: null,
        swapCalcFromSpread: "Calculate Client Rate From Spread",
        swapClientRate: null,
        swapClientAmount1: null,
        swapClientCcy1: null,
        swapClientBuySell1: null,
        swapClientAmount2: null,
        swapClientCcy2: null,
        swapClientBuySell2: null,
        swapSpreadAmount: null,
        swapSpreadCcy: null,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        entryType2: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
      },
      orderDateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        // onChange: function(selectedDates, dateStr, instance) {
        //   console.log(instance)
        // }
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          numeralDecimalMarkAlternativeInput: '.',
          completeDecimalsOnBlur: true
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 4,
          numeralDecimalMarkAlternativeInput: '.',
          completeDecimalsOnBlur: true
        },
      },
      viewFilteredClientModal: false,
      viewFilteredCounterpartyModal:false,
      clientTableColumns: [
        {
          key: "AccountName",
          label: this.$t("client_table_modal.account_name"),
          class: "text-center",
        },
        {
          key: "AccountNumber",
          label: this.$t("client_table_modal.account_code"),
          sortable: false,
          class: "text-center",
        },
        {
          key: "SettlementType",
          label: this.$t("client_table_modal.settlement_type"),
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchFxForwardSwap"]),
    getArangeFxExecutedTCUsers() {
      store
        .dispatch("fxSFS/fetchArangeFxExecutedTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
          } else if (res.data.info == "no_users") {
            this.errorMessage(res.data.message);
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeFxExecutedOrderTakenGivenThrough() {
      store
        .dispatch(
          "fxSFS/fetchArrangeFxExecutedOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          }
          //  else {
          //   this.errorMessage(
          //     this.$t("fx_spot_forward_swap.messages.creating_message")
          //   );
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeFxExecutedCurrencies() {
      store
        .dispatch("fxSFS/fetchArrangeFxExecutedCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optPriceTypes = res.data.currencies;

            this.tradeForm.cptyCcy1 = this.optPriceTypes.find(
              (x) => x === "GBP"
            )
              ? "GBP"
              : null;
            this.tradeForm.cptyCcy2 = this.optPriceTypes.find(
              (x) => x === "USD"
            )
              ? "USD"
              : null;
            this.tradeForm.clientCcy1 = this.optPriceTypes.find(
              (x) => x === "GBP"
            )
              ? "GBP"
              : null;
            this.tradeForm.clientCcy2 = this.optPriceTypes.find(
              (x) => x === "USD"
            )
              ? "USD"
              : null;
            this.tradeForm.spreadCcy = this.optPriceTypes.find(
              (x) => x === "USD"
            )
              ? "USD"
              : null;
            this.tradeForm.deliverableCcy = this.optPriceTypes.find(
              (x) => x === "GBP"
            )
              ? "GBP"
              : null;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getObjectDiff(obj1, obj2) {
      const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
          result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key);
          result.splice(resultKeyIndex, 1);
        }
        return result;
      }, Object.keys(obj2));
      return diff;
    },
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      
      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          
          this.$checkAccessRight("FxExecuted", "Save").then((res) => {
            if (res.data.valid) {
              this.tradeModalLoading = true;
              //set and check trade data
              let tradeData = this.setTradeData();

              store
                .dispatch("fxSFS/copySelectedFxTrade", {
                  auth: this.user,
                  tradeData: tradeData,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    this.copyTradeModal = false
                    this.$emit("copiedFxSFS", true);
                    this.copiedTradeMessage();
                    this.resetTradeModal();
                    this.tradeModalLoading = false;
                  } else {
                    this.errorMessage(res.data.message);
                    this.tradeModalLoading = false;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.enterNewTradeModal = false;
              this.notAuthToastMessage();
            }
          });
        }else {
          let notEnteredDatas = "";

          let checkRequiredDatas = _.map(
            this.$refs.tradeValidation.errors,
            (value, key) => ({ key, value })
          );

          if (checkRequiredDatas.length > 0) {
            let first = true;
            checkRequiredDatas.forEach((r) => {
              if (r.value.length > 0) {
                if (first) {
                  notEnteredDatas += r.key;
                  first = false;
                } else {
                  notEnteredDatas += "," + r.key;
                  first = false;
                }
              }
            });
            this.checkRequiredInputsMessage(notEnteredDatas, 20000);
          }
        }
      });
    },
  
    
    searchClient(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingClient = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchClient`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optClients = [];
                  this.searchedClients = res.data.resData;
                  res.data.resData.forEach((data) => {
                    this.optClients.push(data.AccountName);
                  });

                  this.loadingClient = false;
                } else {
                  this.notFoundMessage("Client");
                  this.loadingClient = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedClient(search) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;
    },
    selectedClientFromTable(client){
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === client.AccountName
      );
      this.tradeForm.client = client.AccountName
      this.tradeForm.clientAccount = client.AccountNumber;
      this.viewFilteredClientModal = false;
    },
    seeFilteredClientModal() {
      this.viewFilteredClientModal = true;
    },
    resetClientModal() {
      this.viewFilteredClientModal = false;
    },
    searchCounterparty(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingCounterparty = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchCounterparty`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optCounterParties = [];
                  this.searchedCounterparties = [];

                  this.searchedCounterparties = res.data.resData;

                  if (res.data.resData.length > 0) {
                    res.data.resData.forEach((data) => {
                      this.optCounterParties.push(data.AccountName);
                    });
                  }

                  this.loadingCounterparty = false;
                } else {
                  this.notFoundMessage("Counterparty");
                  this.loadingCounterparty = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedCounterparty(search) {
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.cptyAccount = selecting.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
              let listTraders = []
              res.data.resTrader.forEach(element => {
                if(!listTraders.includes(element.Trader)){
                  listTraders.push(element.Trader)

                }

                
              });
              this.optCounterPartyTraders = listTraders
            }
          })
          .catch((error) => reject(error));
      });
    },
    selectedCounterpartyFromTable(cpty){
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      this.tradeForm.counterparty = cpty.AccountName
      this.tradeForm.cptyAccount = cpty.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: cpty.AccountName,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
            }
              this.viewFilteredCounterpartyModal = false;
          })
          .catch((error) => reject(error));
      });

    },
    seeFilteredCounterpartyModal() {
      this.viewFilteredCounterpartyModal = true;
    },
    resetCounterpartyModal() {
      this.viewFilteredCounterpartyModal = false;
    },
    changedBuySell(type) {
      this.arrangeBuySell();
      this.arrangeRatesAndAmounts();
    },
    arrangeBuySell() {
      if (this.tradeForm.buySell == "Buy") {
        this.tradeForm.cptyBuySell1 = "Buys";
        this.tradeForm.cptyBuySell2 = "Sells";
        this.tradeForm.clientBuySell1 = "Buys";
        this.tradeForm.clientBuySell2 = "Sells";
        this.tradeForm.swapClientBuySell1 = "Sells";
        this.tradeForm.swapClientBuySell2 = "Buys";
        this.tradeForm.swapCptyBuySell1 = "Sells";
        this.tradeForm.swapCptyBuySell2 = "Buys";
      } else if (this.tradeForm.buySell == "Sell") {
        this.tradeForm.cptyBuySell1 = "Sells";
        this.tradeForm.cptyBuySell2 = "Buys";
        this.tradeForm.clientBuySell1 = "Sells";
        this.tradeForm.clientBuySell2 = "Buys";
        this.tradeForm.swapClientBuySell1 = "Buys";
        this.tradeForm.swapClientBuySell2 = "Sells";
        this.tradeForm.swapCptyBuySell1 = "Buys";
        this.tradeForm.swapCptyBuySell2 = "Sells";
      }
    },
    selectedProductType(type) {
      if (type == "SPOT" && this.tradeForm.counterpartyRate != null) {
        this.convertNumberInputFormat()
        this.tradeForm.spotRef = this.tradeForm.counterpartyRate;
        this.arrangeRatesAndAmounts();
      }
    },
    selectedSettlementType(type) {
      this.arrangeCurrencies();
      if (type == "PHYSICAL") {
        this.tradeForm.cutOffTime = null;
      }
    },
    changedCounterpartyRate(type) {
      this.convertNumberInputFormat()
      if (
        this.tradeForm.productType == "SPOT" &&
        this.tradeForm.counterpartyRate != null
      ) {
        this.tradeForm.spotRef = this.tradeForm.counterpartyRate;
      }

      if (this.tradeForm.productType == "SWAP") {
        this.tradeForm.enableConnectedOCOOrders = false;
      }

      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedCptyCalculateFromRate1() {
      this.convertNumberInputFormat()
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },

    selectedCptyCcy1() {
      this.tradeForm.clientCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.deliverableCcy = this.tradeForm.cptyCcy1;
    },
    selectedCptyCcy2() {
      this.tradeForm.clientCcy2 = this.tradeForm.cptyCcy2;
      this.tradeForm.deliverableCcy = this.tradeForm.cptyCcy2;
      this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
    },
    arrangeCurrencies() {
      this.tradeForm.clientCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.clientCcy2 = this.tradeForm.cptyCcy2;
      this.tradeForm.swapCptyCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.swapCptyCcy2 = this.tradeForm.cptyCcy2;
      this.tradeForm.swapClientCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.swapClientCcy2 = this.tradeForm.cptyCcy2;
    },
    changedCalcFromSpread(type) {
      this.tradeForm.calcFromSpread = type;
      if (type == "Calculate Client Rate From Spread") {
        this.tradeForm.clientCalculateFromRate2 = true;
      }
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedSpread(spread) {
      this.convertNumberInputFormat()
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedClientRate(type) {
      this.convertNumberInputFormat()
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedClientCalculateFromRate2() {
      this.convertNumberInputFormat()
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    calculateAsCptyAmount1(amount) {
      this.convertNumberInputFormat()
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      }

      if (this.tradeForm.cptyCalculateFromRate1) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.cptyAmount1 != null
        ) {
          if (this.tradeForm.cptyAmount2 > 0) {
            if (
              this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate !=
              this.tradeForm.cptyAmount2
            ) {
              if (
                this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1 &&
                this.tradeForm.cptyAmount2 != null
              ) {
                this.tradeForm.cptyAmount2 = this.roundToTwo(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(2);
              } else if (
                this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
              ) {
                this.tradeForm.cptyAmount1 = this.roundToTwo(
                  this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                ).toFixed(2);
              } else {
                this.tradeForm.cptyAmount2 = this.roundToTwo(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(2);
              }
            }
          } else {
            this.tradeForm.cptyAmount2 = this.roundToTwo(
              this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
            );
          }
        }
      } else {
        if (
          this.tradeForm.cptyAmount1 != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          if (this.tradeForm.counterpartyRate > 0) {
            if (
              this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1 !=
              this.tradeForm.counterpartyRate
            ) {
              this.tradeForm.counterpartyRate =
                this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1;
            } else {
              this.tradeForm.counterpartyRate =
                this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1;
            }
          }
        }
      }
      this.arrangeRatesAndAmounts();
      this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
      this.arrangeInputFormats();
    },
    calculateAsCptyAmount2(amount) {
      this.convertNumberInputFormat()
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.clientAmount2 = this.tradeForm.cptyAmount2;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      }

      if (this.tradeForm.cptyCalculateFromRate1) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          if (
            this.tradeForm.cptyAmount1 > 0 &&
            this.tradeForm.cptyAmount2 > 0
          ) {
            if (
              this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate !=
              this.tradeForm.cptyAmount1
            ) {
              this.tradeForm.cptyAmount1 = this.roundToTwo(
                this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
              );
            }
          } else {
            if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
              this.tradeForm.cptyAmount1 = this.roundToTwo(
                this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
              );
            } else if (
              this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1 &&
              this.tradeForm.cptyAmount1 != null
            ) {
              this.tradeForm.cptyAmount2 = this.roundToTwo(
                this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
              );
            } else {
              this.tradeForm.cptyAmount1 = this.roundToTwo(
                this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
              );
            }
          }
        }
      } else {
        if (
          this.tradeForm.cptyAmount1 != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          if (this.tradeForm.counterpartyRate > 0) {
            if (
              this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1 !=
              this.tradeForm.counterpartyRate
            ) {
              this.tradeForm.counterpartyRate =
                this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1;
            } else {
              this.tradeForm.counterpartyRate =
                this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1;
            }
          }
        }
      }
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    calculateAsClientAmount1() {
      this.convertNumberInputFormat()
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.cptyAmount1 = thsi.tradeForm.clientAmount1;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      }

      if (this.tradeForm.clientAmount1 > 0) {
        if (
          this.tradeForm.cptyAmount1 != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          this.tradeForm.cptyAmount1 = this.tradeForm.clientAmount1;
        }
      }

      if (this.tradeForm.clientCalculateFromRate2) {
        if (
          this.tradeForm.clientRate != null &&
          this.tradeForm.clientAmount1 != null
        ) {
          if (this.tradeForm.clientAmount2 > 0) {
            if (
              this.roundToTwo(
                this.tradeForm.clientAmount1 * this.tradeForm.clientRate
              ).toFixed(2) != this.tradeForm.clientAmount2 &&
              this.roundToTwo(
                (
                  this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                ).toFixed(2)
              ) -
                this.tradeForm.clientAmount2 >
                0.01
            ) {
              if (this.tradeForm.clientAmount1 == this.tradeForm.cptyAmount1) {
                this.tradeForm.clientAmount2 = this.roundToTwo(
                  this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                ).toFixed(2);
              } else if (
                this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
              ) {
                this.tradeForm.clientAmount1 = this.roundToTwo(
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(2);
              } else {
                this.tradeForm.clientAmount2 = this.tradeForm
                  .roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  )
                  .toFixed(2);
              }
            }
          } else {
            this.tradeForm.clientAmount2 = this.roundToTwo(
              this.tradeForm.clientAmount1 * this.tradeForm.clientRate
            ).toFixed(2);
          }
        }
      } else {
        if (
          this.tradeForm.clientAmount1 != null &&
          this.tradeForm.clientAmount2 != null
        ) {
          if (this.tradeForm.clientRate > 0) {
            if (
              this.roundToTwo(
                this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
              ).toFixed(8) != this.tradeForm.clientRate &&
              this.roundToTwo(
                (
                  this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
                ).toFixed(8)
              ) -
                this.tradeForm.clientRate >
                0.01
            ) {
              this.tradeForm.clientRate = this.roundToTwo(
                this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
              ).toFixed(8);
            }
          } else {
            this.tradeForm.clientRate = this.roundToTwo(
              this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
            ).toFixed(8);
          }
        }
      }

      this.arrangeRatesAndAmounts();

      if (this.tradedAmountsConsistent()) {
        this.wrongToastMessage(
          $t("fx_spot_forward_swap.messages.traded_amount_consistent"),
          8000
        );
        this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
        this.calculateAsCptyAmount1();
      }

      this.arrangeInputFormats();
    },
    calculateAsClientAmount2() {
      this.convertNumberInputFormat()
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.cptyAmount1 = thsi.tradeForm.clientAmount1;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      }

      if (this.tradeForm.clientCalculateFromRate2) {
        if (
          this.tradeForm.clientRate != null &&
          this.tradeForm.clientAmount2 != null
        ) {
          if (
            this.tradeForm.clientAmount1 > 0 &&
            this.tradeForm.clientAmount2 > 0
          ) {
            if (
              this.roundToTwo(
                this.tradeForm.clientAmount2 / this.tradeForm.clientRate
              ).toFixed(2) != this.tradeForm.clientAmount1 &&
              this.roundToTwo(
                (
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(2)
              ) -
                this.tradeForm.clientAmount1 >
                0.01
            ) {
              if (this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2) {
                this.tradeForm.clientAmount1 = this.roundToTwo(
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(2);
              } else if (
                this.tradeForm.clientAmount1 == this.tradeForm.cptyAmount1
              ) {
                this.tradeForm.clientAmount1 = this.roundToTwo(
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(2);
              } else {
                this.tradeForm.clientAmount1 = this.tradeForm
                  .roundToTwo(
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                  )
                  .toFixed(2);
              }
            }
          } else {
            this.tradeForm.clientAmount1 = this.roundToTwo(
              this.tradeForm.clientAmount2 * this.tradeForm.clientRate
            ).toFixed(2);
          }
        }
      } else {
        if (
          this.tradeForm.clientAmount1 != null &&
          this.tradeForm.clientAmount2 != null
        ) {
          if (this.tradeForm.clientRate > 0) {
            if (
              this.roundToTwo(
                this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
              ).toFixed(8) != this.tradeForm.clientRate &&
              this.roundToTwo(
                (
                  this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
                ).toFixed(8)
              ) -
                this.tradeForm.clientRate >
                0.01
            ) {
              this.tradeForm.clientRate = this.roundToTwo(
                this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
              ).toFixed(8);
            }
          } else {
            this.tradeForm.clientRate = this.roundToTwo(
              this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
            ).toFixed(8);
          }
        }
      }

      this.arrangeRatesAndAmounts();

      if (this.tradedAmountsConsistent()) {
        this.wrongToastMessage(
          $t("fx_spot_forward_swap.messages.traded_amount_consistent"),
          8000
        );
        this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
        this.calculateAsCptyAmount1();
      }

      this.arrangeInputFormats();
    },
    changeSpotRef(){
      this.arrangeInputFormats();
    },
    changeSpreadAmount(){
      this.arrangeInputFormats();
    },
    changeComissionAmount() {
      this.arrangeInputFormats();
    },
    selectedSpreadCcy() {
      this.convertNumberInputFormat()
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },

    tradedAmountsConsistent() {
      if (
        this.tradeForm.clientAmount1 != null &&
        this.tradeForm.clientAmount2
      ) {
        if (
          this.tradeForm.cptyAmount1 != this.tradeForm.clientAmount1 &&
          this.tradeForm.cptyAmount2 != this.tradeForm.clientAmount2
        ) {
          return true;
        }
      }
    },

    changedSwapPoints() {
      this.convertNumberInputFormat()
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedSwapSpread() {
      this.convertNumberInputFormat()
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedSwapClientRate() {
      this.convertNumberInputFormat()
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    setSwapTradetoSettleDays(day) {
      if (this.tradeForm.swapTradeDate != null) {
        this.getHolidays().then((holidays) => {
          if (holidays.length > 0) {
            let tradeDate = new Date(
              this.MDYdateFormat(this.tradeForm.swapTradeDate)
            );

            //set settle date name
            this.tradeForm.swapTradeDateName = this.weekday[
            tradeDate.getDay()
            ];

            let period = this.tradeForm.maturity;
            tradeDate.setDate(tradeDate.getDate() + period);

            //check holiday
            this.checkHolidaysDate(holidays, tradeDate);
            //found settlement date name
            let swapSettleDateName = this.weekday[
              this.checkedHolidayDate.getDay()
            ];

            //check weekend settlement date
            if (swapSettleDateName == "Saturday") {
              this.checkedHolidayDate.setDate(
                this.checkedHolidayDate.getDate() + 2
              );
            } else if (swapSettleDateName == "Sunday") {
              this.checkedHolidayDate.setDate(
                this.checkedHolidayDate.getDate() + 1
              );
            }

            //again check holiday
            this.checkHolidaysDate(holidays, tradeDate);
            
            // set trade date & name
            this.tradeForm.swapSettleDate = this.dateFormat(
              this.checkedHolidayDate
            );
            this.tradeForm.swapSettleDateName = this.weekday[
              this.checkedHolidayDate.getDay()
            ];
          } else {
            let tradeDate = new Date(
              this.MDYdateFormat(this.tradeForm.swapTradeDate)
            );

            //set settle date name
            this.tradeForm.swapTradeDateName = this.weekday[
            tradeDate.getDay()
            ];

            let period = this.tradeForm.maturity;

            tradeDate.setDate(tradeDate.getDate() + period);

            //found settlement date name
            let tradeDateName = this.weekday[tradeDate.getDay()];

            //check weekend settlement date
            if (tradeDateName == "Saturday") {
              tradeDate.setDate(tradeDate.getDate() + 2);
            } else if (tradeDateName == "Sunday") {
              tradeDate.setDate(tradeDate.getDate() + 1);
            }

            // set swap trade date
            this.tradeForm.swapSettleDate = this.dateFormat(tradeDate);
            this.tradeForm.swapSettleDateName = this.weekday[
            tradeDate.getDay()
            ];
          }
        });

        // let settleDate = new Date(
        //   this.MDYdateFormat(this.tradeForm.swapSettleDate)
        // );

        // //set settle date name
        // this.tradeForm.swapSettleDateName = this.weekday[settleDate.getDay()];

        // let period = this.tradeForm.maturity;

        // settleDate.setDate(settleDate.getDate() + period);
        // //set trade date name
        // this.tradeForm.swapTradeDateName = this.weekday[settleDate.getDay()];

        // this.tradeForm.swapTradeDate = settleDate;
      }
    },

    arrangeSwapTradetoSettleDays(type) {
      if (type == "Swap Trade Date") {
        if (
          this.tradeForm.swapTradeDate != null &&
          this.tradeForm.maturity != null
        ) {
        } else if (
          this.tradeForm.swapTradeDate != null &&
          this.tradeForm.swapSettleDate != null
        ) {
        }
      } else if (type == "Swap Settle Date") {
        // if (this.tradeForm.maturity != null && this.tradeForm.maturity > 0) {
        //   this.getHolidays().then((holidays) => {
        //     if (holidays.length > 0) {
        //       let settleDate = new Date(
        //         this.MDYdateFormat(this.tradeForm.swapSettleDate)
        //       );

        //       //set settle date name
        //       this.tradeForm.swapSettleDateName = this.weekday[
        //         settleDate.getDay()
        //       ];

        //       let period = this.tradeForm.maturity;
        //       settleDate.setDate(settleDate.getDate() + period);

        //       //check holiday
        //       this.checkHolidaysDate(holidays, settleDate);
        //       //found settlement date name
        //       let swapTradeDateName = this.weekday[
        //         this.checkedHolidayDate.getDay()
        //       ];

        //       //check weekend settlement date
        //       if (swapTradeDateName == "Saturday") {
        //         this.checkedHolidayDate.setDate(
        //           this.checkedHolidayDate.getDate() + 2
        //         );
        //       } else if (swapTradeDateName == "Sunday") {
        //         this.checkedHolidayDate.setDate(
        //           this.checkedHolidayDate.getDate() + 1
        //         );
        //       }

        //       //again check holiday
        //       this.checkHolidaysDate(holidays, settleDate);

        //       // set trade date & name
        //       this.tradeForm.swapTradeDate = this.dateFormat(
        //         this.checkedHolidayDate
        //       );
        //       this.tradeForm.swapTradeDateName = this.weekday[
        //         this.checkedHolidayDate.getDay()
        //       ];
        //     } else {
        //       let settleDate = new Date(
        //         this.MDYdateFormat(this.tradeForm.swapSettleDate)
        //       );

        //       //set settle date name
        //       this.tradeForm.swapSettleDateName = this.weekday[
        //         settleDate.getDay()
        //       ];

        //       let period = this.tradeForm.maturity;

        //       settleDate.setDate(settleDate.getDate() + period);

        //       //found settlement date name
        //       let settlementDateName = this.weekday[settleDate.getDay()];

        //       //check weekend settlement date
        //       if (settlementDateName == "Saturday") {
        //         settleDate.setDate(settleDate.getDate() + 2);
        //       } else if (settlementDateName == "Sunday") {
        //         settleDate.setDate(settleDate.getDate() + 1);
        //       }

        //       // set swap trade date
        //       this.tradeForm.swapTradeDate = this.dateFormat(settleDate);
        //       this.tradeForm.swapTradeDateName = this.weekday[
        //         settleDate.getDay()
        //       ];
        //     }
        //   });

        //   // let settleDate = new Date(
        //   //   this.MDYdateFormat(this.tradeForm.swapSettleDate)
        //   // );

        //   // //set settle date name
        //   // this.tradeForm.swapSettleDateName = this.weekday[settleDate.getDay()];

        //   // let period = this.tradeForm.maturity;

        //   // settleDate.setDate(settleDate.getDate() + period);
        //   // //set settle date name
        //   // this.tradeForm.swapTradeDateName = this.weekday[settleDate.getDay()];

        //   // this.tradeForm.swapTradeDate = settleDate;
        // }
      }
    },

    changeSwapCalcFromSpread(){
      this.convertNumberInputFormat()
      this.arrangeRatesAndAmounts()
    },

    arrangeRatesAndAmounts() {
      this.convertNumberInputFormat()
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      }

      if (this.tradeForm.cptyCalculateFromRate1) {
        if (this.tradeForm.counterpartyRate != null) {
          if (this.tradeForm.cptyAmount1 != null) {
            if (this.tradeForm.cptyAmount2 > 0) {
              if (
                this.roundToTwo(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(2) != this.tradeForm.cptyAmount2 &&
                Math.abs(
                  this.roundToTwo(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(2) - this.tradeForm.cptyAmount2
                ) > 0.01
              ) {
                if (
                  this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1
                ) {
                  this.tradeForm.cptyAmount2 = this.roundToTwo(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(2);
                } else if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.tradeForm.cptyAmount1 = this.roundToTwo(
                    this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.cptyAmount2 = this.roundToTwo(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(2);
                }
              } else {
                this.tradeForm.cptyAmount2 = this.roundToTwo(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(2);
              }
            }
          } else if (this.tradeForm.cptyAmount2 > 0) {
            if (this.tradeForm.cptyAmount1 > 0) {
              if (
                this.roundToTwo(
                  this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                ).toFixed(2) != this.tradeForm.cptyAmount1 &&
                Math.abs(
                  this.roundToTwo(
                    this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                  ).toFixed(2) - this.tradeForm.cptyAmount1
                ) > 0.01
              ) {
                if (
                  this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2
                ) {
                  this.tradeForm.cptyAmount1 =
                    this.tradeForm.cptyAmount2 /
                    this.tradeForm.counterpartyRate;
                } else if (
                  this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1
                ) {
                  this.tradeForm.cptyAmount2 = this.roundToTwo(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.cptyAmount1 =
                    this.tradeForm.cptyAmount2 /
                    this.tradeForm.counterpartyRate;
                }
              }
            } else {
              this.tradeForm.cptyAmount1 = this.roundToTwo(
                this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
              ).toFixed(2);
            }
          }
        }
      } else {
        if (
          this.tradeForm.cptyAmount1 != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          if (
            this.roundToPointFour(
              this.tradeForm.cptyAmount1 / this.tradeForm.cptyAmount2
            ).toFixed(8) != this.tradeForm.counterpartyRate
          ) {
            this.tradeForm.counterpartyRate = this.roundToPointFour(
              this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1
            ).toFixed(8);
          }
        }
      }

      if (
        this.tradeForm.calcFromSpread == "Calculate Spread From Client Rate"
      ) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.clientRate != null
        ) {
          if (this.tradeForm.cptyBuySell1 == "Buys") {
            this.tradeForm.spread =
              (this.tradeForm.clientRate -
              this.tradeForm.counterpartyRate) / 10000;
          } else if (this.tradeForm.cptyBuySell1 == "Sells") {
            this.tradeForm.spread =
              (this.tradeForm.counterpartyRate -
              this.tradeForm.clientRate) / 10000;
          }
        }
      } else if (
        this.tradeForm.calcFromSpread == "Calculate Client Rate From Spread"
      ) {
        if (this.tradeForm.spread != null) {
          if (this.tradeForm.counterpartyRate != null) {
            if (this.tradeForm.cptyBuySell1 == "Buys") {
              this.tradeForm.clientRate =
                this.tradeForm.counterpartyRate + this.tradeForm.spread / 10000;

            } else if (this.tradeForm.cptyBuySell1 == "Sells") {
              this.tradeForm.clientRate =
                this.tradeForm.counterpartyRate - this.tradeForm.spread / 10000;
            }
          } else if (this.tradeForm.clientRate != null) {
            if (this.tradeForm.cptyBuySell1 == "Buys") {
              this.tradeForm.counterpartyRate =
                this.tradeForm.clientRate - this.tradeForm.spread / 10000;
            } else if (this.tradeForm.cptyBuySell1 == "Sells") {
              this.tradeForm.counterpartyRate =
                this.tradeForm.clientRate + this.tradeForm.spread / 10000;
            }
          }
        }
      }

      if (this.tradeForm.clientCalculateFromRate2) {
        if (this.tradeForm.clientRate != null) {
          if (this.tradeForm.clientAmount1 != null) {
            if (this.tradeForm.clientAmount2 > 0) {
              if (
                this.roundToTwo(
                  this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                ).toFixed(2) != this.tradeForm.clientAmount2 &&
                Math.abs(
                  this.roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(2) - this.tradeForm.clientAmount2
                ) > 0.01
              ) {
                if (
                  this.tradeForm.clientAmount1 == this.tradeForm.cptyAmount1
                ) {
                  this.tradeForm.clientAmount2 = this.roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(2);
                } else if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.tradeForm.clientAmount1 = this.roundToTwo(
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.clientAmount2 = this.roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(2);
                }
              }
            } else {
              this.tradeForm.clientAmount2 = this.roundToTwo(
                this.tradeForm.clientAmount1 * this.tradeForm.clientRate
              ).toFixed(2);
            }
          } else if (this.tradeForm.clientAmount2 != null) {
            if (this.tradeForm.clientAmount1 > 0) {
              if (
                this.roundToTwo(
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(2) != this.tradeForm.clientAmount1 &&
                Math.abs(
                  this.roundToTwo(
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                  ).toFixed(2) - this.tradeForm.clientAmount1
                ) > 0.01
              ) {
                if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.clientAmount1 =
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate;
                } else if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.tradeForm.clientAmount2 = this.roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.clientAmount1 =
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate;
                }
              }
            } else {
              this.clientAmount1 = this.roundToTwo(
                this.tradeForm.clientAmount2 / this.tradeForm.clientRate
              ).toFixed(2);
            }
          }
        }
      } else {
        if (
          this.tradeForm.clientAmount1 != null &&
          this.tradeForm.clientAmount2 != null
        ) {
          if (
            this.roundToPointFour(
              this.tradeForm.clientAmount1 / this.tradeForm.clientAmount2
            ).toFixed(8) != this.tradeForm.clientRate
          ) {
            this.tradeForm.clientRate = this.roundToPointFour(
              this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
            ).toFixed(8);
          }
        }
      }

      if (
        this.tradeForm.calcFromSpread == "Calculate Client Rate From Spread"
      ) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.spread != null
        ) {
          if (this.tradeForm.cptyBuySell1 == "Buys") {
            this.tradeForm.counterpartyRate = this.roundToTwo(
              this.tradeForm.counterpartyRate + this.tradeForm.spread / 10000
            ).toFixed(4);
          } else if (this.tradeForm.cptyBuySell1 == "Sells") {
            this.tradeForm.spread = this.roundToTwo(
              this.tradeForm.counterpartyRate - this.tradeForm.spread / 10000
            ).toFixed(4);
          }
        }
      }

      if (
        this.tradeForm.calcFromSpread == "Calculate Spread From Client Rate"
      ) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.clientRate != null
        ) {
          if (this.tradeForm.cptyBuySell1 == "Buys") {
            this.tradeForm.spread = this.roundToTwo(
              (this.tradeForm.clientRate -
                this.tradeForm.counterpartyRate) * 10000
            ).toFixed(4)
          } else if (this.tradeForm.cptyBuySell1 == "Sells") {
            this.tradeForm.spread = this.roundToTwo(
              (this.tradeForm.counterpartyRate -
                this.tradeForm.clientRate) * 10000
            ).toFixed(4)
          }
        }
      }

      if (this.tradeForm.spreadCcy != null) {
        if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy1) {
          if (this.tradeForm.cptyAmount1 != null) {
            if (this.tradeForm.clientRate != null) {
              if (this.tradeForm.spread != null) {
                this.tradeForm.spreadAmount = this.roundToTwo(
                  (((this.tradeForm.cptyAmount1 * this.tradeForm.spread) /
                    this.tradeForm.clientRate /
                    10000) *
                    1) /
                    100
                ).toFixed(2);
              } else if (this.tradeForm.counterpartyRate != null) {
                if (this.tradeForm.cptyBuySell1 == "Buys") {
                  this.tradeForm.spreadAmount = this.roundToTwo(
                    (((this.tradeForm.cptyAmount1 *
                      (this.tradeForm.clientRate -
                        this.tradeForm.counterpartyRate)) /
                      this.tradeForm.clientRate) *
                      1) /
                      100
                  ).toFixed(2);
                } else if (this.tradeForm.cptyBuySell1 == "Sells") {
                  this.tradeForm.spreadAmount = this.roundToTwo(
                    (((this.tradeForm.cptyAmount1 *
                      (this.tradeForm.counterpartyRate -
                        this.tradeForm.clientRate)) /
                      this.tradeForm.clientRate) *
                      1) /
                      100
                  ).toFixed(2);
                }
              }
            }
          }
        } else if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy2) {
          if (this.tradeForm.cptyAmount1 != null) {
            if (this.tradeForm.spread != null) {
              this.tradeForm.spreadAmount = this.roundToTwo(
                this.tradeForm.cptyAmount1 * (this.tradeForm.spread / 10000)
              ).toFixed(2);
            }
          }
        }
      }

      if (this.tradeForm.productType == "SWAP") {
        this.tradeForm.swapCptyBuySell1 = this.tradeForm.cptyBuySell1;
        this.tradeForm.swapCptyBuySell2 = this.tradeForm.cptyBuySell2;

        this.tradeForm.swapClientBuySell1 = this.tradeForm.clientBuySell1;
        this.tradeForm.swapClientBuySell2 = this.tradeForm.clientBuySell2;

        this.tradeForm.swapSpreadCcy = this.tradeForm.spreadCcy;

        if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy2) {
          this.tradeForm.swapClientAmount1 = this.tradeForm.clientAmount1;
          this.tradeForm.swapCptyAmount1 = this.tradeForm.cptyAmount1;
        } else if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy1) {
          this.tradeForm.swapClientAmount2 = this.tradeForm.clientAmount2;
          this.tradeForm.swapCptyAmount2 = this.tradeForm.cptyAmount2;
        }

        // calculate swap cpty rate
        if (this.tradeForm.counterpartyRate != null) {
          if (this.tradeForm.swapPoints != null) {
            if (this.tradeForm.counterpartyRate != null) {
              this.tradeForm.swapCptyRate = this.formatPrice(
                this.tradeForm.counterpartyRate +
                  this.tradeForm.swapPoints / 10000, 4
              ).toFixed(4);
            } else {
              this.tradeForm.swapCptyRate = this.formatPrice(
                this.tradeForm.counterpartyRate , 4
              ).toFixed(4);
            }
          } else {
            this.tradeForm.swapCptyRate = this.formatPrice(
              this.tradeForm.counterpartyRate, 4
            ).toFixed(4);
          }
        } else {
          this.tradeForm.swapCptyRate = null;
        }

        // calculate swap client rate
        if (this.tradeForm.productType == "SWAP") {
          if (
            this.tradeForm.swapCalcFromSpread ==
            "Calculate Client Rate From Spread"
          ) {
            if (this.tradeForm.swapCptyRate != null) {
              if (this.tradeForm.swapSpread != null) {
                if (this.tradeForm.buySell == "Buy") {
                  this.tradeForm.swapClientRate = this.roundToPointFour(
                    Number.parseFloat(this.tradeForm.swapCptyRate) -
                      this.tradeForm.swapSpread / 10000
                  ).toFixed(4);
                } else {
                  
                  this.tradeForm.swapClientRate = this.roundToPointFour(
                    Number.parseFloat(this.tradeForm.swapCptyRate) +
                      this.tradeForm.swapSpread / 10000
                  ).toFixed(4);
                }
              } else {
                if (this.tradeForm.buySell == "Buy") {
                  this.tradeForm.swapClientRate = this.roundToPointFour(
                    Number.parseFloat(this.tradeForm.swapCptyRate)
                  ).toFixed(4);
                } else {
                  this.tradeForm.swapClientRate = this.roundToPointFour(
                    Number.parseFloat(this.tradeForm.swapCptyRate)
                  ).toFixed(4);
                }
              }
            }
          } else if (
            this.tradeForm.swapCalcFromSpread ==
            "Calculate Spread From Client Rate"
          ) {
            if (this.tradeForm.swapCptyRate != null) {
              if (this.tradeForm.swapClientRate != null) {
                if (this.tradeForm.buySell == "Buy") {
                  this.tradeForm.swapSpread = this.roundToTwo(
                    (Number(-this.tradeForm.swapClientRate) +
                      Number(this.tradeForm.swapCptyRate)) *
                      10000
                  ).toFixed(2);
                } else {
                  this.tradeForm.swapSpread = this.roundToTwo(
                    (Number(this.tradeForm.swapClientRate) +
                    Number(this.tradeForm.swapCptyRate)) *
                      10000
                  ).toFixed(2);
                }
              } else {
                this.tradeForm.swapSpread = null;
              }
            } else {
              this.tradeForm.swapSpread = null;
            }
          }

          if (
            this.tradeForm.swapClientAmount1 != null ||
            this.tradeForm.swapClientAmount2 != null
          ) {
            if (this.tradeForm.swapSpreadCcy == this.tradeForm.cptyCcy1) {
              if (this.tradeForm.swapClientRate != null) {
                if (
                  this.tradeForm.swapCptyAmount2 > 0 &&
                  this.tradeForm.swapCptyRate > 0
                ) {
                  this.tradeForm.swapCptyAmount1 = this.roundToTwo(
                    Number(this.tradeForm.swapCptyAmount2) / Number(this.tradeForm.swapCptyRate)
                  ).toFixed(2);
                  this.tradeForm.swapClientAmount1 = this.roundToTwo(
                    Number(this.tradeForm.swapClientAmount2) /
                    Number(this.tradeForm.swapClientRate)
                  ).toFixed(2);
                }
                if (this.tradeForm.buySell == "Buy") {
                  if (this.tradeForm.swapSpread != null) {
                    this.tradeForm.swapSpreadAmount = this.roundToTwo(
                      Number(this.tradeForm.swapClientAmount2) /
                      Number(this.tradeForm.swapClientRate) -
                      Number(this.tradeForm.swapClientAmount2) /
                      Number(this.tradeForm.swapCptyRate)
                    ).toFixed(2);
                  } else {
                    this.tradeForm.swapSpreadAmount = null;
                  }
                } else {
                  if (this.tradeForm.swapSpread != null) {
                    this.tradeForm.swapSpreadAmount = this.roundToTwo(
                      -(
                        Number(this.tradeForm.swapClientAmount2) /
                        Number(this.tradeForm.swapClientRate)
                      ) +
                      Number(this.tradeForm.swapClientAmount2) /
                      Number(this.tradeForm.swapCptyRate)
                    ).toFixed(2);
                  } else {
                    this.tradeForm.swapSpreadAmount = null;
                  }
                }
              }
            } else if (
              this.tradeForm.swapSpreadCcy == this.tradeForm.cptyCcy2
            ) {
              if (this.tradeForm.swapClientRate != null) {
                if (
                  this.tradeForm.swapCptyAmount1 > 0 &&
                  this.tradeForm.swapCptyRate != null
                ) {
                  this.tradeForm.swapCptyAmount2 = this.roundToTwo(
                    Number(this.tradeForm.swapCptyAmount1) * Number(this.tradeForm.swapCptyRate)
                  ).toFixed(2);
                  this.tradeForm.swapClientAmount2 = this.roundToTwo(
                    Number(this.tradeForm.swapClientAmount1) *
                    Number(this.tradeForm.swapClientRate)
                  ).toFixed(2);
                }
                if (this.tradeForm.buySell == "Buy") {
                  if (this.tradeForm.swapSpread != null) {
                    this.tradeForm.swapSpreadAmount = this.roundToTwo(
                      -(
                        Number(this.tradeForm.swapClientAmount1) *
                        Number(this.tradeForm.swapClientRate)
                      ) +
                      Number(this.tradeForm.swapClientAmount1) *
                      Number(this.tradeForm.swapCptyRate)
                    ).toFixed(2);
                  } else {
                    this.tradeForm.swapSpreadAmount = null;
                  }
                } else {
                  if (this.tradeForm.swapSpread != null) {
                    this.tradeForm.swapSpreadAmount = this.roundToTwo(
                      -(
                        Number(this.tradeForm.swapClientAmount1) *
                        Number(this.tradeForm.swapClientRate)
                      ) -
                      Number(this.tradeForm.swapClientAmount1) *
                      Number(this.tradeForm.swapCptyRate)
                    ).toFixed(2);
                  } else {
                    this.tradeForm.swapSpreadAmount = null;
                  }
                }
              }
            }
          }
        }
      }
    },

    convertNumberInputFormat(){
      if (this.tradeForm.counterpartyRate != null) {
        this.tradeForm.counterpartyRate = Number(this.tradeForm.counterpartyRate)
        
      }
      if (this.tradeForm.clientRate != null) {
        this.tradeForm.clientRate = Number(this.tradeForm.clientRate)

      }
      if (this.tradeForm.cptyAmount1 != null) {
        this.tradeForm.cptyAmount1 = Number(this.tradeForm.cptyAmount1)
      }
      if (this.tradeForm.cptyAmount2 != null) {
        this.tradeForm.cptyAmount2 = Number(this.tradeForm.cptyAmount2)
      }
      if (this.tradeForm.spread != null) {
        this.tradeForm.spread = Number(this.tradeForm.spread)
      }
      if (this.tradeForm.spreadAmount != null) {
        this.tradeForm.spreadAmount = Number(this.tradeForm.spreadAmount)
      }
      if (this.tradeForm.comissionAmount != null) {
        this.tradeForm.comissionAmount = Number(this.tradeForm.comissionAmount)

      }
      if (this.tradeForm.clientAmount1 != null) {
        this.tradeForm.clientAmount1 = Number(this.tradeForm.clientAmount1)
      }
      if (this.tradeForm.clientAmount2 != null) {
        this.tradeForm.clientAmount2 = Number(this.tradeForm.clientAmount2)

      }
      if (this.tradeForm.spotRef != null) {
        this.tradeForm.spotRef = Number(this.tradeForm.spotRef)
      }
      if (this.tradeForm.stopPrice != null) {
        this.tradeForm.stopPrice = Number(this.tradeForm.stopPrice)
      }
      if (this.tradeForm.trailingAmount != null) {
        this.tradeForm.trailingAmount = Number(this.tradeForm.trailingAmount)

      }
      if (this.tradeForm.takeProfitLimit != null) {
        this.tradeForm.takeProfitLimit = Number(this.tradeForm.takeProfitLimit)

      }
      if (this.tradeForm.stopLossLimit != null) {
        this.tradeForm.stopLossLimit = Number(this.tradeForm.stopLossLimit)

      }
      if (this.tradeForm.stopLossStop != null) {
        this.tradeForm.stopLossStop = Number(this.tradeForm.stopLossStop)

      }
      if (this.tradeForm.swapClientAmount1 != null) {
        this.tradeForm.swapClientAmount1 = Number(this.tradeForm.swapClientAmount1)

      }
      if (this.tradeForm.swapClientAmount2 != null) {
        this.tradeForm.swapClientAmount2 = Number(this.tradeForm.swapClientAmount2)

      }
      if (this.tradeForm.swapSpreadAmount != null) {
        this.tradeForm.swapSpreadAmount = Number(this.tradeForm.swapSpreadAmount)
      }
      if (this.tradeForm.swapSpread != null) {
        this.tradeForm.swapSpread = Number(this.tradeForm.swapSpread)
      }
      if (this.tradeForm.swapClientRate != null) {
        this.tradeForm.swapClientRate = Number(this.tradeForm.swapClientRate)
      }
      if (this.tradeForm.swapCptyRate != null) {
        this.tradeForm.swapCptyRate = Number(this.tradeForm.swapCptyRate)
      }
      if (this.tradeForm.swapCptyAmount1 != null) {
        this.tradeForm.swapCptyAmount1 = Number(this.tradeForm.swapCptyAmount1)
      }
      if (this.tradeForm.swapCptyAmount2 != null) {
        this.tradeForm.swapCptyAmount2 = Number(this.tradeForm.swapCptyAmount2)
      }
      if (this.tradeForm.swapPoints != null) {
        this.tradeForm.swapPoints = Number(this.tradeForm.swapPoints)
      }
    },

    arrangeInputFormats() {
      if (this.tradeForm.counterpartyRate != null) {
        //this.tradeForm.counterpartyRate = this.formatPrice(this.tradeForm.counterpartyRate, 4);
        this.tradeForm.counterpartyRate = Number.parseFloat(this.tradeForm.counterpartyRate).toFixed(4)

        
      }
      if (this.tradeForm.spotRef != null) {
        // this.tradeForm.spotRef = this.formatPrice(this.tradeForm.spotRef, 4);
        this.tradeForm.spotRef = Number.parseFloat(this.tradeForm.spotRef).toFixed(4)
       
        
      }
      if (this.tradeForm.clientRate != null) {
        // this.tradeForm.clientRate = this.formatPrice(this.tradeForm.clientRate, 4);
        this.tradeForm.clientRate = Number.parseFloat(this.tradeForm.clientRate).toFixed(4)

      }
      if (this.tradeForm.cptyAmount1 != null) {
        this.tradeForm.cptyAmount1 = Number.parseFloat(this.tradeForm.cptyAmount1).toFixed(2)
      }
      if (this.tradeForm.cptyAmount2 != null) {
        this.tradeForm.cptyAmount2 = Number.parseFloat(this.tradeForm.cptyAmount2).toFixed(2)
      }
      if (this.tradeForm.spread != null) {
        this.tradeForm.spread = Number.parseFloat(this.tradeForm.spread).toFixed(2)
      }
      if (this.tradeForm.spreadAmount != null) {
        this.tradeForm.spreadAmount = Number.parseFloat(this.tradeForm.spreadAmount).toFixed(2)
      }
      if (this.tradeForm.comissionAmount != null) {
        this.tradeForm.comissionAmount = Number.parseFloat(this.tradeForm.comissionAmount).toFixed(2)

      }
      if (this.tradeForm.clientAmount1 != null) {
        this.tradeForm.clientAmount1 = Number.parseFloat(this.tradeForm.clientAmount1).toFixed(2)
      }
      if (this.tradeForm.clientAmount2 != null) {
        this.tradeForm.clientAmount2 = Number.parseFloat(this.tradeForm.clientAmount2).toFixed(2)

      }
      
      if (this.tradeForm.stopPrice != null) {
        this.tradeForm.stopPrice = Number.parseFloat(this.tradeForm.stopPrice).toFixed(2)
      }
      if (this.tradeForm.trailingAmount != null) {
        this.tradeForm.trailingAmount = Number.parseFloat(this.tradeForm.trailingAmount).toFixed(2)

      }
      if (this.tradeForm.takeProfitLimit != null) {
        this.tradeForm.takeProfitLimit = Number.parseFloat(this.tradeForm.takeProfitLimit).toFixed(2)

      }
      if (this.tradeForm.stopLossLimit != null) {
        this.tradeForm.stopLossLimit = Number.parseFloat(this.tradeForm.stopLossLimit).toFixed(2)
      }
      if (this.tradeForm.stopLossStop != null) {
        this.tradeForm.stopLossStop = Number.parseFloat(this.tradeForm.stopLossStop).toFixed(2)
      }
      if (this.tradeForm.swapClientAmount1 != null) {
        this.tradeForm.swapClientAmount1 = Number.parseFloat(this.tradeForm.swapClientAmount1).toFixed(2)

      }
      if (this.tradeForm.swapClientAmount2 != null) {
        this.tradeForm.swapClientAmount2 = Number.parseFloat(this.tradeForm.swapClientAmount2).toFixed(2)

      }
      if (this.tradeForm.swapSpreadAmount != null) {
        this.tradeForm.swapSpreadAmount = Number.parseFloat(this.tradeForm.swapSpreadAmount).toFixed(2)

      }
      if (this.tradeForm.swapSpread != null) {
        this.tradeForm.swapSpread = Number.parseFloat(this.tradeForm.swapSpread).toFixed(2)

      }
      if (this.tradeForm.swapClientRate != null) {
        this.tradeForm.swapClientRate = Number.parseFloat(this.tradeForm.swapClientRate).toFixed(4)
      }
      if (this.tradeForm.swapCptyRate != null) {
        this.tradeForm.swapCptyRate = Number.parseFloat(this.tradeForm.swapCptyRate).toFixed(4)

      }
      if (this.tradeForm.swapCptyAmount1 != null) {
        this.tradeForm.swapCptyAmount1 = Number.parseFloat(this.tradeForm.swapCptyAmount1).toFixed(2)

      }
      if (this.tradeForm.swapCptyAmount2 != null) {
        this.tradeForm.swapCptyAmount2 = Number.parseFloat(this.tradeForm.swapCptyAmount2).toFixed(2)

      }
      if (this.tradeForm.swapPoints != null) {
        this.tradeForm.swapPoints = Number.parseFloat(this.tradeForm.swapPoints).toFixed(2)

      }
    },
    checkEnableOCOInputs(input) {
      if (input == "takeProfitLimit") {
        if (this.tradeForm.takeProfitLimit > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.stopLossStop > 0) {
              if (
                this.tradeForm.takeProfitLimit < this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.take_profit_lower_stop_price"
                  )
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
            if (
              this.tradeForm.takeProfitLimit > 0 &&
              this.tradeForm.stopLossLimit > 0
            ) {
              if (
                this.tradeForm.takeProfitLimit < this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.take_profit_lower_limit_price"
                  )
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
          } else if (this.tradeForm.buySell == "Sell") {
            if (this.tradeForm.stopLossStop > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.take_profit_greater_stop_price"
                  )
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
            if (
              this.tradeForm.takeProfitLimit > 0 &&
              this.tradeForm.stopLossLimit > 0
            ) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.take_profit_greater_limit_price"
                  )
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t(
              "fx_spot_forward_swap.messages.greater_then_zero_take_profit"
            )
          );
        }
      } else if (input == "stopLossStop") {
        if (this.tradeForm.stopLossStop > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.stopLossStop > this.tradeForm.takeProfitLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.stop_loss_greater_profit_price"
                  )
                );
                this.tradeForm.stopLossStop = null;
              }
            }
          } else if (this.tradeForm.buySell == "Sell") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.stop_loss_lower_profit_price"
                  )
                );
                this.tradeForm.stopLossStop = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t("fx_spot_forward_swap.messages.greater_then_zero_stop_loss")
          );
        }
      } else if (input == "stopLossLimit") {
        if (this.tradeForm.stopLossLimit > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.stopLossLimit > this.tradeForm.takeProfitLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.stop_loss_limit_greater_profit_price"
                  )
                );
                this.tradeForm.stopLossLimit = null;
              }
            }
          } else if (this.tradeForm.buySell == "Sell") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.stop_loss_limit_lower_profit_price"
                  )
                );
                this.tradeForm.stopLossLimit = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t(
              "fx_spot_forward_swap.messages.greater_then_zero_stop_loss_limit"
            )
          );
        }
      }
    },



    getHolidays() {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, this.user)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => reject(error));
      });
    },

    checkHolidaysDate(holidays, tradeDate) {
      let checkDate = tradeDate;
      let status = true;
      holidays.forEach((h) => {
        if (this.dateFormat(h.Date) == this.dateFormat(tradeDate)) {
          checkDate.setDate(checkDate.getDate() + 1);
          status = false;
          this.checkHolidaysDate(holidays, checkDate);
        }
      });
      if (status) {
        this.checkedHolidayDate = checkDate;
      }
    },

    arrangeTPlus() {
      if (
        this.tradeForm.tradeDate != null &&
        this.tradeForm.tradeDateName != null
      ) {
        if (this.tradeForm.Tplus > 0) {
          this.getHolidays().then((holidays) => {
            if (holidays.length > 0) {
              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.Tplus;
              tradeDate.setDate(tradeDate.getDate() + period);

              this.checkHolidaysDate(holidays, tradeDate);

              //found settlement date name
              let settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 2
                );
              } else if (settlementDateName == "Sunday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 1
                );
              }

              //again check holiday
              this.checkHolidaysDate(holidays, this.checkedHolidayDate);

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(
                this.checkedHolidayDate
              );
              this.tradeForm.settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];
            } else {
              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.Tplus;

              tradeDate.setDate(tradeDate.getDate() + period);

              //found settlement date name
              let settlementDateName = this.weekday[tradeDate.getDay()];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                tradeDate.setDate(tradeDate.getDate() + 2);
              } else if (settlementDateName == "Sunday") {
                tradeDate.setDate(tradeDate.getDate() + 1);
              }

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(tradeDate);
              this.tradeForm.settlementDateName = this.weekday[
                tradeDate.getDay()
              ];
            }
          });
        }
      }
    },


    checkTraSettlCptyDate(type) {
      if (type == "Trade Date") {
        let tradeDate = new Date(this.MDYdateFormat(this.tradeForm.tradeDate));

        //set trade date name
        this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

        //arrange as T+
        if (this.tradeForm.Tplus > 0) {
          this.arrangeTPlus();
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_after_settlement_date"
            )
          );
          this.tradeForm.tradeDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_before_order_date"
            )
          );
          this.tradeForm.tradeDate = null;
        }
        if (this.tradeForm.productType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t(
                "fx_spot_forward_swap.messages.trade_date_before_counterparty_order_date"
              )
            );
            this.tradeForm.tradeDate = null;
          }
        }
      } else if (type == "Settlement Date") {
        let settleDate = new Date(
          this.MDYdateFormat(this.tradeForm.settlementDate)
        );

        //set settle date name
        this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_after_settlement_date"
            )
          );
          this.tradeForm.settlementDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_after_settlement_date"
            )
          );
          this.tradeForm.settlementDate = null;
        }
        if (this.tradeForm.productType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t(
                "fx_spot_forward_swap.messages.settlement_date_before_counterparty_order_date"
              )
            );
            this.tradeForm.settlementDate = null;
          }
        }
      } else if ("Cpty Order Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.settlement_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }
      }
    },
    checkTime(type) {
      if (type == "Order Time") {

        //set time
        if(this.tradeForm.orderTime != null && this.tradeForm.orderTime.length < 8){
    
          for(let i= this.tradeForm.orderTime.length; i < 8; i++){
            if(i == 2 ){
              this.tradeForm.orderTime += ":"
            } else if(i== 5){
              this.tradeForm.orderTime += ":"
            } else {
              this.tradeForm.orderTime += "0"
            }
          }
        }


        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.order_time_later_execution_time"
              )
            );
            this.tradeForm.orderTime = null;
          }
        }

        this.checkOrdersTime("Order Time");
      } else if ("Execution Time") {

        //set time
        if(this.tradeForm.executionTime != null && this.tradeForm.executionTime.length < 8){
    
          for(let i= this.tradeForm.executionTime.length; i < 8; i++){
            if(i == 2 ){
              this.tradeForm.executionTime += ":"
            } else if(i== 5){
              this.tradeForm.executionTime += ":"
            } else {
              this.tradeForm.executionTime += "0"
            }
          }
        }

        


        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.execution_time_later_order_time"
              )
            );
            this.tradeForm.executionTime = null;
          }
        }

        //set time
        if(this.tradeForm.cptyOrderTime != null && this.tradeForm.cptyOrderTime.length < 8){
    
          for(let i= this.tradeForm.cptyOrderTime.length; i < 8; i++){
            if(i == 2 ){
              this.tradeForm.cptyOrderTime += ":"
            } else if(i== 5){
              this.tradeForm.cptyOrderTime += ":"
            } else {
              this.tradeForm.cptyOrderTime += "0"
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          if (this.tradeForm.cptyOrderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.execution_time_later_counterparty_order_time"
              )
            );
            this.tradeForm.executionTime = null;
          }
        }
      }
    },
    checkOrdersTime(type) {
      if (type == "Order Duration Time") {

        //set time
        if(this.tradeForm.orderDurationTime != null && this.tradeForm.orderDurationTime.length < 8){
    
          for(let i= this.tradeForm.orderDurationTime.length; i < 8; i++){
            if(i == 2 ){
              this.tradeForm.orderDurationTime += ":"
            } else if(i== 5){
              this.tradeForm.orderDurationTime += ":"
            } else {
              this.tradeForm.orderDurationTime += "0"
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.order_duration_later_order_time"
              )
            );
            this.tradeForm.orderDurationTime = null;
          }
        }
      } else if (type == "Order Time") {
        
         //set time
        if(this.tradeForm.orderTime != null && this.tradeForm.orderTime.length < 8){
    
        for(let i= this.tradeForm.orderTime.length; i < 8; i++){
          if(i == 2 ){
            this.tradeForm.orderTime += ":"
          } else if(i== 5){
            this.tradeForm.orderTime += ":"
          } else {
            this.tradeForm.orderTime += "0"
          }
        }
      }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.order_time_less_order_duration_time"
              )
            );
            this.tradeForm.orderTime = null;
          }
        }
      }
    },
    checkOrdersDate(type) {
      if (type == "Order Date") {
        if (this.tradeForm.timeInForce == "DAY") {
          this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.order_date_after_order_duration_date"
            )
          );
          this.tradeForm.orderDate = null;
        }
      } else if (type == "Order Duration Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.order_duration_less_order_date"
            )
          );
          this.tradeForm.orderDurationDate = null;
        }
      }
    },
    changeTimeInForceType() {
      if (this.tradeForm.timeInForce == "DAY") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = true;
        this.orderDurationTimeDisabled = true;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "GTC") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "GTD") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = false;
        this.orderDurationTimeDisabled = false;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "FOK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "FAK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }
    },
    resetTradeModal() {
      this.copyTradeModal = false;
      this.fetchedTradeData = {};
      this.oldTradeFormData = {};
      this.actionsData = [];
      // this.searchedCounterparties = []
      // this.searchedClients = []
      this.checkVerify = false;
      this.checkedHolidayDate = null;
      ////
      this.tradeModalLoading = false;

      this.tradeForm = {
        costCenter:null,
        orderDurationDate: null,
        orderDurationTime: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderTime: null,
        cptyOrderDate: null,
        cptyOrderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        productType: null,
        settlementType: null,
        counterpartyRate: null,
        cptyCalculateFromRate1: true,
        clientRate: null,
        clientCalculateFromRate2: true,
        cptyAmount1: null,
        cptyAmount2: null,
        cptyCcy1: null,
        cptyCcy2: null,
        cptyBuySell1: null,
        cptyBuySell2: null,
        calcFromSpread: "Calculate Spread From Client Rate",
        spread: null,
        clientAmount1: null,
        clientAmount2: null,
        clientCcy1: null,
        clientCcy2: null,
        clientBuySell1: null,
        clientBuySell2: null,
        spotRef: null,
        spreadAmount: null,
        spreadCcy: null,
        comissionAmount: null,
        comissionCcy: null,
        cutOffTime: null,
        deliverableCcy: null,
        tradeDate: null,
        tradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        Tplus: null,
        executionTime: null,
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        swapTradeDate: null,
        swapTradeDateName: null,
        maturity: null,
        swapSettleDate: null,
        swapSettleDateName: null,
        swapPoints: null,
        swapCptyRate: null,
        swapCptyAmount1: null,
        swapCptyCcy1: null,
        swapCptyBuySell1: null,
        swapCptyAmount2: null,
        swapCptyCcyType2: null,
        swapCptyBuySell2: null,
        swapSpread: null,
        swapCalcFromSpread: "Calculate Client Rate From Spread",
        swapClientRate: null,
        swapClientAmount1: null,
        swapClientCcy1: null,
        swapClientBuySell1: null,
        swapClientAmount2: null,
        swapClientCcy2: null,
        swapClientBuySell2: null,
        swapSpreadAmount: null,
        swapSpreadCcy: null,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        entryType2: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      };

      // this.optClients = [];
      this.optPriceTypes = [];
      // this.optCounterParties = [];
      this.optCounterPartyTraders = [];
      this.optScfUsers = [];
      this.optOrderTakenVias = [];
      this.optOrderGivenThroughs = [];
    },

    async tradeEnterReasonText() {
      const self = this;
      const { value: reason } = await this.$swal({
        title: self.$t("fx_spot_forward_swap.reason.amendment_reason"),
        text: self.$t(
          "fx_spot_forward_swap.reason.please_enter_amendment_reason"
        ),
        input: "textarea",
        inputPlaceholder: self.$t(
          "fx_spot_forward_swap.reason.please_enter_amendment_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("fx_spot_forward_swap.reason.submit"),
        cancelButtonText: self.$t("fx_spot_forward_swap.reason.Cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              self.tradeForm.amendmentReason = reason;
              resolve();
            } else {
              resolve(
                self.$t("fx_spot_forward_swap.reason.you_need_write_reason")
              );
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      });

      if (reason) {
        return new Promise(function(resolve, reject) {
          resolve(true);
        });
      }
    },
    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    exitAmendOperationMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_spot_forward_swap.messages.trade_amend_cancelled"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    validReasonTextMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_spot_forward_swap.reason.enter_valid_reason"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    greaterThanZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `${val} must be greater than 0`,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedEnableOCOInputsMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("fx_spot_forward_swap.messages.required_fill_inputs") +
              ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    checkJournalCreated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("fxSFS/checkTCUtiJournalCreated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(
          this.$t("fx_spot_forward_swap.messages.tc_uti_not_found")
        );
      }
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t(
            "fx_spot_forward_swap.toast_messages.database_connection_error"
          ),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t(
            "fx_spot_forward_swap.general_messages.not_authorized_message"
          ),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    noChangesMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_spot_forward_swap.messages.not_changes"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkedTraSettlDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalCreatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkTimeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkAmendEntryTypeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    copiedTradeMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t('fx_spot_forward_swap.toast_messages.trade_copy_success'),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, exp) {
      if(value == undefined || value == null) return Math.round(0)
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },

    roundToTwo(val) {
      if (val != undefined && val != null) {
        return Math.round(val * 100) / 100;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },

    roundToPointFour(val) {
      if (val != undefined && val > 0) {
        return Math.round(val * 10000) / 10000;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },
    setTradeData() {
      const self = this;
      if (
        this.tradeForm.timeInForce == "GTC" ||
        this.tradeForm.timeInForce == "FOK" ||
        this.tradeForm.timeInForce == "FAK"
      ) {
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }

      let setTrade = {
        costCenter : this.tradeForm.costCenter,
        client: this.tradeForm.client != null ? this.tradeForm.client : null,
        clientAcc:
          this.tradeForm.clientAccount != null
            ? this.tradeForm.clientAccount
            : null,
        counterparty:
          this.tradeForm.counterparty != null
            ? this.tradeForm.counterparty
            : null,
        cptyAccount:
          this.tradeForm.cptyAccount != null
            ? this.tradeForm.cptyAccount
            : null,
        cptyRate:
          this.tradeForm.counterpartyRate != null
            ? parseFloat(this.tradeForm.counterpartyRate)
            : null,
        cptyBuySell1:
          this.tradeForm.cptyBuySell1 != null
            ? this.tradeForm.cptyBuySell1
            : null,
        cptyCcy1:
          this.tradeForm.cptyCcy1 != null ? this.tradeForm.cptyCcy1 : null,
        cptyCcy2:
          this.tradeForm.cptyCcy2 != null ? this.tradeForm.cptyCcy2 : null,
        cptyAmount1:
          this.tradeForm.cptyAmount1 != null
            ? parseFloat(this.tradeForm.cptyAmount1)
            : null,
        cptyAmount2:
          this.tradeForm.cptyAmount2 != null
            ? parseFloat(this.tradeForm.cptyAmount2)
            : null,
        spreadCcy:
          this.tradeForm.spreadCcy != null ? this.tradeForm.spreadCcy : null,
        spread:
          this.tradeForm.spread != null
            ? parseFloat(this.tradeForm.spread)
            : null,
        spreadAmount:
          this.tradeForm.spreadAmount != null
            ? parseFloat(this.tradeForm.spreadAmount)
            : null,
        comissionCcy:
          this.tradeForm.comissionCcy != null
            ? this.tradeForm.comissionCcy
            : null,
        comissionAmount:
          this.tradeForm.comissionAmount != null
            ? parseFloat(this.tradeForm.comissionAmount)
            : null,
        clientRate:
          this.tradeForm.clientRate != null
            ? parseFloat(this.tradeForm.clientRate)
            : null,
        clientAmount1:
          this.tradeForm.clientAmount1 != null
            ? parseFloat(this.tradeForm.clientAmount1)
            : null,
        clientAmount2:
          this.tradeForm.clientAmount2 != null
            ? parseFloat(this.tradeForm.clientAmount2)
            : null,
        tradeDate:
          this.tradeForm.tradeDate != null
            ? this.YMDdateFormat(this.tradeForm.tradeDate)
            : null,

        settlementDate:
          this.tradeForm.settlementDate != null
            ? this.YMDdateFormat(this.tradeForm.settlementDate)
            : null,
        orderTime:
          this.tradeForm.orderTime != null
            ? this.timeFormat(this.tradeForm.orderTime)
            : null,
        executionTime:
          this.tradeForm.executionTime != null
            ? this.timeFormat(this.tradeForm.executionTime)
            : null,

        cptyTrader:
          this.tradeForm.counterpartyTrader != null
            ? this.tradeForm.counterpartyTrader
            : null,
        tcUser: this.tradeForm.scfUser != null ? this.tradeForm.scfUser : null,
        orderTakenVia:
          this.tradeForm.orderTakenVia != null
            ? this.tradeForm.orderTakenVia
            : null,
        orderGivenThrough:
          this.tradeForm.orderGivenThrough != null
            ? this.tradeForm.orderGivenThrough
            : null,
        actingCapaticy:
          this.tradeForm.actingCapaticy != null
            ? this.tradeForm.actingCapaticy
            : null,
        traderNote: this.tradeForm.note != null ? this.tradeForm.note : null,
        savedBy: this.tradeForm.savedBy,
        saveDate: this.tradeForm.saveDate,
        saveTime: this.tradeForm.savetime ?? null,
        amendedBy:  this.user.name + ' ' + this.user.surname,
        amendmentDate: moment().format("YYYY-MM-DD"),
        amentmentTime: moment().format("HH:mm:ss"),
        amendmentReason:
          this.tradeForm.amendmentReason != null
            ? this.tradeForm.amendmentReason
            : null,
        canceled: 0,
        canceledBy: null,
        canceledDate: null,
        canceledTime: null,
        canceledReason: null,
        relatedFxOptionId: null,
        cpRef: null,
        productType:
          this.tradeForm.productType != null
            ? this.tradeForm.productType
            : null,
        settlementType:
          this.tradeForm.settlementType != null
            ? this.tradeForm.settlementType
            : null,
        spotRef:
          this.tradeForm.spotRef != null
            ? parseFloat(this.tradeForm.spotRef)
            : null,
        cutOffTime:
          this.tradeForm.settlementType != null
            ? this.tradeForm.settlementType
            : null,
        deliverableCcy:
          this.tradeForm.deliverableCcy != null
            ? this.tradeForm.deliverableCcy
            : null,
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        cptyUti: null,
        tcUti: this.tradeForm.uti != null ? this.tradeForm.uti : null,
        uniqueLinkId:
          this.tradeForm.uniqueLinkID != null
            ? this.tradeForm.uniqueLinkID
            : null,
        clearingMemberId: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        rabate: null,
        future4: "",
        future5: "",
        future6: "",
        future7: "",
        future8: "",
        future9: "",
        future10: "",
        future11: "",
        future12: "",
        future13: "",
        future14: "",
        future15: "",
        entryType:
          this.tradeForm.entryType != null ? this.tradeForm.entryType : null,
        buySell: this.tradeForm.buySell != null ? this.tradeForm.buySell : null,
        orderType:
          this.tradeForm.orderType != null ? this.tradeForm.orderType : null,
        timeInForce:
          this.tradeForm.timeInForce != null
            ? this.tradeForm.timeInForce
            : null,
        stopPrice:
          this.tradeForm.stopPrice != null
            ? parseFloat(this.tradeForm.stopPrice)
            : null,

        trailingAmount:
          this.tradeForm.trailingAmount != null
            ? parseFloat(this.tradeForm.trailingAmount)
            : null,

        takeProfitLimit:
          this.tradeForm.takeProfitLimit != null
            ? parseFloat(this.tradeForm.takeProfitLimit)
            : null,

        stopLoss:
          this.tradeForm.stopLossStop != null
            ? parseFloat(this.tradeForm.stopLossStop)
            : null,

        stopLossLimit:
          this.tradeForm.stopLossLimit != null
            ? parseFloat(this.tradeForm.stopLossLimit)
            : null,

        orderAmount: null,

        enableOCO:
          this.tradeForm.enableConnectedOCOOrders != null
            ? this.tradeForm.enableConnectedOCOOrders.toString()
            : null,

        orderPrice: null,

        orderDate:
          this.tradeForm.orderDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDate)
            : null,

        cptyOrderDate:
          this.tradeForm.cptyOrderDate != null
            ? this.YMDdateFormat(this.tradeForm.cptyOrderDate)
            : null,

        counterpartOrderTime:
          this.tradeForm.cptyOrderTime != null
            ? this.timeFormat(this.tradeForm.cptyOrderTime)
            : null,

        orderDurationDate:
          this.tradeForm.orderDurationDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDate)
            : null,
        orderDurationTime:
          this.tradeForm.orderDurationTime != null
            ? this.timeFormat(this.tradeForm.orderDurationTime)
            : null,

        ocoLink: null,
        swapMaturity:
          this.tradeForm.maturity != null ? this.tradeForm.maturity : null,
        swapForwardRate:
          this.tradeForm.swapPoints != null
            ? parseFloat(this.tradeForm.swapPoints)
            : null,
        swapSpread:
          this.tradeForm.swapSpread != null
            ? parseFloat(this.tradeForm.swapSpread)
            : null,
        swapClientRate:
          this.tradeForm.swapClientRate != null
            ? parseFloat(this.tradeForm.swapClientRate).toFixed(4)
            : null,
        swapClientBuySell1:
          this.tradeForm.swapClientBuySell1 != null
            ? this.tradeForm.swapClientBuySell1
            : null,
        swapClientBuySell2:
          this.tradeForm.swapClientBuySell2 != null
            ? this.tradeForm.swapClientBuySell2
            : null,
        swapClientCcy1:
          this.tradeForm.swapClientCcy1 != null
            ? this.tradeForm.swapClientCcy1
            : null,
        swapClientCcy2:
          this.tradeForm.swapClientCcy2 != null
            ? this.tradeForm.swapClientCcy2
            : null,
        swapClientAmount1:
          this.tradeForm.swapClientAmount1 != null
            ? parseFloat(this.tradeForm.swapClientAmount1)
            : null,
        swapClientAmount2:
          this.tradeForm.swapClientAmount2 != null
            ? parseFloat(this.tradeForm.swapClientAmount2)
            : null,
        swapCptyRate:
          this.tradeForm.swapCptyRate != null
            ? parseFloat(this.tradeForm.swapCptyRate)
            : null,
        swapCptyBuySell1:
          this.tradeForm.swapCptyBuySell1 != null
            ? this.tradeForm.swapCptyBuySell1
            : null,
        swapCptyBuySell2:
          this.tradeForm.swapCptyBuySell2 != null
            ? this.tradeForm.swapCptyBuySell2
            : null,
        swapCptyCcy1:
          this.tradeForm.swapCptyCcy1 != null
            ? this.tradeForm.swapCptyCcy1
            : null,
        swapCptyCcy2:
          this.tradeForm.swapCptyCcy2 != null
            ? this.tradeForm.swapCptyCcy2
            : null,
        swapCptyAmount1:
          this.tradeForm.swapCptyAmount1 != null
            ? parseFloat(this.tradeForm.swapCptyAmount1)
            : null,
        swapCptyAmount2:
          this.tradeForm.swapCptyAmount2 != null
            ? parseFloat(this.tradeForm.swapCptyAmount2)
            : null,
        swapSpreadCcy:
          this.tradeForm.swapSpreadCcy != null
            ? this.tradeForm.swapSpreadCcy
            : null,
        swapSpreadAmount:
          this.tradeForm.swapSpreadAmount != null
            ? parseFloat(this.tradeForm.swapSpreadAmount)
            : null,
        swapTradeDate:
          this.tradeForm.swapTradeDate != null
            ? this.YMDdateFormat(this.tradeForm.swapTradeDate)
            : null,
        swapSettleDate:
          this.tradeForm.swapSettleDate != null
            ? this.YMDdateFormat(this.tradeForm.swapSettleDate)
            : null,
      };

      return setTrade;
    },
  },
  watch: {
    // "tradeForm.calcFromSpread": {
    //   handler: function(val, before) {
    //     console.log(val)

    //   },
    //   deep: true,
    // },
    copyTradeModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeFxExecutedTCUsers();
          this.getArrangeFxExecutedCurrencies();
          this.getArrangeFxExecutedOrderTakenGivenThrough();

          // this.tradeForm.orderDate = moment().format("DD-MM-YYYY");
          // this.tradeForm.orderTime = moment().format("HH:mm:ss");

          // this.tradeForm.orderDurationDate = moment().format("DD-MM-YYYY ");
          // this.tradeForm.orderDurationTime = moment()
          //   .endOf("day")
          //   .format("HH:mm:ss")
          //   .toString();

          // this.tradeForm.cptyOrderDate = moment().format("DD-MM-YYYY ");
          // this.tradeForm.cptyOrderTime = moment().format("HH:mm:ss")

          // this.tradeForm.tradeDate = moment().format("DD-MM-YYYY ");

          //   let tradeDate = new Date(
          //     this.MDYdateFormat(this.tradeForm.tradeDate)
          //   );

          //   //set trade date name
          //   this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

          // this.tradeForm.scfUser = this.user.name;
          // this.tradeForm.entryType = "Order";
          // this.tradeForm.buySell = "Buy";
          // this.tradeForm.orderType = "Limit";
          // this.tradeForm.timeInForce = "DAY";
          // this.tradeForm.productType = "SPOT",
          // this.tradeForm.settlementType = "PHYSICAL"
          // this.tradeForm.actingCapaticy = "PRINCIPAL";

          // this.tradeForm.cptyBuySell1 = 'Buys'
          // this.tradeForm.cptyBuySell2 = 'Sells'
          // this.tradeForm.clientBuySell1 = 'Buys'
          // this.tradeForm.clientBuySell2 = 'Sells'
        }
      },
    },
    "tradeForm.productType": {
      handler: function(val, before) {
        if(this.optPriceTypes.length > 0) {
          if (val == "SWAP") {
          this.tradeForm.swapTradeDate = moment().format("DD-MM-YYYY ");

          let swapTradeDate = new Date(
            this.MDYdateFormat(this.tradeForm.swapTradeDate)
          );

          //set swap trade date name
          this.tradeForm.swapTradeDateName = this.weekday[
            swapTradeDate.getDay()
          ];

          this.tradeForm.swapCptyBuySell1 = "Sells";
          this.tradeForm.swapCptyBuySell2 = "Buys";
          this.tradeForm.swapClientBuySell1 = "Buys";
          this.tradeForm.swapClientBuySell2 = "Sells";

          this.tradeForm.swapCptyCcy1 = this.optPriceTypes.find(
            (x) => x === "GBP"
          )
            ? "GBP"
            : null;
          this.tradeForm.swapCptyCcy2 = this.optPriceTypes.find(
            (x) => x === "USD"
          )
            ? "USD"
            : null;
          this.tradeForm.swapClientCcy1 = this.optPriceTypes.find(
            (x) => x === "GBP"
          )
            ? "GBP"
            : null;
          this.tradeForm.swapClientCcy2 = this.optPriceTypes.find(
            (x) => x === "USD"
          )
            ? "USD"
            : null;
          this.tradeForm.swapSpreadCcy = this.optPriceTypes.find(
            (x) => x === "USD"
          )
            ? "USD"
            : null;
        } else {

          this.tradeForm.swapTradeDate = null
          this.tradeForm.swapTradeDateName = null
          this.tradeForm.maturity = null
          this.tradeForm.swapSettleDate = null
          this.tradeForm.swapSettleDateName = null
          this.tradeForm.swapPoints = null
          this.tradeForm.swapCptyRate = null
          this.tradeForm.swapCptyAmount1 = null
          this.tradeForm.swapCptyCcy1 = null
          this.tradeForm.swapCptyBuySell1 = null
          this.tradeForm.swapCptyAmount2 = null
          this.tradeForm.swapCptyCcyType2 = null
          this.tradeForm.swapCptyBuySell2 = null
          this.tradeForm.swapSpread = null
          this.tradeForm.swapCalcFromSpread = "Calculate Client Rate From Spread"
          this.tradeForm.swapClientRate = null
          this.tradeForm.swapClientAmount1 = null
          this.tradeForm.swapClientCcy1 = null
          this.tradeForm.swapClientBuySell1 = null
          this.tradeForm.swapClientAmount2 = null
          this.tradeForm.swapClientCcy2 = null
          this.tradeForm.swapClientBuySell2 = null
          this.tradeForm.swapSpreadAmount = null
          this.tradeForm.swapSpreadCcy = null
        }
        }

        if (val == "SWAP") {
          
          this.tradeForm.enableConnectedOCOOrders = false
          this.tradeForm.takeProfitLimit = null
          this.tradeForm.stopLossStop = null
          this.tradeForm.stopLossLimit = null
          }
        
      },
      deep: true,
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = {
            costCenter:val.CostCenter,
            orderDurationDate:
              val.OrderDurationDate != null
                ? this.dateFormat(val.OrderDurationDate)
                : null,
            orderDurationTime:
              val.OrderDurationTime != null
                ? this.HmstimeFormat(val.OrderDurationTime)
                : null,
            entryType: val.EntryType ?? null,
            buySell: val.BuySell ?? null,
            orderType: val.OrderType ?? null,
            timeInForce: val.TimeinForce ?? null,
            orderDate:
              val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
            orderTime:
              val.OrderTime != null ? this.HmstimeFormat(val.OrderTime) : null,
            cptyOrderDate:
              val.CounterpartyOrderDate != null
                ? this.dateFormat(val.CounterpartyOrderDate)
                : null,
            cptyOrderTime:
              val.CounterpartyOrderTime != null
                ? this.HmstimeFormat(val.CounterpartyOrderTime)
                : null,
            enableConnectedOCOOrders: val.EnableOCO == "true" ? true : false,
            stopPrice: val.StopPrice != null ? parseFloat(val.StopPrice) : null,
            trailingAmount:
              val.TrailingAmount != null ? parseFloat(val.TrailingAmount) : null,
            takeProfitLimit:
              val.TakeProfitLimit != null
                ? parseFloat(val.TakeProfitLimit)
                : null,
            stopLossStop: val.StopLoss != null ? parseFloat(val.StopLoss) : null,
            stopLossLimit:
              val.StopLossLimit != null ? parseFloat(val.StopLossLimit) : null,
            client: val.Client ?? null,
            clientAccount: val.ClientAcc != null ? val.ClientAcc : null,
            counterparty: val.Cpty ?? null,
            cptyAccount: val.CptyAcc ?? null,
            productType: val.ProductType ?? null,
            settlementType: val.SettlementType ?? null,
            counterpartyRate:
              val.CptyRate != null ? parseFloat(val.CptyRate) : null,

            clientRate:
              val.ClientRate != null ? parseFloat(val.ClientRate) : null,

            cptyAmount1:
              val.CptyAmount1 != null ? parseFloat(val.CptyAmount1) : null,

            cptyAmount2:
              val.CptyAmount2 != null ? parseFloat(val.CptyAmount2) : null,

            cptyCcy1: val.Ccy1 != null ? val.Ccy1 : null,
            cptyCcy2: val.Ccy2 != null ? val.Ccy2 : null,
            cptyBuySell1: val.CptyBuySell1 == "Buys" ? "Buys" : "Sells",
            cptyBuySell2: val.CptyBuySell1 == "Buys" ? "Sells" : "Buys",
            spread: val.Spread != null ? parseFloat(val.Spread) : null,
            clientAmount1:
              val.ClientAmount1 != null ? parseFloat(val.ClientAmount1) : null,
            clientAmount2:
              val.ClientAmount2 != null ? parseFloat(val.ClientAmount2) : null,
            clientCcy1: val.Ccy1 != null ? val.Ccy1 : null,
            clientCcy2: val.Ccy2 != null ? val.Ccy2 : null,
            clientBuySell1: val.CptyBuySell1 == "Buys" ? "Buys" : "Sells",
            clientBuySell2: val.CptyBuySell1 == "Buys" ? "Sells" : "Buys",
            spotRef: val.SpotRef != null ? parseFloat(val.SpotRef) : null,
            spreadAmount:
              val.SpreadAmount != null ? parseFloat(val.SpreadAmount) : null,
            spreadCcy: val.SpreadCcy != null ? val.SpreadCcy : null,
            comissionAmount:
              val.CommAmount != null ? parseFloat(val.CommAmount) : null,
            comissionCcy: val.CommCcy != null ? val.CommCcy : null,
            cutOffTime: val.CutOffTime != null ? val.CutOffTime : null,
            deliverableCcy:
              val.DeliverableCcy != null ? val.DeliverableCcy : null,
            tradeDate:
              val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
            settlementDate:
              val.SettlDate != null ? this.dateFormat(val.SettlDate) : null,
            executionTime:
              val.ExecTime != null ? this.HmstimeFormat(val.ExecTime) : null,
            counterpartyTrader: val.CptyTrader != null ? val.CptyTrader : null,
            scfUser: val.TcUser != null ? val.TcUser : null,
            orderTakenVia: val.TakenVia != null ? val.TakenVia : null,
            orderGivenThrough: val.GivenThrg != null ? val.GivenThrg : null,
            actingCapaticy: val.ActingCap != null ? val.ActingCap : null,
            note: val.TraderNote != null ? val.TraderNote : null,
            swapTradeDate:
              val.SwapTradeDate != null
                ? this.dateFormat(val.SwapTradeDate)
                : null,
            maturity: val.SwapMaturity != null ? val.SwapMaturity : null,
            swapSettleDate:
              val.SwapSettlementDate != null
                ? this.dateFormat(val.SwapSettlementDate)
                : null,
            swapPoints:
              val.SwapForwardRate != null
                ? parseFloat(val.SwapForwardRate)
                : null,
            swapCptyRate:
              val.SwapCptyRate != null ? parseFloat(val.SwapCptyRate) : null,
            swapCptyAmount1:
              val.SwapCptyAmount1 != null
                ? parseFloat(val.SwapCptyAmount1)
                : null,
            swapCptyCcy1: val.SwapCptyCcy1 != null ? val.SwapCptyCcy1 : null,
            swapCptyBuySell1:
              val.SwapCptyBuySell1 != null ? val.SwapCptyBuySell1 : null,
            swapCptyAmount2:
              val.SwapCptyAmount2 != null
                ? parseFloat(val.SwapCptyAmount2)
                : null,
            swapCptyCcyType2: val.SwapCptyCcy2 != null ? val.SwapCptyCcy2 : null,
            swapCptyBuySell2:
              val.SwapCptyBuySell2 != null ? val.SwapCptyBuySell2 : null,
            swapSpread: val.SwapSpread != null ? val.SwapSpread : null,
            swapClientRate:
              val.SwapClientRate != null ? parseFloat(val.SwapClientRate) : null,
            swapClientAmount1:
              val.SwapClientAmount1 != null
                ? parseFloat(val.SwapClientAmount1)
                : null,
            swapClientCcy1:
              val.SwapClientCcy1 != null ? val.SwapClientCcy1 : null,
            swapClientBuySell1:
              val.SwapClientBuySell1 != null ? val.SwapClientBuySell1 : null,
            swapClientAmount2:
              val.SwapClientAmount2 != null
                ? parseFloat(val.SwapClientAmount2)
                : null,
            swapClientCcy2:
              val.SwapClientCcy2 != null ? val.SwapClientCcy2 : null,
            swapClientBuySell2:
              val.SwapClientBuySell2 != null ? val.SwapClientBuySell2 : null,
            swapSpreadAmount:
              val.SwapSpreadAmount != null
                ? parseFloat(val.SwapSpreadAmount)
                : null,
            swapSpreadCcy: val.SwapSpreadCcy != null ? val.SwapSpreadCcy : null,
            uti: val.TcUti != null ? val.TcUti : null,
            uniqueLinkID: val.UniqueLinkId != null ? val.UniqueLinkId : null,
            tradeRef: val.id,
            saveDate: val.SaveDate != null ? val.SaveDate : null,
            savetime: val.SaveTime != null ? val.SaveTime : null,
            savedBy: val.SavedBy != null ? val.SavedBy : null,
            amanededBy: val.AmendedBy != null ? val.AmendedBy : null,
            amendmentDate: val.AmendmentDate != null ? val.AmendmentDate : null,
            amendmentReason:
              val.AmendmentReason != null ? val.AmendmentReason : null,
            amendmentTime: val.AmendmentTime != null ? val.AmendmentTime : null,
            cancelDate: val.CancelDate != null ? val.CancelDate : null,
            cancelReason: val.CancelReason != null ? val.CancelReason : null,
            cancelTime: val.CancelTime != null ? val.CancelTime : null,
            cancelled: val.Cancelled != "0" ? val.CancelTime : "1",
            cancelledBy: val.CancelledBy != null ? val.CancelledBy : null,
          };

          if (this.tradeForm.tradeDate != null) {
            let tradeDate = new Date(
              this.MDYdateFormat(this.tradeForm.tradeDate)
            );

            //set trade date name
            this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];
          }

          if (this.tradeForm.settlementDate != null) {
            let settleDate = new Date(
              this.MDYdateFormat(this.tradeForm.settlementDate)
            );

            //set settle date name
            this.tradeForm.settlementDateName = this.weekday[
              settleDate.getDay()
            ];
          }

          if (this.tradeForm.swapSettleDate != null) {
            let settleDate = new Date(
              this.MDYdateFormat(this.tradeForm.swapSettleDate)
            );

            //set swap settle date name
            this.tradeForm.swapSettleDateName = this.weekday[
              settleDate.getDay()
            ];
          }

          if (this.tradeForm.swapTradeDate != null) {
            let tradeDate = new Date(
              this.MDYdateFormat(this.tradeForm.swapTradeDate)
            );

            //set swap trade date name
            this.tradeForm.swapTradeDateName = this.weekday[tradeDate.getDay()];
          }

          // this.tradeForm.calcFromSpread = this.optCalcFromSpread[0].value
          // this.tradeForm.swapCalcFromSpread = this.optCalcFromSpread[1].value
          this.tradeForm.calcFromSpread = "Calculate Spread From Client Rate";
          this.tradeForm.swapCalcFromSpread =
            "Calculate Client Rate From Spread";
            
          this.tradeForm.cptyCalculateFromRate1 = true;
          this.tradeForm.clientCalculateFromRate2 = true;

          this.oldTradeFormData = JSON.parse(JSON.stringify(this.tradeForm));

          this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
          this.tradeForm.orderDurationTime = moment()
            .endOf("day")
            .format("HH:mm:ss")
            .toString();

          this.changeTimeInForceType();
          this.arrangeCurrencies();
          this.arrangeBuySell();
          this.arrangeInputFormats();

        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.fxDetailRadio .custom-control:nth-child(odd) {
  margin-bottom: 5px;
}

.animate__animated {
  animation-fill-mode: none;
}
.swapSettleName .input-group-append .input-group-text {
  color: red;
}
.swapTradeName .input-group-append .input-group-text {
  color: red;
}
.tradeDateName .input-group-append .input-group-text {
  color: red;
}
.settlementDateName .input-group-append .input-group-text {
  color: red;
}

.animate__animated {
  animation-fill-mode: none;
}

.input-dropdown .btn {
  padding: 0;
}

.swall2-overlay {
  z-index: 100005;
}
.swal2-modal {
  z-index: 99999;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
